/* eslint-disable react/no-array-index-key */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React, { Component } from "react";
import styled from "styled-components";
import Title from "../components/Title";
import ImageV2 from "../components/ImageV2";
import OptionCardV2 from "../components/OptionCardV2";
import LeftArrowContainer from "../components/LeftArrowContainer";
import RightArrowContainer from "../components/RightArrowContainer";
import LeftArrow from "../icons/LeftArrow";
import RightArrow from "../icons/RightArrow";
import SliderBadge from "../components/SliderBadge";
import RoundTrue from "../icons/RoundTrue";
import RoundTrueContainer from "../components/RoundTrueContainer";
import SubTitle from "../components/SubTitle";
import WrapText from "../components/WrapText";

const TitleWrapper = styled.div`
  display: flex;
  margin: 5px 10px;
  flex-direction: column;
  word-break: break-word;
  width: 159px;
  text-align: center;
`;

const ImageWrapper = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  outline: ${({ theme }) => `1px solid ${theme.optionBorderColor}`};
  border-radius: 6px;
  width: 163px;
  height: 163px;
  margin: 8px;
  &:hover {
    outline: 1px solid ${({ theme }) => theme.optionHoverBorderColor};
  }
`;

const SliderBadgeWrapper = styled.div`
  display: flex;
  justify-content: end;
  margin-top: 12px;
`;

const Slider = styled.div`
  display: flex;
  transition: all 0.5s ease;
  ${(props) =>
    props.currentSlide
      ? `transform: translateX(${-179 * props.currentSlide}px);`
      : ""};
`;

const SlidesWrapper = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  margin: 0 auto;
  font-size: ${({ slideData }) => `${slideData?.fontSize}px`};
  color: ${({ slideData }) => slideData?.fontColor};
  padding: ${({ slideData }) => slideData?.padding};
  height: ${({ slideData }) => `${slideData?.height}px`};
  position: absolute;
  width: 100%;
  top: ${({ slideData }) => `calc(20px + ${slideData?.contentTop}%)`};
`;

const MainTitle = styled.div`
  color: ${({ theme }) => theme.optionTitleColor};
  font-size: ${({ theme }) => `${theme.optionTitleFontSize}px`};
  font-weight: ${({ theme }) => theme.optTitleFontWeight};
  align-self: center;
`;

const OptionSubTitle = styled.div`
  color: ${({ theme }) => theme.optionSubtitleColor};
  font-size: ${({ theme }) => `${theme.optionSubtitleFontSize}px`};
  font-weight: ${({ theme }) => theme.optSubTitleFontWeight};
  align-self: center;
`;

class OptionsV2 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeOptions: [],
      currentSlide: 0,
    };
  }

  onContentEdit = (key, value) => {
    if (this.props.isGrid) this.props.setSlideContent(key, value);
  };

  render() {
    const content = this.props.data?.data?.content;
    const { setActiveOptions } = this.props;
    const { currentSlide, activeOptions } = this.state;
    const { id } = this.props.data;

    return (
      <div className="gsf-container">
        <div className={`gsf-title gsf-title-${id}`}>
          <div
            className="gsf-wrap-text"
            dangerouslySetInnerHTML={{ __html: content?.title }}
          />
        </div>
        <div className={`gsf-subtitle gsf-subtitle-${id}`}>
          <div
            className="gsf-wrap-text"
            dangerouslySetInnerHTML={{ __html: content?.paragraph1 }}
          />
        </div>
        <div className={`gsf-option-wrapper gsf-content gsf-content-${id}`}>
          <div style={{ position: "relative" }}>
            {content?.options?.length > 5 ? (
              <>
                <LeftArrowContainer
                  className="gsf-left-arrow"
                  onClick={() =>
                    currentSlide &&
                    this.setState({
                      currentSlide: currentSlide - 1,
                    })
                  }
                >
                  <LeftArrow />
                </LeftArrowContainer>

                <RightArrowContainer
                  className="gsf-right-arrow"
                  onClick={() =>
                    content?.options?.length - 5 !== currentSlide &&
                    this.setState({
                      currentSlide: currentSlide + 1,
                    })
                  }
                >
                  <RightArrow />
                </RightArrowContainer>
              </>
            ) : (
              ""
            )}
            <div style={{ maxWidth: "895px", overflow: "hidden" }}>
              <Slider currentSlide={currentSlide}>
                {content?.options?.length &&
                  content?.options.map((opt, idx) => (
                    <div
                      onClick={() => {
                        if (activeOptions.includes(idx)) {
                          const foundIndex = activeOptions.findIndex(
                            (id) => id === idx
                          );
                          activeOptions.splice(foundIndex, 1);
                          setActiveOptions(activeOptions);
                          this.setState((prevState) => ({
                            activeOptions: [...prevState.activeOptions],
                          }));
                          return;
                        }
                        if (
                          activeOptions.length ===
                          Number(
                            content.maxOption && content.maxOption > 0
                              ? content.maxOption
                              : "1"
                          )
                        )
                          return;
                        setActiveOptions([...activeOptions, idx]);
                        this.setState((prevState) => ({
                          activeOptions: [...prevState.activeOptions, idx],
                        }));
                      }}
                      key={`slide${idx}`}
                    >
                      <div className="gsf-option-imagewrapper">
                        {activeOptions.includes(idx) ? (
                          <RoundTrueContainer
                            active={activeOptions.includes(idx)}
                            className="gsf-checkmark"
                          >
                            <RoundTrue />
                          </RoundTrueContainer>
                        ) : (
                          ""
                        )}
                        <div className="gsf-option-image" src={opt.url} />
                      </div>
                      <div className="gsf-titles-wrapper">
                        <div className="gsf-option-title">
                          {opt?.title || ""}
                        </div>
                        <div className="gsf-option-subtitle">
                          {opt?.subTitle || ""}
                        </div>
                      </div>
                    </div>
                  ))}
              </Slider>
              <div className="gsf-slider-badgewrapper">
                {content?.options?.length > 5 ? (
                  <div className="gsf-slider-badge">{`${
                    activeOptions.length
                  } Valittu | ${Number(content?.maxOption) ||
                    Number(content?.minOption)} Jaljella`}</div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default OptionsV2;
