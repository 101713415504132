import React from "react";

const RightArrow = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props?.size ? props?.size : "28"}
    height={props?.size ? props?.size : "28"}
    viewBox="0 0 512 512"
    fill="none"
  >
    <path
      d="M346.353 256.119L123.86 33.6265C116.169 25.9336 116.169 13.4614 123.86 5.76851C131.554 -1.92211 144.025 -1.92211 151.718 5.76851L388.14 242.19C395.83 249.883 395.83 262.355 388.14 270.048L151.718 506.469C143.892 514.028 131.419 513.811 123.86 505.984C116.487 498.349 116.487 486.245 123.86 478.611L346.353 256.119Z"
      className={props.className}
    />
  </svg>
);

export default RightArrow;
