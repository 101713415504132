import styled from 'styled-components';

const Timestamp = styled.div`
  border-radius: 24px;
  color: black;
  font-size: 12px;
  padding-bottom: 0px;
  margin: 0 5px 5px;
  text-align: ${props => (props.isRight ? 'right' : 'left')};
`;

export default Timestamp;
