import styled from 'styled-components';
import defaultTheme from '../theme';

const FormButton = styled.button`
  background-color: ${({ theme }) => theme.botFormButtonBgColor};
  border: 2px solid ${({ theme }) => theme.botFormButtonBgColor};
  text-transform: "none";
  border-radius: 6px;
  cursor: pointer;
  width: auto !important;
  color: ${({ theme }) => theme.botFormButtonTextColor};
  font-size: ${({ fontSizeMultiplier }) =>
    `${fontSizeMultiplier * 14 || 14}px`};
  line-height: ${({ fontSizeMultiplier }) =>
    `${fontSizeMultiplier * 20 || 20}px`};
  padding: 11px 16px;
  font-weight: 600;
  &:hover {
    color: ${({ theme }) => `${theme.botFormButtonHoverTextColor} !important`};
    background: ${({ theme }) => theme.botFormButtonHoverBgColor};
    border: 2px solid ${({ theme }) => theme.botFormButtonHoverTextColor};
  }
  &:active,
  &:hover:focus {
    outline:none;
  }
`;

FormButton.defaultProps = {
  theme: defaultTheme
};

export default FormButton;
