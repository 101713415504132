export default {
  background: '#fff',
  fontFamily: 'Helvetica',
  headerBgColor: '#fff',
  headerFontColor: '#1A175E',
  headerFontSize: '18px',
  botBubbleColor: '#F4F4F4',
  botFontColor: '#4f4f4f',
  userBubbleColor: '#1A175E',
  userFontColor: '#fff',
  headerFontFamily: 'Helvetica',
  footerWidth: '340px',
  iconBgColor: '#1A175E',
  iconColor: '#fff',
  contentBgType: 'color',
  optionFontColor: '#1A175E',
  optionBubbleColor: '#1A175E',
  botFormButtonTextColor: '#fff',
  botFormButtonBgColor: '#1A175E',
  botFormButtonHoverTextColor: '#1A175E',
  botFormButtonHoverBgColor: '#fff',
  chatNotificationTextColor:'#000',
  chatNotificationBgColor: '#ffff',
  cardBtnTextColor: '#fff',
  cardBtnBgColor: '#1A175E',
  cardBtnHoverTextColor: '#1A175E',
  cardBtnHoverBgColor: '#fff',
};
