import React from "react";

const CloseIcon = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 5.999 6"
    className="gsf-cross-icon"
  >
  <g id="_21" data-name="21" transform="translate(-0.001 -1.844)">
    <path id="Path_1" data-name="Path 1" d="M.569,8.456a.564.564,0,0,1-.4-.138.58.58,0,0,1,0-.811L4.992,2.626a.564.564,0,0,1,.8.027.58.58,0,0,1,.026.755L.967,8.318A.564.564,0,0,1,.569,8.456Z" transform="translate(0 -0.615)"/>
    <path id="Path_2" data-name="Path 2" d="M6.888,7.841a.565.565,0,0,1-.4-.167L1.665,2.793a.58.58,0,0,1,.062-.811.563.563,0,0,1,.739,0L7.32,6.863a.58.58,0,0,1,.026.813L7.32,7.7a.564.564,0,0,1-.432.138Z" transform="translate(-1.5 0)"/>
  </g>
</svg>
);

export default CloseIcon;
