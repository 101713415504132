import styled from 'styled-components';
import defaultTheme from '../../theme';

const OptionElement = styled.button`
  background: ${({ theme }) => theme.optionBubbleColor};
  background-opacity: 0.3;
  border: 0;
  border-radius: 8px;
  color: ${({ theme }) => theme.optionFontColor};
  display: inline-block;
  font-size: ${({ fontSizeMultiplier }) => fontSizeMultiplier*14 + 'px' };
  line-height: ${({ fontSizeMultiplier }) => fontSizeMultiplier*20 + 'px' };
  padding: 12px;
  font-weight: 400;
  text-align: left;
  &:hover {
    color: ${({ theme }) => theme.onOptionHoverTextColor};
    background: ${({ theme }) => theme.onOptionHoverBgColor};
  }
  &:active,
  &:hover:focus {
    outline:none;
  }
`;

OptionElement.defaultProps = {
  theme: defaultTheme
};

export default OptionElement;
