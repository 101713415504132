import styled from 'styled-components';
import defaultTheme from '../theme';

const Card = styled.div`
  background-color: ${({ theme }) => theme.contentBgColor};
  ${({ theme }) =>
    theme.contentBgImage
      ? `background-image: url(${theme.contentBgImage});
        background-repeat: no-repeat;
        background-position-x: left;
        background-position-y: bottom;`
      : ""};
  padding: 25px 20px 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: ${({ height, theme }) => (theme.autoBarHeight ? height : 'auto')};
}
`;

Card.defaultProps = {
  theme: defaultTheme
};

export default Card;
