import styled from 'styled-components';
import defaultTheme from '../theme';

const CarouselStep = styled.div`
  margin: 3px;
  border: 2px solid ${({ theme }) => theme.userBubbleColor};
  background: ${props => props.isActive ? props.theme.userBubbleColor : '#fff'};
  border-radius: 50%;
  cursor: pointer;
  width: 8px;
  height: 8px;
`;

CarouselStep.defaultProps = {
    theme: defaultTheme
  };
export default CarouselStep;