import styled from 'styled-components';

const CardText = styled.p`
  color: ${({color}) => color};
  font-size: ${({ fontSizeMultiplier }) => `${fontSizeMultiplier * 16}px`};
  line-height: ${({ fontSizeMultiplier }) => `${fontSizeMultiplier * 20}px`};
  margin: 0.5rem 0 0 !important;
  display: block;
`;

export default CardText;
