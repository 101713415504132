import React from "react";

const BotHeaderIcon = props => {
  // const defaultSrc = `data:image/svg+xml;utf8,<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="24" cy="24" r="23.5" fill="${props.avatarColor ||
  //   "#1A175E"}" stroke="${props.strokeColor ||
  //   "black"}"></circle><path d="M31.2042 24.8065L31.2017 24.8083C29.1818 26.277 26.6897 27.0649 24.0295 27.0649C21.3413 27.0649 18.8272 26.2692 16.7954 24.7856L16.7931 24.784L16.7931 24.784C16.5201 24.5822 16.1781 24.4812 15.8305 24.5029C15.4832 24.5245 15.1587 24.6668 14.9161 24.8992C14.3169 25.4772 14.3733 26.4249 15.0297 26.9062L15.0299 26.9064C17.5374 28.7476 20.6626 29.75 24.0295 29.75C27.3702 29.75 30.471 28.7573 32.9689 26.933L31.2042 24.8065ZM31.2042 24.8065C31.4778 24.6049 31.8204 24.5041 32.1684 24.526C32.516 24.5478 32.8408 24.6901 33.0839 24.9226M31.2042 24.8065L33.0839 24.9226M33.0839 24.9226C33.6838 25.5018 33.6264 26.4522 32.969 26.9329L33.0839 24.9226Z" fill="white" stroke="black"></path><path d="M22.5382 21.1156C22.5382 22.2375 21.5938 23.1746 20.3941 23.1746C19.1944 23.1746 18.25 22.2375 18.25 21.1156C18.25 19.9938 19.1944 19.0566 20.3941 19.0566C21.5938 19.0566 22.5382 19.9938 22.5382 21.1156Z" fill="white" stroke="black"></path><path d="M29.712 20.309C29.712 21.4308 28.7676 22.368 27.5679 22.368C26.3682 22.368 25.4238 21.4308 25.4238 20.309C25.4238 19.1871 26.3682 18.25 27.5679 18.25C28.7676 18.25 29.712 19.1871 29.712 20.309Z" fill="white" stroke="black"></path></svg>`;
  const { scale, src, size } = props;
  const iconSize = size ? `${size}px` : "48px";
  
  const defaultAvatar = (
    <svg
      style={{ transform: `scale(${props.scale || 1})` }}
      width={iconSize}
      height={iconSize}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="24"
        cy="24"
        r="23.5"
        fill={props.avatarColor || "#1A175E"}
        stroke={props.textColor || props.userFontColor || "black"}
      ></circle>
      <path
        d="M31.2042 24.8065L31.2017 24.8083C29.1818 26.277 26.6897 27.0649 24.0295 27.0649C21.3413 27.0649 18.8272 26.2692 16.7954 24.7856L16.7931 24.784L16.7931 24.784C16.5201 24.5822 16.1781 24.4812 15.8305 24.5029C15.4832 24.5245 15.1587 24.6668 14.9161 24.8992C14.3169 25.4772 14.3733 26.4249 15.0297 26.9062L15.0299 26.9064C17.5374 28.7476 20.6626 29.75 24.0295 29.75C27.3702 29.75 30.471 28.7573 32.9689 26.933L31.2042 24.8065ZM31.2042 24.8065C31.4778 24.6049 31.8204 24.5041 32.1684 24.526C32.516 24.5478 32.8408 24.6901 33.0839 24.9226M31.2042 24.8065L33.0839 24.9226M33.0839 24.9226C33.6838 25.5018 33.6264 26.4522 32.969 26.9329L33.0839 24.9226Z"
        fill="white"
        stroke="black"
      ></path>
      <path
        d="M22.5382 21.1156C22.5382 22.2375 21.5938 23.1746 20.3941 23.1746C19.1944 23.1746 18.25 22.2375 18.25 21.1156C18.25 19.9938 19.1944 19.0566 20.3941 19.0566C21.5938 19.0566 22.5382 19.9938 22.5382 21.1156Z"
        fill="white"
        stroke="black"
      ></path>
      <path
        d="M29.712 20.309C29.712 21.4308 28.7676 22.368 27.5679 22.368C26.3682 22.368 25.4238 21.4308 25.4238 20.309C25.4238 19.1871 26.3682 18.25 27.5679 18.25C28.7676 18.25 29.712 19.1871 29.712 20.309Z"
        fill="white"
        stroke="black"
      ></path>
    </svg>
  );

  const style = {
    transform: `scale(${scale || 1})`,
    width: iconSize,
    height: iconSize
  };

  // if (props.width && props.height) {
  //   style.width = props.width;
  //   style.height = props.height;
  // }

  let result = defaultAvatar;
  if (src) {
    result = <img alt="bot avatar" style={style} src={src}></img>;
  }

  return result;
};

export default BotHeaderIcon;
