import styled from 'styled-components';
import defaultTheme from '../theme';

const TrademarkContainer = styled.div`
  width: 340px;
  background: rgba(235, 235, 235, 1);
  opacity: 1;
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
  box-shadow: 0px 4px 12px rgba(0.7686274647712708, 0.7686274647712708, 0.7686274647712708, 0.4000000059604645);
  overflow: hidden;
`;

TrademarkContainer.defaultProps = {
  theme: defaultTheme
};

export default TrademarkContainer;
