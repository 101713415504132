import React from "react";

const GroweoIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="30"
      viewBox="0 0 1070.497 241.2"
    >
      <path
        id="Vector_1_"
        data-name="Vector (1)"
        d="M255.283,3.8h3.9a16.943,16.943,0,0,1,16.9,16.9v2.6a16.943,16.943,0,0,1-16.9,16.9c-14.6,0-20.5,5.9-20.5,20.8v94.7a20.2,20.2,0,1,1-40.4,0V62C198.283,21.7,218.483,3.8,255.283,3.8Zm18.7,86.3c0-52.4,39.7-90.1,90.1-90.1s90.1,37.8,90.1,90.1-39.7,90.5-90.1,90.5C313.683,180.5,273.983,142.5,273.983,90.1Zm90.1,52.4c28.3,0,49.8-21.5,49.8-52.4s-21.5-52.4-49.8-52.4-49.8,21.5-49.8,52.4S335.783,142.5,364.083,142.5ZM474.283,3.8a19.95,19.95,0,0,1,19.2,14.3l30.9,104.1,32.1-100.6a25.624,25.624,0,0,1,48.8,0l31.1,97.7,31.6-101.8a19.482,19.482,0,1,1,37.1,11.9l-42.9,128.9a25.806,25.806,0,0,1-48.9.1L582.683,68a2.671,2.671,0,0,0-5.1,0l-30.2,89.4a27.149,27.149,0,0,1-51.6-.4l-40.4-127.1A19.868,19.868,0,0,1,474.283,3.8Zm231.7,86.6c0-52.7,37.8-90.1,87.5-90.1,43.5,0,82.8,28.1,82.9,89.5a9.133,9.133,0,0,1-9.1,9.1h-123.6c3.3,27.3,23.8,46.2,53,46.2a60.3,60.3,0,0,0,43.1-17.5,17.117,17.117,0,0,1,23.7,24.7c-16.6,16.5-39.2,27.6-67.8,27.6C742.083,179.9,705.983,142.1,705.983,90.4Zm132.1-20.5c-4.9-24.1-22.1-36.5-44.9-36.5-24.1,0-42.3,15-48.2,36.5Zm52.8,20.2c0-52.4,39.7-90.1,90.1-90.1s90.1,37.8,90.1,90.1-39.7,90.5-90.1,90.5C930.583,180.5,890.883,142.5,890.883,90.1Zm90.1,52.4c28.3,0,49.8-21.5,49.8-52.4s-21.5-52.4-49.8-52.4-49.8,21.5-49.8,52.4S952.683,142.5,980.983,142.5Zm-800.2-52.3c0-52.4-39.7-90.1-90.1-90.1S.583,37.9.583,90.2c0,52.4,39.7,90.5,90.1,90.5a91.683,91.683,0,0,0,45.7-11.8c-7.8,21.3-27.1,34.4-52.3,34.4a64.112,64.112,0,0,1-15.9-1.9,18.968,18.968,0,0,0-21.9,10.3l-.1.2a18.836,18.836,0,0,0,12.5,26.1,108.254,108.254,0,0,0,26.3,3.2c56.3,0,95.7-39.2,95.7-95.3Zm-90.1,52.4c-28.3,0-49.8-21.5-49.8-52.4s21.5-52.4,49.8-52.4,49.8,21.5,49.8,52.4S118.983,142.6,90.683,142.6Z"
        transform="translate(-0.583)"
        fill={props.color}
      />
    </svg>
  );
};

export default GroweoIcon;
