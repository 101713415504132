import styled from "styled-components";
import defaultTheme from "../theme";

const PolicyText = styled.div`
  position: absolute;
  bottom: 0px;
  left: 20px;
`;

PolicyText.defaultProps = {
  theme: defaultTheme,
};

export default PolicyText;
