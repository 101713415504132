import styled from "styled-components";
import defaultTheme from "../theme";

const CloseButton = styled.div`
  background-color:  ${({ theme }) => `${theme.closeBgColor}`};
  border-radius:${({ theme }) => `${theme.closeBorderRadius}`};
  margin:${({ theme }) => `${theme.closeMargin}`};
  position: absolute;
  top: 0;
  right: ${({ theme, barWidth }) => `${theme.direction === 'left' ? `${barWidth}px` : '0'}`};
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  padding: 0px;
  width: 40px;
  height: 40px;
  border: 0;
  cursor: pointer;
  z-index: 99;
  ${({ disabled }) => disabled ? 'pointer-events: none;' : ''}
`;

CloseButton.defaultProps = {
	theme: defaultTheme,
};

export default CloseButton;
