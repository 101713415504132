/* eslint-disable react/no-danger */
import React from 'react';
import { CardText, CardTitle, SuccessMessageBody } from '../custom_components';

class SuccessMessageComponent extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
  }

  componentDidMount() {
    setTimeout(() => {
      this.props.setOpened(false);
      // this.props.setIsFormSubmit(false);
    }, 3000);
  }

  render() {
    const { appearance } = this.props;
    return (
      <SuccessMessageBody className="gsb-success-message">
        <CardTitle
          fontSizeMultiplier={this.props?.fontSizeMultiplier}
          className="gsb-card-title"
          color={appearance?.successTitleFontColor} 
        >
          <span
            dangerouslySetInnerHTML={{
              __html: appearance?.successMsgTitle
            }}
          ></span>
        </CardTitle>
        <CardText
          fontSizeMultiplier={this.props.fontSizeMultiplier}
          className="gsb-card-text"
          color={appearance?.successSubTitleFontColor} 
        >
          <span
            dangerouslySetInnerHTML={{
              __html: appearance?.successMsgSubTitle
            }}
          ></span>
        </CardText>
      </SuccessMessageBody>
    );
  }
}

export default SuccessMessageComponent;
