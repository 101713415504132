export default {
  wrapperHeight: '650px',
  wrapperFontFamily: 'Proxima Nova',
  wrapperWidth: 'auto',
  wrapperBgColor: '#ffffff',
  wrapperBgImage: 'url()',
  mobileWrapperBgImage: 'url()',
  titleColor: '#000000',
  titleFontSize: '50px',
  titleFontWeight: 400,
  titleTop: '20px',
  titleWrapPadding: '5px',
  subtitleColor: '#000000',
  subtitleFontSize: '18px',
  subtitleFontWeight: 400,
  subtitleTop: '20px',
  subtitleWrapPadding: '5px',
  contentColor: '#000000',
  contentFontSize: '24px',
  contentFontWeight: 400,
  contentWrapPadding: '20px',
  opTitleColor: '#000000',
  opTitleFontSize: '20px',
  opTitleFontWeight: 500,
  opSubtitleColor: '#000000',
  opSubtitleFontSize: '18px',
  opSubtitleFontWeight: 400,
  opImgOutline: '1px solid',
  opImgOutlineColor: '#000000',
  opImgHoverOutlineColor: '#41B35C',
  formLabelColor: '#000000',
  formLabelFontSize: '18px',
  formLabelFontWeight: 400,
  submitColor: '#fff',
  submitFontSize: '18px',
  submitBgColor: '#4F4F4F',
  submitHoverColor: '#fff',
  closeBgColor: '#DDDDDD',
  closeBorderRadius: '0px',
  closeMargin: '0px'
};
