/* eslint-disable func-names */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React, { Component } from "react";
import styled from "styled-components";
import Title from "../components/Title";
import SubTitle from "../components/SubTitle";
import { flexGrowContent } from "../utils";

const FormWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${({ slideData }) => `${slideData?.fontSize}px`};
  color: ${({ slideData }) => slideData?.fontColor};
  min-height: ${({ minHeight }) => minHeight};
  height: ${({ slideData }) => `${slideData?.height}px`};
  position: absolute;
  width: 100%;
  top: ${({ slideData }) => `calc(20px + ${slideData?.contentTop}%)`};
`;

class Form extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      fileS3Url: {},
      column1: [],
      column2: [],
    };
  }

  componentDidMount() {
    const { fields, isDragAndDrop } = this.props.data?.data?.content;
    const { formMultiColumn } = this.props?.appearance?.style;
    if (!this.props.isMobile && formMultiColumn && formMultiColumn === 50) {
      const column1 = fields.filter(fld => fld.column === 1);
      const column2 = fields.filter(fld => fld.column === 2);
      if (!isDragAndDrop) {
        column1.sort((a, b) => {
          return a.row < b.row ? -1 : 1;
        });
        column2.sort((a, b) => {
          return a.row < b.row ? -1 : 1;
        });
      }
      this.setState({ column1: [...column1] });
      this.setState({ column2: [...column2] });
    } else this.setState({ column1: [...fields] });
    setTimeout(() => {
      if (this.props.isSession || this.props.data.value) this.setNodeForm();
    }, 100);
  }

  uploadFiles(el) {
    const { isSession, isEditor } = this.props
    if (isSession || isEditor) return;
    const fileData = new FormData();
    const getBtn = document.querySelector(".gsf-submit-btn")
    if (getBtn) getBtn.setAttribute("disabled", "");
    if (!el.target.value) return;
    const fileName = el.target.value.split("\\");
    fileData.append("files", el.target.files[0]);
    fileData.append("fileNames", fileName[fileName.length - 1]);
    el.persist();
    const xhr = new XMLHttpRequest();
    const url = this.props.baseUrl.split("/api")[0];
    xhr.open(
      "POST",
      `${url}/api/session/upload/${this.props.moduleId}/${this.props.trackingId}`,
      true
    );
    xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");
    xhr.send(fileData);
    xhr.onload = () => {
      JSON.parse(xhr.responseText)[0]
        ? el.target.setAttribute("fileurl", JSON.parse(xhr.responseText)[0])
        : (el.target.value = "");
      if (getBtn) getBtn.removeAttribute("disabled");
    };
  }

  setNodeForm() {
    const formDetails = this.props.isSession
      ? this.props.details
      : this.props.data.value;
    let form;
    if (this.props.isSession) {
      const searchModule = document.querySelector(".shadow-div");
      const searchModuleRoot = searchModule && searchModule.shadowRoot;
      form = searchModuleRoot.querySelector(`#gsf-form-${this.props.data?.id}`);
    } else form = document.getElementById(`gsf-form-${this.props.data?.id}`);
    if (!form) return;
    const submitEl = form.querySelectorAll("[type=submit]");
    for (let i = 0; i < submitEl.length; i++) submitEl[i].disabled = true;
    form.reset();
    Object.keys(formDetails).forEach((key) => {
      if (!key) return;
      const el = form.querySelectorAll(`[name="${key}"]`);
      if (!el.length) {
        if (key === "email" || key === "phone") {
          const el = form.querySelector(
            `input[type=${key === "email" ? "email" : "tel"}]`
          );
          if (el) el.value = formDetails[key];
        }
        return;
      }
      if (el[0].type === "checkbox" || el[0].type === "radio") {
        for (let i = 0; i < el.length; i++)
          el[i].checked = formDetails[key].includes(el[i].value);
      } else if (el[0].type === "submit") el[0].disabled = true;
      else if (el[0].type === "date")
        el[0].value = formDetails[key]
          .split("-")
          .reverse()
          .join("-");
      else if (el[0].type === "file") el[0].src = formDetails[key];
      else el[0].value = formDetails[key];
    });
  }

  // eslint-disable-next-line class-methods-use-this
  validateCheckboxInput(e) {
    if (!e.target.name) return;
    const checkboxGroup = document.getElementsByName(e.target.name);
    const checkboxInputList = Array.from(checkboxGroup);
    const isAnyChecked = checkboxInputList.find((item) => item.checked);
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < checkboxInputList.length; i++) {
      if (isAnyChecked) {
        checkboxInputList[i].removeAttribute("required");
        checkboxInputList[i].setCustomValidity("");
      } else {
        checkboxInputList[i].setAttribute("required", "");
        this.errorMessage(e.target);
      }
    }
  }

  errorMessage(field) {
    let message = "";
    // eslint-disable-next-line react/destructuring-assignment, react/prop-types
    if (this.props.language === "fi") {
      if (["dropdwon"].includes(field.type))
        message = "Valitse vähintään yksi valinta";
      else if (["radio"].includes(field.type))
        message = "Valitse vähintään yksi vaihtoehto";
      else if (["checkbox"].includes(field.type))
        message = "Tämä valinta on pakollinen";
      else if (["file"].includes(field.type)) message = "Valitse tiedosto";
      else message = "Tämä kenttä on pakollinen";
    }
    return message;
  }

  render() {
    const content = this.props.data?.data?.content;
    const { id } = this.props.data;
    const { formMultiColumn, formStyle } = this.props?.appearance?.style;
    const { isMobile } = this.props;

    const renderFormField = (fields) =>
      fields?.map((field, index) => (
        <div
          key={`field${index}`}
          // eslint-disable-next-line no-nested-ternary
          style={{ marginTop: index ? (isMobile ? 8 : 16) : "" }}
        >
          {field.showlabel && (
            <label className="gsf-form-label" htmlFor={field.name}>
              {field.label}
              {field.showlabel && field.require && (
                <span style={{ color: "red" }}>*</span>
              )}
            </label>
          )}
          {field.showlabel && <br />}
          <div className="gsf-input-wrapper">
            {!["checkbox", "dropdwon", "textarea", "radio", "extra"].includes(
              field.type
            ) && (
              <input
                id={field.name}
                aria-label={field.name}
                type={field.type}
                name={field.name}
                aria-required={field.require}
                required={field.require}
                className={field.type === 'file' ? "gsf-input-file" : ''}
                onChange={(e) => {
                  field.type === "file" && this.uploadFiles(e);
                }}
                placeholder={field.placeholder}
                onInvalid={(e) =>
                  e.target.setCustomValidity(this.errorMessage(field))
                }
                onInput={(e) => e.target.setCustomValidity("")}
              />
            )}
            {field.type === "textarea" && (
              <textarea
                id={field.name}
                aria-label={field.name}
                style={{ height: "88px" }}
                name={field.name}
                required={field.require}
                aria-required={field.require}
                placeholder={field.placeholder}
                onInvalid={(e) =>
                  e.target.setCustomValidity(this.errorMessage(field))
                }
                onInput={(e) => e.target.setCustomValidity("")}
              />
            )}
            {["dropdwon"].includes(field.type) && (
              <select
                name={field.name}
                id={field.name}
                aria-label={field.name}
                aria-required={field.require}
                required={field.require}
                onInvalid={(e) =>
                  e.target.setCustomValidity(this.errorMessage(field))
                }
                onInput={(e) => e.target.setCustomValidity("")}
              >
                <option value="">Valitse</option>
                {field.options?.split("\n").map((option, i) => (
                  <option key={`op${i}`} value={option.trim()}>
                    {option.trim()}
                  </option>
                ))}
              </select>
            )}
            {["checkbox", "radio"].includes(field.type) &&
              field.options?.split("\n").map((option, i) => (
                <div key={`op${i}`}>
                  <input
                    id={field.name + i}
                    type={field.type}
                    name={field.name}
                    aria-required={field.require}
                    aria-label={field.name}
                    style={{ marginLeft: "10px" }}
                    required={field.require}
                    onChange={this.validateCheckboxInput}
                    value={option.trim()}
                    onInvalid={(e) =>
                      e.target.setCustomValidity(this.errorMessage(field))
                    }
                    onInput={(e) => e.target.setCustomValidity("")}
                  />
                  <label
                    className="gsf-form-label"
                    htmlFor={field.name + i}
                    dangerouslySetInnerHTML={{ __html: option.trim() }}
                  >
                    {/* {option.trim()} */}
                  </label>
                  <br />
                </div>
              ))}
          </div>
        </div>
      ));

    return (
      <div className="gsf-container" style={isMobile ? flexGrowContent : {}}>
        <div className={`gsf-title gsf-title-${id}`}>
          <div
            className="gsf-wrap-text"
            dangerouslySetInnerHTML={{ __html: content?.title }}
          />
        </div>
        <div className={`gsf-subtitle gsf-subtitle-${id}`}>
          <div
            className="gsf-wrap-text"
            dangerouslySetInnerHTML={{ __html: content?.paragraph1 }}
          />
        </div>
        <div
          className={`gsf-form-wrapper ${!isMobile ? "gsf-form-mobile" : ""}`}
        >
          <form
            id={`gsf-form-${this.props.data?.id}`}
            style={{ maxHeight: isMobile ? "initial" : 335 }}
            className={`gsf-form simple-form white ${formStyle || "underline-style"}`}
          >
            <div className={`${isMobile ? "" : "row"}`}>
              <div
                style={{ marginBottom: "12px" }}
                className={`${isMobile ? "" : "form-column1"}`}
              >
                {renderFormField(this.state.column1)}
              </div>
              {formMultiColumn === 50 && (
                <div
                  style={{ marginBottom: "12px" }}
                  className={`${isMobile ? "" : "form-column2"}`}
                >
                  {renderFormField(this.state.column2)}
                </div>
              )}
            </div>
          </form>
        </div>
      </div>
    );
  }
}
export default Form;
