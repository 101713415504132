import styled from 'styled-components';

const Footer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: end;
  margin-right: 16px;
`;

export default Footer;
