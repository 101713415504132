import React from "react";

const PolicyTextIcon = (props) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="200px"
      height="30px"
      viewBox="0 0 1466.000000 86.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,86.000000) scale(0.100000,-0.100000)"
        fill="#5b5b5b"
        stroke="none"
      >
        <path
          d="M870 840 c-41 -41 -10 -110 48 -110 36 0 62 28 62 67 0 57 -69 84
-110 43z"
        />
        <path
          d="M7162 834 c-42 -29 -18 -104 34 -104 62 1 80 80 24 110 -25 13 -32
13 -58 -6z"
        />
        <path
          d="M7351 823 c-33 -40 -7 -93 44 -93 51 0 74 75 33 104 -32 23 -54 20
-77 -11z"
        />
        <path
          d="M10122 827 c-27 -29 -28 -51 -2 -77 25 -25 62 -26 83 -2 23 25 21 58
-3 82 -26 26 -52 25 -78 -3z"
        />
        <path
          d="M10320 830 c-38 -38 -14 -100 38 -100 27 0 62 33 62 58 0 23 -40 62
-63 62 -9 0 -26 -9 -37 -20z"
        />
        <path
          d="M11752 834 c-27 -19 -29 -64 -4 -87 54 -49 127 19 81 76 -23 31 -45
34 -77 11z"
        />
        <path
          d="M11941 823 c-33 -40 -7 -93 44 -93 51 0 74 75 33 104 -32 23 -54 20
-77 -11z"
        />
        <path
          d="M40 520 l0 -310 60 0 60 0 0 88 c0 69 4 94 20 120 11 17 25 32 32 32
7 0 53 -54 103 -120 l90 -120 72 0 c40 0 73 3 73 8 0 4 -43 61 -96 127 -53 66
-109 137 -125 157 l-30 37 116 135 c63 75 115 140 115 146 0 6 -29 10 -70 10
l-70 0 -106 -135 c-59 -74 -111 -132 -115 -129 -5 3 -9 63 -9 135 l0 129 -60
0 -60 0 0 -310z"
        />
        <path
          d="M625 818 c-3 -7 -4 -146 -3 -308 l3 -295 55 0 55 0 0 305 0 305 -53
3 c-36 2 -54 -1 -57 -10z"
        />
        <path
          d="M1100 520 l0 -311 53 3 52 3 3 56 c2 46 8 61 31 83 l28 27 59 -83 59
-83 68 -3 c37 -2 67 0 67 4 0 4 -38 58 -85 120 -47 63 -85 116 -85 119 0 3 36
46 80 96 44 50 80 95 80 100 0 11 -111 12 -125 1 -6 -4 -43 -45 -84 -92 -41
-47 -78 -86 -82 -88 -5 -2 -10 77 -11 175 l-3 178 -52 3 -53 3 0 -311z"
        />
        <path
          d="M1595 818 c-3 -7 -4 -146 -3 -308 l3 -295 55 0 55 0 5 61 c5 57 27
104 51 104 4 0 33 -38 65 -85 l58 -85 68 0 c53 0 67 3 62 13 -4 6 -43 60 -86
118 -43 59 -78 110 -78 114 0 4 35 47 78 97 42 49 79 93 80 99 2 5 -24 9 -58
9 -60 0 -63 -1 -101 -42 -22 -24 -58 -66 -82 -95 -23 -29 -45 -52 -49 -53 -4
0 -9 80 -10 178 l-3 177 -53 3 c-36 2 -54 -1 -57 -10z"
        />
        <path
          d="M4407 824 c-4 -4 -7 -144 -7 -311 l0 -303 55 0 55 0 -2 308 -3 307
-45 3 c-25 2 -49 0 -53 -4z"
        />
        <path
          d="M4635 818 c-3 -7 -4 -146 -3 -308 l3 -295 55 0 55 0 0 305 0 305 -53
3 c-36 2 -54 -1 -57 -10z"
        />
        <path
          d="M5617 823 c-4 -3 -7 -143 -7 -310 l0 -303 55 0 55 0 0 148 c0 141 1
150 23 170 48 45 117 51 151 13 12 -14 16 -48 18 -171 l3 -155 55 0 55 0 0
175 c0 207 -6 229 -71 261 -54 26 -109 21 -179 -17 -25 -13 -48 -24 -51 -24
-2 0 -4 50 -4 110 l0 110 -48 0 c-27 0 -52 -3 -55 -7z"
        />
        <path
          d="M7610 521 l0 -311 55 0 55 0 0 58 c0 51 4 62 28 85 33 32 28 36 106
-75 l47 -68 65 0 c35 0 64 3 64 8 0 4 -39 58 -85 121 -72 96 -83 116 -73 130
7 9 43 50 80 92 38 42 68 82 68 88 0 8 -20 11 -62 9 l-62 -3 -78 -90 c-42 -49
-82 -91 -87 -93 -7 -2 -11 58 -13 175 l-3 178 -52 3 -53 3 0 -310z"
        />
        <path
          d="M9575 818 c-3 -7 -4 -146 -3 -308 l3 -295 49 -3 c59 -4 66 4 66 74 0
41 5 55 25 74 29 27 24 31 95 -73 l52 -77 64 0 c37 0 64 4 64 10 0 6 -36 58
-80 117 -44 58 -80 111 -80 117 0 6 36 52 80 102 43 51 76 94 73 98 -4 3 -32
6 -63 6 l-57 0 -87 -101 -86 -100 -2 183 -3 183 -53 3 c-36 2 -54 -1 -57 -10z"
        />
        <path
          d="M12730 520 l0 -311 53 3 52 3 3 152 3 152 32 23 c45 32 112 32 138
-1 17 -21 19 -40 19 -177 l0 -154 55 0 55 0 0 183 c0 165 -2 185 -20 215 -41
67 -136 78 -228 27 -24 -14 -46 -25 -48 -25 -2 0 -5 48 -6 108 l-3 107 -52 3
-53 3 0 -311z"
        />
        <path
          d="M13577 824 c-4 -4 -7 -54 -7 -111 0 -56 -3 -103 -7 -103 -4 0 -16 8
-27 19 -23 20 -78 41 -110 41 -12 0 -44 -11 -73 -24 -72 -34 -105 -91 -111
-195 -4 -64 -1 -86 18 -129 50 -116 169 -154 272 -87 l37 24 3 -22 c3 -19 10
-22 61 -25 l57 -3 -2 308 -3 308 -50 3 c-28 2 -54 0 -58 -4z m-37 -283 c23
-17 25 -25 28 -108 3 -85 1 -91 -20 -105 -91 -59 -190 -6 -190 101 0 67 24
106 82 133 22 11 71 0 100 -21z"
        />
        <path
          d="M9047 774 c-4 -4 -7 -31 -7 -61 l0 -53 -35 0 -35 0 0 -49 c0 -49 1
-50 33 -53 l32 -3 5 -140 c7 -158 17 -186 77 -206 35 -11 106 -6 132 10 10 7
11 18 4 47 -8 35 -12 39 -41 39 -18 0 -37 7 -42 15 -5 8 -10 66 -10 128 l0
112 46 0 45 0 -3 48 -3 47 -42 3 -42 3 -3 57 -3 57 -50 3 c-28 2 -54 0 -58 -4z"
        />
        <path
          d="M11077 774 c-4 -4 -7 -31 -7 -61 0 -53 0 -53 -32 -54 -18 -1 -63 -1
-99 1 l-66 2 -36 -94 c-20 -51 -46 -119 -57 -150 -12 -32 -25 -58 -29 -58 -5
0 -32 64 -61 143 -30 78 -58 145 -64 149 -13 9 -100 11 -108 2 -3 -3 34 -104
83 -225 96 -236 99 -255 51 -287 -24 -15 -82 -18 -582 -22 l-555 -5 -3 -37 -3
-38 538 -2 c296 -2 1439 -2 2541 0 l2002 2 0 40 0 40 -1906 0 -1905 0 7 23 c4
12 24 65 45 117 22 52 65 159 96 237 32 79 61 143 66 143 4 0 7 -18 7 -40 0
-39 1 -40 35 -40 l35 0 0 -112 c0 -142 7 -182 35 -212 28 -30 99 -43 149 -26
39 13 42 20 25 68 -9 26 -15 31 -34 26 -14 -3 -28 1 -39 11 -14 14 -16 35 -14
129 l3 111 43 3 43 3 -3 47 -3 47 -42 3 -42 3 -3 57 -3 57 -50 3 c-28 2 -54 0
-58 -4z"
        />
        <path
          d="M11390 721 l0 -60 -37 -3 c-38 -3 -38 -4 -41 -50 l-3 -47 38 -3 38
-3 5 -146 c4 -125 8 -150 24 -168 26 -29 81 -44 129 -37 59 10 67 19 55 66
-10 36 -13 40 -41 37 -46 -3 -58 27 -55 148 l3 100 43 3 43 3 -3 47 -3 47 -42
3 -43 3 0 60 0 59 -55 0 -55 0 0 -59z"
        />
        <path
          d="M14370 721 l0 -60 -37 -3 c-38 -3 -38 -4 -41 -50 l-3 -47 38 -3 38
-3 5 -146 c4 -125 8 -150 24 -168 26 -29 81 -44 129 -37 59 10 67 19 55 67
-10 37 -13 40 -39 34 -47 -11 -60 23 -57 146 l3 104 43 3 43 3 -3 47 -3 47
-42 3 -43 3 0 60 0 59 -55 0 -55 0 0 -59z"
        />
        <path
          d="M2184 650 c-33 -11 -68 -28 -78 -38 -16 -18 -16 -21 1 -51 20 -34 34
-39 52 -17 18 22 96 36 137 25 42 -12 64 -43 64 -91 0 -21 -1 -38 -2 -38 -2 0
-25 11 -52 25 -59 30 -106 31 -163 6 -73 -33 -101 -108 -68 -182 37 -81 154
-111 238 -61 46 27 47 27 47 2 0 -18 7 -20 56 -20 l55 0 -3 179 c-3 163 -5
182 -24 208 -47 64 -156 87 -260 53z m154 -256 c15 -10 22 -25 22 -48 0 -43
-33 -66 -92 -66 -59 0 -88 22 -88 65 0 44 29 65 90 65 25 0 56 -7 68 -16z"
        />
        <path
          d="M2704 656 c-58 -19 -104 -48 -104 -66 0 -8 9 -25 18 -38 l18 -24 37
22 c52 31 129 31 163 -1 18 -17 24 -33 24 -65 l0 -43 -34 20 c-55 32 -126 36
-180 9 -60 -29 -80 -65 -74 -136 5 -63 25 -89 87 -116 51 -23 117 -18 167 13
31 19 32 19 37 -1 5 -19 12 -21 59 -18 l53 3 0 170 c0 196 -7 220 -77 256 -51
26 -140 33 -194 15z m134 -262 c15 -10 22 -25 22 -49 0 -44 -29 -65 -91 -65
-39 0 -51 5 -68 26 -12 15 -21 32 -21 39 0 7 9 24 21 39 17 21 29 26 68 26 26
0 56 -7 69 -16z"
        />
        <path
          d="M3276 640 l-57 -29 -9 25 c-9 23 -15 25 -57 22 l-48 -3 -3 -222 -2
-223 55 0 55 0 0 145 c0 144 0 145 28 175 39 43 83 49 116 17 26 -26 26 -28
26 -182 l0 -155 55 0 55 0 0 148 0 148 30 29 c22 21 40 29 65 29 64 0 70 -15
73 -194 l3 -160 55 0 54 0 0 183 c0 201 -4 217 -60 255 -28 19 -43 23 -78 18
-48 -6 -94 -28 -123 -57 l-20 -20 -21 27 c-24 29 -74 54 -112 54 -13 0 -49
-13 -80 -30z"
        />
        <path
          d="M3994 652 c-28 -10 -63 -26 -78 -35 -25 -17 -25 -18 -9 -52 9 -19 20
-35 24 -35 4 0 24 9 43 21 48 28 118 29 154 2 21 -16 28 -30 30 -67 l3 -46
-40 21 c-58 30 -113 35 -166 15 -60 -23 -89 -65 -89 -128 0 -52 19 -86 68
-122 39 -29 128 -28 179 2 46 27 43 27 50 2 4 -17 14 -20 56 -20 l51 0 0 174
c0 163 -1 175 -23 206 -47 72 -154 98 -253 62z m144 -258 c15 -10 22 -25 22
-49 0 -44 -29 -65 -92 -65 -55 0 -88 24 -88 65 0 41 33 65 88 65 27 0 57 -7
70 -16z"
        />
        <path
          d="M4986 659 c-45 -11 -116 -49 -116 -61 0 -13 30 -68 37 -68 3 0 20 9
39 20 33 21 102 28 142 16 25 -8 52 -54 52 -89 0 -35 -8 -38 -43 -17 -70 43
-170 34 -222 -20 -39 -40 -46 -119 -15 -168 44 -70 156 -90 238 -42 l34 20 5
-20 c5 -17 14 -20 59 -20 l54 0 0 163 c0 149 -2 166 -22 207 -15 29 -36 50
-60 63 -44 22 -128 30 -182 16z m117 -260 c73 -39 32 -119 -60 -119 -33 0 -50
6 -68 25 -14 13 -25 31 -25 40 0 9 11 27 25 40 27 28 89 34 128 14z"
        />
        <path
          d="M7204 652 c-28 -10 -63 -26 -78 -35 -25 -17 -25 -18 -9 -52 9 -19 20
-35 24 -35 4 0 24 9 43 21 48 28 118 29 154 2 22 -16 28 -30 30 -68 l3 -47
-34 21 c-51 31 -117 37 -173 16 -59 -22 -84 -62 -84 -132 0 -44 5 -58 31 -88
52 -59 136 -68 222 -25 37 20 37 20 37 -1 0 -18 5 -20 58 -17 l57 3 0 165 c-1
136 -4 171 -18 201 -37 74 -160 108 -263 71z m144 -258 c15 -10 22 -25 22 -49
0 -44 -29 -65 -90 -65 -78 0 -118 62 -70 110 25 25 105 27 138 4z"
        />
        <path
          d="M8187 658 c-21 -6 -54 -26 -73 -45 -29 -29 -34 -40 -34 -81 0 -79 34
-109 157 -138 73 -17 103 -36 103 -65 0 -45 -99 -53 -176 -14 -27 14 -52 25
-55 25 -3 0 -14 -16 -25 -36 l-19 -36 23 -19 c35 -28 98 -43 177 -43 108 0
174 46 182 126 8 80 -39 125 -158 152 -79 18 -99 29 -99 57 0 42 89 53 155 19
22 -11 42 -20 45 -20 11 0 40 62 34 72 -23 37 -168 66 -237 46z"
        />
        <path
          d="M10186 659 c-48 -12 -116 -49 -116 -64 0 -8 13 -31 35 -63 2 -2 21 7
43 18 45 24 117 29 152 10 24 -13 40 -48 40 -91 0 -16 -1 -29 -2 -29 -2 0 -25
11 -52 25 -61 31 -109 32 -166 3 -56 -29 -83 -76 -77 -135 6 -58 30 -91 84
-115 54 -24 117 -19 170 12 42 25 43 25 43 0 0 -18 7 -20 56 -20 l55 0 -3 179
c-3 163 -5 182 -24 207 -42 57 -149 86 -238 63z m118 -261 c47 -21 47 -85 1
-106 -36 -17 -96 -15 -123 4 -15 10 -22 25 -22 49 0 24 7 39 22 49 26 19 87
21 122 4z"
        />
        <path
          d="M11802 651 c-99 -34 -146 -104 -146 -216 0 -102 37 -166 124 -211 51
-26 162 -24 216 4 83 43 131 133 121 226 -10 103 -66 173 -159 201 -66 19 -91
19 -156 -4z m150 -108 c98 -89 34 -261 -89 -238 -43 8 -69 33 -84 83 -20 69
10 149 65 173 29 12 85 3 108 -18z"
        />
        <path
          d="M12348 659 c-51 -15 -122 -80 -143 -131 -24 -62 -21 -151 8 -207 59
-115 234 -155 355 -81 34 21 34 27 0 73 -13 17 -16 17 -43 3 -46 -24 -93 -30
-135 -17 -42 12 -80 52 -80 82 0 17 12 19 163 21 l162 3 -3 45 c-4 49 -33 123
-60 153 -45 50 -150 76 -224 56z m117 -94 c36 -18 61 -55 52 -79 -5 -13 -25
-16 -106 -16 -55 0 -102 4 -105 8 -8 14 24 67 52 85 34 21 65 21 107 2z"
        />
        <path
          d="M13937 654 c-138 -43 -198 -220 -116 -348 51 -82 147 -118 253 -96
47 10 67 20 106 58 127 124 77 336 -91 386 -63 19 -89 19 -152 0z m157 -123
c23 -24 30 -41 34 -84 5 -69 -16 -113 -65 -134 -82 -34 -163 27 -163 122 0
114 118 172 194 96z"
        />
        <path
          d="M867 653 c-4 -3 -7 -105 -7 -225 l0 -218 55 0 55 0 0 225 0 225 -48
0 c-27 0 -52 -3 -55 -7z"
        />
        <path
          d="M6090 653 c0 -5 40 -107 89 -228 48 -121 86 -227 84 -236 -9 -33 -43
-59 -78 -59 -31 0 -35 -3 -41 -35 -11 -59 -2 -69 61 -62 66 7 109 31 135 75
11 18 61 135 111 260 50 125 97 238 104 252 l13 25 10 -25 c5 -14 45 -112 87
-217 l77 -193 59 0 c50 0 60 3 70 23 29 53 168 411 163 419 -8 13 -102 10
-111 -4 -4 -7 -31 -74 -59 -148 -28 -74 -55 -135 -60 -137 -5 -2 -34 63 -64
145 l-55 147 -120 0 -120 0 -55 -147 c-30 -82 -59 -147 -64 -145 -5 2 -32 64
-60 138 -28 74 -55 140 -59 147 -9 13 -117 18 -117 5z"
        />
        <path
          d="M8488 654 c-3 -3 34 -105 83 -227 80 -200 88 -225 78 -250 -14 -33
-37 -47 -77 -47 -27 0 -31 -4 -37 -40 -9 -56 -2 -63 62 -57 65 7 111 32 136
74 10 18 65 147 122 288 76 188 100 257 91 261 -8 3 -35 4 -61 2 l-48 -3 -54
-145 c-30 -79 -56 -147 -59 -149 -8 -9 -17 9 -65 140 -27 73 -54 138 -60 146
-11 13 -100 19 -111 7z"
        />
      </g>
    </svg>
  );
};

export default PolicyTextIcon;
