import React from "react";
import axios from "axios";
import ChatBot from "./ChatBot";
import CardComponent from "./custom_components/CardComponent";
import FormComponent from "./custom_components/FormComponent";
import CarouselComponent from "./custom_components/CarouselComponent";
import { getRenderedSteps, postActivity } from "./utils";
import conf from "./conf/conf";

class ChatBotX extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: true,
      inited: false,
      previousSteps: [],
      trackedClick: [],
    };
    this.props = props;
    console.log("ChatBotX constructor");
    if (window.top && props.moduleId) {
      window.top.postMessage(
        {
          type: "init",
          width: props.width,
          height: props.height,
          moduleId: props.moduleId,
          popup: !props.inPage
        },
        "*"
      );
    }
  }

  render() {
    const { show } = this.state;

    const props = Object.assign({}, this.props);

    if (Number(props.height)) {
      props.height += "px";
    }

    if (Number(props.width)) {
      props.width += "px";
    }

    if (!props.fontSizeMultiplier) {
      props.fontSizeMultiplier = 1;
    }

    const onPublishStep = data => {
      if (this.props.isPreview || !data.component) return;
      const { trackingId, moduleId, workspaceId, baseUrl } = props;
      if (!workspaceId || !trackingId || !moduleId || !baseUrl) return;
      data.trackingId = trackingId;
      data.moduleId = moduleId;
      data.workspaceId = workspaceId;
    };

    const onLinkClick = (key, markAsComplete) => {
      if (this.props.isPreview) return;
      const foundIndex = this.state.previousSteps.findIndex(
        item => item.key === key
      );
      if (!key || foundIndex === -1) return;
      const steps = [...this.state.previousSteps];
      const prevSteps = steps.slice(0, foundIndex + 1);
      const renderedData = getRenderedSteps(prevSteps);
      props.handleSessionSubmit(renderedData, markAsComplete);
    };

    const onTrackClick = (data) => {
      if (this.props.isPreview || this.state.trackedClick.includes(data.id)) return;
      this.setState({ trackedClick: [...this.state.trackedClick, data.id] });
    
      const { trackingId, moduleId, workspaceId, baseUrl, type } = props;
      const trackClick = {
        ...data,
        type,
        trackingId,
        moduleId,
        workspaceId,
      }
      if (window.location.host === "editor.unlayer.com") return;
      let url = "https://2tn2gr0q94.execute-api.eu-north-1.amazonaws.com";
      if (baseUrl.search('localhost') === -1) url = baseUrl.split("/api")[0];
      try {
        axios.post(`${url}/collectSteps`, trackClick);
      } catch (err) {
        console.error("error sending click tracking request:", err);
      }
    }
  

    const setPreviousSteps = previousSteps => {
      this.setState({ previousSteps });
    };

    for (const step of props.steps) {
      if (step.type === "card") {
        step.component = (
          <CardComponent
            fontSizeMultiplier={props.fontSizeMultiplier}
            currentElement={
              this.props.isSession ? { data: step.metadata, type: 'card' } : step.currentElement
            }
            onPublishStep={onPublishStep}
            onLinkClick={onLinkClick}
            onTrackClick={onTrackClick}
            isPreview={this.props.isPreview}
          ></CardComponent>
        );
        delete step.currentElement;
        delete step.type;
      } else if (step.type === "carousel") {
        step.component = (
          <CarouselComponent
            fontSizeMultiplier={props.fontSizeMultiplier}
            currentElement={step.currentElement}
            onPublishStep={onPublishStep}
            onLinkClick={onLinkClick}
            onTrackClick={onTrackClick}
            isPreview={this.props.isPreview}
            inPage={this.props.inPage}
          ></CarouselComponent>
        );
        delete step.type;
        delete step.currentElement;
      } else if (step.type === "form") {
        if (!props.isSession) step.waitAction = true;
        step.component = (
          <FormComponent
            moduleId={this.props.moduleId}
            trackingId={props.trackingId}
            primaryColor={this.props.primaryColor}
            fileTextColor={this.props?.appearance?.botFormLabelColor}
            currentElement={
              this.props.isSession ? { data: step.metadata } : step.currentElement
            }
            onPublishStep={onPublishStep}
            onLinkClick={onLinkClick}
            isPreview={this.props.isPreview}
            isSession={this.props.isSession}
            formData={step.value || {}}
            formId={step.id}
            language={this.props.language}
            fontSizeMultiplier={props.fontSizeMultiplier}
          ></FormComponent>
        );
        delete step.type;
        delete step.currentElement;
      }
    }

    const restart = () => {
      this.setState(state => ({ show: false }));
      setTimeout(() => {
        this.setState(state => ({ show: true }));
      }, 10);
    };

    return show ? (
      <ChatBot
        restart={restart}
        setPreviousSteps={setPreviousSteps}
        handleSessionSubmit={props.handleSessionSubmit}
        {...props}
      ></ChatBot>
    ) : (
      ""
    );
  }
}

export default ChatBotX;
