import React from 'react';

const BarDefaultIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="40px" height="40px" viewBox="0 0 24 24" version="1.1">
    <g stroke="none" stroke-width="1" fill-rule="evenodd">
        <rect x="4" y="11" width="16" height="2" rx="1"/>
        <rect opacity="0.3" transform="translate(12.000000, 12.000000) rotate(-270.000000) translate(-12.000000, -12.000000) " x="4" y="11" width="16" height="2" rx="1"/>
    </g>
  </svg>
);

export default BarDefaultIcon;