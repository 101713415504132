import React, { Component } from "react";
import PropTypes from "prop-types";
import Bubble from "./Bubble";
import Image from "./Image";
import ImageContainer from "./ImageContainer";
import Loading from "../common/Loading";
import TextStepContainer from "./TextStepContainer";
import { BotHeaderIcon } from "../../icons";
import Timestamp from "./Timestamp";

class TextStep extends Component {
  /* istanbul ignore next */
  state = {
    loading: true
  };

  componentDidMount() {
    const { step, speak, previousValue, triggerNextStep } = this.props;
    const { component, delay, waitAction } = step;
    const isComponentWatingUser = component && waitAction;

    setTimeout(() => {
      this.setState({ loading: false }, () => {
        if (!isComponentWatingUser && !step.rendered) {
          triggerNextStep();
        }
        speak(step, previousValue);
      });
    }, delay);
  }

  getMessage = () => {
    const { previousValue, step } = this.props;
    const { message } = step;

    return (
      <span
        dangerouslySetInnerHTML={{
          __html: message ? (
            message.replace(/{previousValue}/g, previousValue)
          ) : (
            ''
          )
        }}
      ></span>
    );
  };

  renderMessage = () => {
    const { step, steps, previousStep, triggerNextStep } = this.props;
    const { component } = step;

    if (component) {
      return React.cloneElement(component, {
        step,
        steps,
        previousStep,
        triggerNextStep
      });
    }

    return this.getMessage();
  };

  render() {
    const {
      step,
      isFirst,
      isLast,
      avatarStyle,
      bubbleStyle,
      hideBotAvatar,
      hideUserAvatar,
      headerTitle,
      botAvatar,
      avatarColor,
      forceShowAvatar,
      showTimestamp,
      fontSizeMultiplier
    } = this.props;
    const { loading } = this.state;
    const { avatar, botName } = step;
    let { usr, user } = step;
    if (usr) {
      user = usr;
    }
    const showAvatar = user ? !hideUserAvatar : !hideBotAvatar;

    const imageAltText = user ? "Your avatar" : `${botName}'s avatar`;
    let hours = step.date.getHours().toString();
    if (hours.length === 1) {
      hours = '0' + hours;
    }
    let minutes = step.date.getMinutes().toString();
    if (minutes.length === 1) {
      minutes = '0' + minutes;
    }
    return (
      <TextStepContainer
        fontSizeMultiplier={fontSizeMultiplier}
        className={`rsc-ts ${user ? "rsc-ts-user" : "rsc-ts-bot"}`}
        user={user}
      >
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: user ? "end" : "start",
            paddingBottom: user ? 10 : 0
          }}
        >
          {/* {showTimestamp && !loading && !user ? <Timestamp>{`${hours}:${minutes}`}</Timestamp> : ""} */}
          <Bubble
            className="rsc-ts-bubble"
            style={bubbleStyle}
            user={user}
            showAvatar={showAvatar}
            isFirst={isFirst}
            isLast={isLast}
            fontSizeMultiplier={fontSizeMultiplier}
          >
            {loading ? <Loading /> : this.renderMessage()}
          </Bubble>
        </div>
      </TextStepContainer>
    );
  }
}

TextStep.propTypes = {
  avatarStyle: PropTypes.objectOf(PropTypes.any).isRequired,
  isFirst: PropTypes.bool.isRequired,
  isLast: PropTypes.bool.isRequired,
  bubbleStyle: PropTypes.objectOf(PropTypes.any).isRequired,
  hideBotAvatar: PropTypes.bool.isRequired,
  hideUserAvatar: PropTypes.bool.isRequired,
  previousStep: PropTypes.objectOf(PropTypes.any),
  previousValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.number,
    PropTypes.object,
    PropTypes.array
  ]),
  speak: PropTypes.func,
  step: PropTypes.objectOf(PropTypes.any).isRequired,
  steps: PropTypes.objectOf(PropTypes.any),
  triggerNextStep: PropTypes.func.isRequired
};

TextStep.defaultProps = {
  previousStep: {},
  previousValue: "",
  speak: () => {},
  steps: {}
};

export default TextStep;
