import styled from 'styled-components';
import defaultTheme from '../theme';

const HeaderTitle = styled.span`
  margin: 0;
  font-style: normal;
  font-weight: bold;
  font-size: ${({ fontSizeMultiplier }) => (fontSizeMultiplier*18) + 'px'};
  line-height: 24px;
  padding: 15px;
`;

HeaderTitle.defaultProps = {
  theme: defaultTheme
};

export default HeaderTitle;
