import styled from "styled-components";

const RoundTrueContainer = styled.div`
  position: absolute;
  width: 16px;
  height: 16px;
  top: 7px;
  right: 7px;
  border-radius: 50%;
  background: ${(props) => (props.active ? "#41b35c" : "none")};
`;

// RightArrowContainer.defaultProps = {
//   theme: defaultTheme,
// };

export default RoundTrueContainer;
