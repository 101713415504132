import styled from "styled-components";
import defaultTheme from "../theme";

const SubmitButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: calc(100%);
  top: ${({ slideData }) => `calc(20px + ${slideData?.buttonTop}%)`};
`;

SubmitButtonContainer.defaultProps = {
  theme: defaultTheme
};

export default SubmitButtonContainer;
