import styled from "styled-components";

const SliderBadge = styled.button`
  display: inline-block;
  outline: 0px;
  border: 0px;
  cursor: pointer;
  background: rgb(0 0 0 / 70%);
  color: white !important;
  border-radius: 8px;
  padding: 10px 20px 12px;
  font-size: 12px;
  font-weight: 600;
  line-height: 1;
  letter-spacing: 0.1em;
  vertical-align: baseline;
  text-shadow: none;
`;

export default SliderBadge;
