import styled from 'styled-components';

// const Content = styled.div`
//   height: calc(${props => props.height} - ${props => (props.hideInput ? '56px' : '155px')});
//   overflow-y: scroll;
//   overflow-x: hidden;
//   margin-top: 2px;
//   padding-top: 6px;
//   padding: 20px;
//   font-size: 32px !important;
//   font-family: ${props => (props.theme.bubbleFontFamily)};

//   @media screen and (max-width: 568px) {
//     height: ${props => (props.floating ? 'calc(100% - 155px)' : '')};
//   }
// `;

const Content = styled.div`
  height: ${props => props.isIframe 
    ? (props.inPage ? `calc(${props.height} - ${props.hideHeader ? 56 : 127}px)` : "100%;")
    : (props.inPage ? `calc(${props.height} - ${props.hideHeader ? 56 : 127}px)` : `calc(${props.height} - ${props.hideHeader ? 73 : 144}px)`)};
  overflow-y: scroll;
  overflow-x: hidden;
  margin-top: 2px;
  padding-top: 6px;
  padding: 20px;
  font-family: ${props => (props.theme.bubbleFontFamily)};

  @media screen and (max-width: 480px) {
    height: ${props => (props.isIframe ? '100%' : props.floating ? 'calc(100% - 70px)' : '')};
  }
`;

export default Content; 
