import styled from 'styled-components';
import { scale } from '../../common/animations';

const Option = styled.li`
  animation: ${scale} 0.3s ease forwards;
  cursor: pointer;
  display: ${({ optionLayout }) => (optionLayout === 'step' ? "block" : "inline-block")};
  margin: 4px;
  transform: scale(0);
`;

export default Option;
