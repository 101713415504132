import styled from 'styled-components';
import defaultTheme from '../theme';

const CardLink = styled.a`
background-color: ${({ theme }) => theme.cardBtnBgColor};
border: 2px solid ${({ theme }) => theme.cardBtnBgColor};
padding: calc(0.75rem + 1px) calc(1.5rem + 1px);
box-shadow: none !important;
text-decoration: none;
color: ${({ theme }) => theme.cardBtnTextColor};
outline: none !important;
margin: 0.25rem !important;
display: inline-block;
font-weight: 600;
line-height: 20;
text-align: center;
vertical-align: middle;
cursor: pointer;
user-select: none;
font-size: ${({ fontSizeMultiplier }) => fontSizeMultiplier*14 + 'px' };
line-height: ${({ fontSizeMultiplier }) => fontSizeMultiplier*20 + 'px' };
border-radius: 0.475rem;
transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
&:hover {
  color: ${({ theme }) => theme.cardBtnHoverTextColor};
  background-color: ${({ theme }) => theme.cardBtnHoverBgColor};
  border: 2px solid ${({ theme }) => theme.cardBtnHoverTextColor};
}
`;

CardLink.defaultProps = {
    theme: defaultTheme
  };

  
export default CardLink;