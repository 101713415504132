import styled from "styled-components";
import defaultTheme from "../theme";

const CloseButton = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  margin: 0px;
  padding: 0px;
  width: 40px;
  height: 40px;
  border: 0;
  cursor: pointer;
  z-index: 99;
  ${({ isEditor }) => isEditor ? 'pointer-events: none;' : ''}
`;

CloseButton.defaultProps = {
  theme: defaultTheme
};

export default CloseButton;
