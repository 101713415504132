import styled from 'styled-components';
import defaultTheme from '../theme';

const Card = styled.div`
    left: ${props => (props.index ? `calc(${props.index} * ${props.cardSpacing})` : "0")};
    flex-shrink: 0;
    box-shadow: 0px 0px 20px 0px rgb(76 87 125 / 2%);
    position: relative;
    display: flex;
    flex-direction: column;
    word-wrap: break-word;
    background-color: ${props => props.theme.botBubbleColor};
    background-clip: border-box;
    border-radius: 8px;
    width: ${(props) => props.cardWidth ? props.cardWidth : "100%"}
    padding: 15px;
    box-sizing: border-box;
`;

Card.defaultProps = {
  theme: defaultTheme
};


export default Card;
