import styled from "styled-components";
import defaultTheme from "../theme";

const PopupMain = styled.div`
  width: 100%;
  max-width: 1200px;
  height: auto;
  margin: auto;
`;

PopupMain.defaultProps = {
  theme: defaultTheme
};

export default PopupMain;
