import React from "react";

const RightArrow = () => (
  <svg
    width="24"
    height="24"
    viewBox="1 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 4L14 12L6 20H10L17.3 12.7C17.7 12.3 17.7 11.7 17.3 11.3L10 4H6Z"
      fill="inherit"
    />
  </svg>
);

export default RightArrow;
