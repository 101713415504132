import styled from "styled-components";
import defaultTheme from "../theme";

const PopupContainer = styled.div`
  display: flex;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: rgba(0,0,0,0.4);
  ${({ opened }) => !opened ? 'display: none;' : ''}
`;

PopupContainer.defaultProps = {
  theme: defaultTheme
};

export default PopupContainer;
