import React, { Component } from "react";
import Slider from "react-slick";
import ImageV2 from "../components/ImageV2";
import OptionCardV2 from "../components/OptionCardV2";
import LeftArrow from "../icons/LeftArrow";
import RightArrow from "../icons/RightArrow";
import RoundTrue from "../icons/RoundTrue";
import { flexGrowContent } from "../utils";

function PrevArrowCmp(props) {
  const { className, style, onClick } = props;
  return (
    <div className={className} style={style} onClick={onClick}>
      <LeftArrow size={"43"} className={"gsf-slick-arr"}/>
    </div>
  );
}
function NextArrowCmp(props) {
  const { className, style, onClick } = props;
  return (
    <div className={className} style={style} onClick={onClick}>
      <RightArrow size={"43"} className={"gsf-slick-arr"}/>
    </div>
  );
}

class OptionsV2Slick extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeOptions: [],
      currentSlide: 0,
    };
  }

  onContentEdit = (key, value) => {
    if (this.props.isGrid) this.props.setSlideContent(key, value);
  };

  getSubmitBtn() {
    let btn;
    if (this.props.isEditor) {
      const searchModule = document.querySelector(".shadow-div");
      const searchModuleRoot = searchModule && searchModule.shadowRoot;
      btn = searchModuleRoot.querySelector(`.gsf-submit-btn`);
    } else btn = document.querySelector(`.gsf-submit-btn`);
    return btn
  }

  componentDidMount() {
    const submitBtn = this.getSubmitBtn();
    if (!this.props.data.value && submitBtn) {
      submitBtn.classList.add("gsf-disable-submit");
    }
    if (!this.props.inPage || this.props.isMobilePopup)
      window.addEventListener("scroll", this.handleBadge);
    this.setState({ activeOptions: [] });
    if (this.props.isSession || this.props.data.value) {
      const { content, name } = this.props.data?.data;
      const { details, setActiveOptions, isSession, data } = this.props;
      const slideValue = isSession ? details[name] : data.value
      if (!slideValue) return;
      let optionIndex = [];
      for (let index = 0; index < content?.options?.length; index++) {
        const { title, subTitle } = content?.options[index];
        if (slideValue.includes(`${title} ${subTitle}`))
          optionIndex.push(index);
      }
      this.setState({ activeOptions: optionIndex });
      setActiveOptions(optionIndex);
    }
  }

  componentDidUpdate() {
    if (!this.props.inPage || this.props.isMobilePopup) this.handleBadge();
  }

  handleBadge() {
    const badge = document.querySelector(".gsf-slider-badge");
    const otbadge = document.querySelector(".gsf-options");
    const clientRect = otbadge.getBoundingClientRect();
    if (clientRect.top <= 14) badge.classList.add("badge-fix");
    else badge.classList.remove("badge-fix");
  }

  render() {
    const content = this.props.data?.data?.content;
    const { setActiveOptions, isMobile, isSession, isEditor } = this.props;
    const { activeOptions } = this.state;
    const appearance = this.props?.appearance;
    const gsfOptions = {
      ...flexGrowContent,
      width: isSession ? "540px" : "",
    };
    const slidesToShow = isSession
      ? content?.options?.length > 3
        ? 3
        : content?.options?.length
      : content?.options?.length > 4
      ? 4
      : content?.options?.length;
    const responsive = [
      {
        breakpoint: 1030,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4
        },
      },
      {
        breakpoint: 825,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          slidesToScroll: 2,
          slidesToShow: 2,
        },
      },
    ];
    const settings = {
      dots: true,
      slidesToShow: isMobile ? 2 : slidesToShow,
      arrows: !isMobile,
      infinite: false,
      slidesToScroll: isMobile ? 2 : slidesToShow,
      speed: 500,
      cssEase: "linear",
      nextArrow: <NextArrowCmp />,
      prevArrow: <PrevArrowCmp />,
      responsive,
    };

    const toggleAnimation = submitBtn => {
      if (submitBtn) submitBtn.classList.add("animate__pulse");
      setTimeout(() => {
        if (submitBtn) submitBtn.classList.remove("animate__pulse");
      }, 500);
    };

    const renderOption = (opt, idx) => (
      <div
        onClick={() => {
          if (isSession) return;
          const submitBtn = this.getSubmitBtn();
          if (activeOptions.includes(idx)) {
            const foundIndex = activeOptions.findIndex((id) => id === idx);
            activeOptions.splice(foundIndex, 1);
            if (activeOptions.length < Number(content.minOption || "1") && submitBtn) {
              submitBtn.classList.add("gsf-disable-submit");
            }
            setActiveOptions(activeOptions);
            this.setState((prevState) => ({
              activeOptions: [...prevState.activeOptions],
            }));
            return;
          }
          if (
            activeOptions.length ===
            Number(
              content.maxOption && content.maxOption > 0
                ? content.maxOption
                : "1"
            )
          ) {
            toggleAnimation(submitBtn);
            return;
          }
          if (
            [...activeOptions, idx].length === Number(content?.maxOption || "1")
          )
            toggleAnimation(submitBtn);
          if ([...activeOptions, idx].length === Number(content.minOption || "1"))
            submitBtn.classList.remove("gsf-disable-submit");
          setActiveOptions([...activeOptions, idx]);
          this.setState((prevState) => ({
            activeOptions: [...prevState.activeOptions, idx],
          }));
        }}
        key={`slide${idx}`}
      >
        <div
          className={`gsf-option-imagewrapper ${activeOptions.includes(idx) &&
            "option-selected"}`}
        >
          {activeOptions.includes(idx) ? (
            <div
              style={{
                background: activeOptions.includes(idx)
                  ? appearance?.style?.optionCheckmarkColor || "#41b35c"
                  : "none",
              }}
              className="gsf-checkmark"
            >
              <RoundTrue />
            </div>
          ) : (
            ""
          )}
          {opt.url ? (
            <img className="gsf-option-image" src={opt.url} />
          ) : (
            <div className="gsf-option-placeholder">
              <div className="gsf-titles-wrapper">
                <div className="gsf-option-title">{opt?.title || ""}</div>
                <div className="gsf-option-subtitle">{opt?.subTitle || ""}</div>
              </div>
            </div>
          )}
        </div>
        {opt.url ? (
            <div className="gsf-titles-wrapper">
              <div className="gsf-option-title">{opt?.title || ""}</div>
              <div className="gsf-option-subtitle">{opt?.subTitle || ""}</div>
            </div>
          ) : ('')}
      </div>
    );

    return (
      <div className="gsf-container" style={isMobile ? flexGrowContent : {}}>
        <div className="gsf-title">
          <div
            className="gsf-wrap-text"
            dangerouslySetInnerHTML={{ __html: content?.title }}
          ></div>
        </div>
        <div className="gsf-subtitle">
          <div
            className="gsf-wrap-text"
            dangerouslySetInnerHTML={{ __html: content?.paragraph1 }}
          ></div>
        </div>
        <div
          className="gsf-options"
          style={isMobile ? { width: "356px" } : gsfOptions}
        >
          {/* <div style={{ width: "80%", margin: "auto" }}> */}
          <div className={`gsf-slider-badgewrapper`}>
            {content?.options?.length ? (
              <div
                className={`gsf-slider-badge ${
                  activeOptions.length
                    ? "gsf-selected-badge"
                    : "gsf-badge-disable"
                }`}
                onClick={() => {
                  const submitBtn = this.getSubmitBtn();
                  submitBtn.classList.add("gsf-disable-submit");
                  this.setState({ activeOptions: [] });
                  setActiveOptions([]);
                }}
              >{`${activeOptions.length}/${Number(
                content?.maxOption
              )} valittu`}</div>
            ) : (
              ""
            )}
          </div>
          {isMobile ? (
            <div className="row">
              {content?.options?.length &&
                content?.options.map((opt, idx) => (
                  <div className="col-md-6">{renderOption(opt, idx)}</div>
                ))}
            </div>
          ) : (
            <Slider {...settings}>
              {content?.options?.length &&
                content?.options.map((opt, idx) => renderOption(opt, idx))}
            </Slider>
          )}
          {/* </div> */}
        </div>
      </div>
    );
  }
}
export default OptionsV2Slick;
