import styled from 'styled-components';

const SuccessMessageBody = styled.div`
  text-align: center;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  animation: fadeIn 3s;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export default SuccessMessageBody;
