export default {
  width: '400px',
  height: '400px',
  direction: 'right',
  barFontColor: 'black',
  barBgColor: '#FED900',
  contentBgColor: '#FED900',
  contentFontColor: 'black',
  contentTitleFontColor : 'black',
  contentSubTitleFontColor : 'black',
  footerFontColor: 'black',
  successTitleFontColor : 'black',
  successSubTitleFontColor : 'black',
  invertBarColor: true,
  barFormLabelColor: '#000000',
  barFormButtonTextColor: '#fff',
  barFormButtonBgColor: '#c6a23f',
  barFormButtonHoverTextColor: '#1A175E',
  barFormButtonHoverBgColor: '#fff',
  fontFamily: 'Helvetica',
  iconBgColor: '#1A175E',
  iconColor: '#fff',
  direction: 'left',
  imageBgColor: '#1A175E',
  barFormInputTextColor: '#000',
  barFormInputBgColor: 'rgba(245, 248, 250, 1)',
  barFormInputPlaceholderColor: '#999',
};
