import React, { Component } from "react";
import PropTypes from "prop-types";
import Random from "random-id";
import { CustomStep, OptionsStep, TextStep } from "./steps_components";
import schema from "./schemas/schema";
import * as storage from "./storage";
import conf from "./conf/conf";

import {
  ChatBotContainer,
  Content,
  Header,
  HeaderTitle,
  HeaderCloseIcon,
  FloatButton,
  FloatingIcon,
  Footer,
  Input,
  SubmitButton,
  TrademarkContainer,
  TrademarkFooter
} from "./components";
import Recognition from "./recognition";
import {
  ChatIcon,
  CloseIcon,
  SubmitIcon,
  MicIcon,
  BotHeaderIcon,
  TrademarkIcon
} from "./icons";
import { postActivity, getRenderedSteps, isMobile, setGlobalItem, getGlobalItem } from "./utils";
import { speakFn } from "./speechSynthesis";
import RestartIcon from "./icons/RestartIcon";
import axios from "axios";
import Bubble from "./steps_components/text/Bubble";
import GroweoIcon from "./icons/GroweoIcon";
import PolicyTextIcon from "./icons/PolicyTextIcon";
import Timestamp from "./steps_components/text/Timestamp";
import ImageContainer from "./steps_components/text/ImageContainer";
class ChatBot extends Component {
  /* istanbul ignore next */
  constructor(props) {
    super(props);

    console.log(
      "ChatBot constructor, fontSizeMultiplier:",
      props.fontSizeMultiplier
    );

    this.content = null;
    this.input = null;

    this.supportsScrollBehavior = false;

    this.setContentRef = element => {
      this.content = element;
    };

    this.setInputRef = element => {
      this.input = element;
    };

    this.state = {
      renderedSteps: [],
      previousSteps: [],
      termsAccepted: false,
      currentStep: {},
      previousStep: {},
      steps: {},
      disabled: true,
      opened: props.opened || !props.floating,
      inputValue: "",
      inputInvalid: false,
      speaking: false,
      recognitionEnable: props.recognitionEnable && Recognition.isSupported(),
      defaultUserSettings: {},
      resizeObserver: null,
      canShow: false,
      publishedSteps: [],
      isFirstClicked: false,
      notificationItems: [],
      notificationCount: 0,
      clearTimeOutOfNotification: true
    };

    this.speak = speakFn(props.speechSynthesis);
  }

  componentDidMount() {
    const { steps, delay } = this.props;
    const {
      botDelay,
      botAvatar,
      botName,
      cache,
      cacheName,
      customDelay,
      enableMobileAutoFocus,
      userAvatar,
      userDelay
    } = this.props;
    const chatSteps = {};

    const defaultBotSettings = { delay: botDelay, avatar: botAvatar, botName };
    const defaultUserSettings = {
      delay: userDelay,
      avatar: userAvatar,
      hideInput: false,
      hideExtraControl: false
    };
    const defaultCustomSettings = { delay: customDelay };

    for (let i = 0, len = steps.length; i < len; i += 1) {
      const step = steps[i];
      let settings = {};

      if (step.user) {
        settings = defaultUserSettings;
      } else if (step.message || step.asMessage) {
        settings = defaultBotSettings;
      } else if (step.component) {
        settings = defaultCustomSettings;
      }

      chatSteps[step.id] = Object.assign({}, settings, schema.parse(step));
    }

    schema.checkInvalidIds(chatSteps);

    const firstStep = steps[0];

    if (firstStep.message) {
      const { message } = firstStep;
      firstStep.message = typeof message === "function" ? message() : message;
      chatSteps[firstStep.id].message = firstStep.message;
    }

    const { recognitionEnable } = this.state;
    const { recognitionLang } = this.props;

    if (recognitionEnable) {
      this.recognition = new Recognition(
        this.onRecognitionChange,
        this.onRecognitionEnd,
        this.onRecognitionStop,
        recognitionLang
      );
    }

    this.supportsScrollBehavior =
      "scrollBehavior" in document.documentElement.style;

    if (this.content) {
      this.content.addEventListener("DOMNodeInserted", this.onNodeInserted);
      window.addEventListener("resize", this.onResize);
    }

    const {
      currentStep,
      previousStep,
      previousSteps,
      renderedSteps
    } = storage.getData(
      {
        cacheName,
        cache,
        firstStep,
        steps: chatSteps
      },
      () => {
        // focus input if last step cached is a user step
        this.setState({ disabled: false }, () => {
          if (enableMobileAutoFocus || !isMobile()) {
            if (this.input) {
              this.input.focus();
            }
          }
        });
      }
    );

    this.setState({
      currentStep,
      defaultUserSettings,
      previousStep,
      previousSteps,
      renderedSteps,
      firstStep,
      steps: chatSteps
    });

    if (Number(delay) && !isMobile()) {
      const intervalId = setInterval(() => {
        const isGroweoSession = getGlobalItem('groweoSession');
        if (!isGroweoSession || this.props.isPreview) {
          if (this.state.canShow) this.toggleChatBot(true);
          clearInterval(intervalId)
        }
      }, Number(delay) * 1000);
    }

    this.props.setPreviousSteps(previousSteps);
    window.addEventListener("message", this.handleBotMessage);
  }

  static getDerivedStateFromProps(props, state) {
    const { opened, toggleFloating } = props;
    if (
      toggleFloating !== undefined &&
      opened !== undefined &&
      opened !== state.opened
    ) {
      return {
        ...state,
        opened
      };
    }
    return state;
  }

  componentWillUnmount() {
    if (this.content) {
      this.content.removeEventListener("DOMNodeInserted", this.onNodeInserted);
      window.removeEventListener("resize", this.onResize);
    }
    window.removeEventListener("message", this.handleBotMessage);
  }

  onRestartClicked = event => {
    const { cache, cacheName } = this.props;
    const { steps, firstStep } = this.state;

    this.setState({
      renderedSteps: [],
      previousSteps: [],
      currentStep: {},
      previousStep: {},
      publishedSteps: [],
      termsAccepted: false,
      isFirstClicked: false
    });

    const currentStep = firstStep;
    const renderedSteps = [steps[currentStep.id]];
    const previousSteps = [steps[currentStep.id]];
    const previousStep = {};

    if (cache) {
      storage.clear(cacheName);
    }
    setTimeout(() => {
      this.setState({
        currentStep,
        previousStep,
        previousSteps,
        renderedSteps
      });
      this.props.setPreviousSteps(previousSteps);
    }, 10);
  };

  onNodeInserted = event => {
    const { currentTarget: target } = event;
    const { enableSmoothScroll } = this.props;

    if (enableSmoothScroll && this.supportsScrollBehavior) {
      target.scroll({
        top: target.scrollHeight,
        left: 0,
        behavior: "smooth"
      });
    } else {
      target.scrollTop = target.scrollHeight;
    }
  };

  onResize = () => {
    this.content.scrollTop = this.content.scrollHeight;
  };

  onRecognitionChange = value => {
    this.setState({ inputValue: value });
  };

  onRecognitionEnd = () => {
    this.setState({ speaking: false });
    this.handleSubmitButton();
  };

  onRecognitionStop = () => {
    this.setState({ speaking: false });
  };

  onValueChange = event => {
    this.setState({ inputValue: event.target.value });
  };

  getTriggeredStep = (trigger, value) => {
    const steps = this.generateRenderedStepsById();
    return typeof trigger === "function" ? trigger({ value, steps }) : trigger;
  };

  getStepMessage = message => {
    const { previousSteps } = this.state;
    const lastStepIndex =
      previousSteps.length > 0 ? previousSteps.length - 1 : 0;
    const steps = this.generateRenderedStepsById();
    const previousValue = previousSteps[lastStepIndex].value;
    return typeof message === "function"
      ? message({ previousValue, steps })
      : message;
  };

  generateRenderedStepsById = () => {
    const { previousSteps } = this.state;
    const steps = {};

    for (let i = 0, len = previousSteps.length; i < len; i += 1) {
      const { id, message, value, metadata } = previousSteps[i];

      steps[id] = {
        id,
        message,
        value,
        metadata
      };
    }

    return steps;
  };

  triggerNextStep = data => {
    const { enableMobileAutoFocus,isPreview } = this.props;
    const {
      defaultUserSettings,
      previousSteps,
      renderedSteps,
      steps,
      opened
    } = this.state;
    let { currentStep, previousStep } = this.state;
    const customComponent = previousStep?.component?.props?.currentElement;
    const isEnd = currentStep.end;
    if (opened && !currentStep.options) {
      this.onPublishStep(currentStep);
    }
    // If markAsComplete is true then publish session
    if (currentStep?.markAsComplete && !currentStep.user && !isPreview) {
      console.log("Mark scussessfull conversation");
      this.onSubmitStep(currentStep?.markAsComplete);
    }

    if (customComponent?.type === "carousel" && customComponent?.data?.markAsComplete && !isPreview) {
      console.log("Mark scussessfull conversation");
      this.onSubmitStep(customComponent.data.markAsComplete);
    }

    if (data && data.value) {
      if (previousStep.metadata && previousStep.metadata.gtmEventName&&!isPreview) {
        window.sendTagEvent({
          event: previousStep.metadata.gtmEventName,
          payload: data.value
        });
      }
    }

    if (data && data.value) {
      currentStep.value = data.value;
    }
    if (data && data.hideInput) {
      currentStep.hideInput = data.hideInput;
    }
    if (data && data.hideExtraControl) {
      currentStep.hideExtraControl = data.hideExtraControl;
    }
    if (data && data.trigger) {
      currentStep.trigger = this.getTriggeredStep(data.trigger, data.value);
    }

    if (isEnd) {
      this.handleEnd();
    } else if (currentStep.options && data) {
      const option = currentStep.options.filter(o => o.value === data.value)[0];
      const trigger = this.getTriggeredStep(option.trigger, currentStep.value);
      delete currentStep.options;

      // replace choose option for user message
      currentStep = Object.assign(
        {},
        currentStep,
        option,
        defaultUserSettings,
        {
          user: true,
          hideInput: true,
          message: option.label,
          trigger
        }
      );

      renderedSteps.pop();
      previousSteps.pop();
      renderedSteps.push(currentStep);
      previousSteps.push(currentStep);

      this.setState({
        currentStep,
        renderedSteps,
        previousSteps
      });
    } else if (currentStep.trigger) {
      if (currentStep.replace) {
        renderedSteps.pop();
      }

      const trigger = this.getTriggeredStep(
        currentStep.trigger,
        currentStep.value
      );
      let nextStep = Object.assign({}, steps[trigger]);

      if (nextStep.message) {
        nextStep.message = this.getStepMessage(nextStep.message);
      } else if (nextStep.update) {
        const updateStep = nextStep;
        nextStep = Object.assign({}, steps[updateStep.update]);

        if (nextStep.options) {
          for (let i = 0, len = nextStep.options.length; i < len; i += 1) {
            nextStep.options[i].trigger = updateStep.trigger;
          }
        } else {
          nextStep.trigger = updateStep.trigger;
        }
      }

      nextStep.key = Random(24);

      previousStep = currentStep;
      currentStep = nextStep;

      this.setState({ renderedSteps, currentStep, previousStep }, () => {
        if (nextStep.user) {
          this.setState({ disabled: false }, () => {
            if (enableMobileAutoFocus || !isMobile()) {
              if (this.input) {
                this.input.focus();
              }
            }
          });
        } else {
          renderedSteps.push(nextStep);
          previousSteps.push(nextStep);

          this.setState({ renderedSteps, previousSteps });
        }
      });
      this.props.setPreviousSteps(previousSteps);
    }

    const { cache, cacheName } = this.props;
    if (cache) {
      setTimeout(() => {
        storage.setData(cacheName, {
          currentStep,
          previousStep,
          previousSteps,
          renderedSteps
        });
      }, 300);
    }
    this.props.setPreviousSteps(previousSteps);
  };

  handleEnd = () => {
    const { handleEnd } = this.props;

    if (handleEnd) {
      const { previousSteps, currentStep } = this.state;

      const renderedSteps = previousSteps.map(step => {
        const { id, message, value, metadata, type } = step;

        return {
          id,
          message,
          value,
          metadata,
          type
        };
      });

      const steps = [];

      for (let i = 0, len = previousSteps.length; i < len; i += 1) {
        const { id, message, value, metadata, type } = previousSteps[i];

        steps[id] = {
          id,
          message,
          value,
          metadata,
          type
        };
      }

      const values = previousSteps
        .filter(step => step.value)
        .map(step => step.value);

      const markAsComplete =
        currentStep?.markAsComplete || this.checkNodeType();
      handleEnd({ renderedSteps, steps, values }, markAsComplete);
    }
  };

  isInputValueEmpty = () => {
    const { inputValue } = this.state;
    return !inputValue || inputValue.length === 0;
  };

  isLastPosition = step => {
    const { renderedSteps } = this.state;
    const { length } = renderedSteps;
    const stepIndex = renderedSteps.map(s => s.key).indexOf(step.key);

    if (length <= 1 || stepIndex + 1 === length) {
      return true;
    }

    const nextStep = renderedSteps[stepIndex + 1];
    const hasMessage = nextStep.message || nextStep.asMessage;

    if (!hasMessage) {
      return true;
    }

    const isLast = step.user !== nextStep.user;
    return isLast;
  };

  isFirstPosition = step => {
    const { renderedSteps } = this.state;
    const stepIndex = renderedSteps.map(s => s.key).indexOf(step.key);

    if (stepIndex === 0) {
      return true;
    }

    const lastStep = renderedSteps[stepIndex - 1];
    const hasMessage = lastStep.message || lastStep.asMessage;

    if (!hasMessage) {
      return true;
    }

    const isFirst = step.user !== lastStep.user;
    return isFirst;
  };

  checkNodeType = () => {
    const { currentStep } = this.state;
    const fName = currentStep?.metadata?.name;
    const type = currentStep?.metadata?.type;
    const isEmail = fName === "email" || type === "email";
    const isPhone = fName === "phone" || type === "phone";
    const result = isEmail || isPhone;
    return result;
  };

  handleKeyPress = event => {
    if (event.key === "Enter") {
      const { currentStep } = this.state;
      const isInvalid = currentStep.validator && this.checkInvalidInput();
      if (isInvalid) return;
      this.submitUserMessage(isInvalid);
      if (this.checkNodeType()) this.onSubmitStep(true);
    }
  };

  onSubmitStep = (markAsComplete) => {
    if (this.state.currentStep.end) return;
    const renderedData = getRenderedSteps(this.state.previousSteps);
    this.props.handleSessionSubmit(renderedData, markAsComplete);
  };

  onFirstClick() {
    if (this.state.termsAccepted || this.props.isSessionStart) {
      return;
    }
    if(!this.props.isPreview) {
      window.sendTagEvent({ event: conf.CHAT_START_EVENT_NAME,eventProps:{category:conf.GTM_CATEGORY,action:conf.CHAT_START_EVENT_NAME,label:this.props.botName} });
    }
    this.setState({
      termsAccepted: true
    });
    const trackingId = this.props.trackingId;
    const baseUrl = this.props.baseUrl;
    if (trackingId && baseUrl && !this.props.isPreview) {
      try {
        const data = {
          type: this.props.type,
          activity: "START-SESSION",
          moduleId: this.props.moduleId,
          workspaceId: this.props.workspaceId,
          baseUrl: baseUrl
        };
        postActivity(data);
        this.props.onSessionStart(true);
        this.props.onSessionTime(new Date());
      } catch (err) {
        console.error("error sending start-session request:", err);
      }
    }
    this.setState({ isFirstClicked: true }, () => {
      [...this.state.renderedSteps].map( step => this.onPublishStep(step))
    });
  }

  showAllPreNotifications = () => {
    this.setState({clearTimeOutOfNotification : false});
    const { notification } = this.props.preNotification;
    const notificationList = notification.slice(notification.length - this.state.notificationCount)
    let notiCount = this.state.notificationCount;
    for (let i = 0; i < this.state.notificationCount; i++) {
      setTimeout(() => {
        if (!this.props.isPreview && !i && notification.length === Number(this.state.notificationCount)) this.setBotSession();
        this.state.notificationItems.push(notificationList[i])
        this.setState({notificationItems: [...this.state.notificationItems]})
        this.setState({ notificationCount: --notiCount });
        this.updateDimentions();
      }, i * 1000);
    }
  }

  setBotSession = () => {
    window.top.postMessage(
      {
        type: "set_bot_session",
        moduleId: this.props.moduleId,
        popup: !this.props.inPage,
        botData: { show: null, submitted: null }
      },
      "*"
    );
  }

  handleBotMessage = ev => {
    const showPopup = ev.data.canShow && this.props.moduleId === ev.data.id;
    if (ev.data.type === "set_session" && showPopup && this.props.floating) {
      this.setState({ canShow: ev.data.canShow });
      const { showMobileNotification } = this.props.appearance;
      const { notification, count, delay } = this.props.preNotification;
      if (notification?.length && (!isMobile() || (isMobile() && (showMobileNotification === undefined || showMobileNotification)))) {
        this.setState({ notificationCount: count });
        let notiCount = this.state.notificationCount;
        let countDelayTime = 0;

        for (let i = 0; i < this.state.notificationCount; i++) {
          countDelayTime += Number(notification[i].delay || delay || 30);
          const intervalId = setInterval(() => {
            const isGroweoSession = getGlobalItem('groweoSession');
            if (!isGroweoSession || !isMobile() || this.props.isPreview) {
              if (!this.state.clearTimeOutOfNotification) return;
              if (!this.props.isPreview && !i) this.setBotSession();
              this.state.notificationItems.push(notification[i])
              this.setState({notificationItems: [...this.state.notificationItems]})
              this.setState({ notificationCount: --notiCount });
              this.updateDimentions();
              clearInterval(intervalId);
            }
          }, countDelayTime * 1000);
        }
      }
    } 
  };

  handleSubmitButton = () => {
    const { speaking, recognitionEnable, currentStep } = this.state;

    if ((this.isInputValueEmpty() || speaking) && recognitionEnable) {
      this.recognition.speak();
      if (!speaking) {
        this.setState({ speaking: true });
      }
      return;
    }
    const isInvalid = currentStep.validator && this.checkInvalidInput();
    if (isInvalid) return;
    this.submitUserMessage(isInvalid);
    if (this.checkNodeType()) this.onSubmitStep(true);
  };

  submitUserMessage = isInvalid => {
    const {
      defaultUserSettings,
      inputValue,
      previousSteps,
      renderedSteps
    } = this.state;
    let { currentStep } = this.state;

    if (!isInvalid) {
      const step = {
        message: inputValue,
        hideInput: true,
        value: inputValue
      };
      this.onFirstClick();
      console.log('currentStep:', currentStep)
      if (currentStep.metadata.gtmEventName&&!this.props.isPreview) {
        window.sendTagEvent({
          event: currentStep.metadata.gtmEventName,
          payload: inputValue
        });
      }
      currentStep = Object.assign({}, defaultUserSettings, currentStep, step);

      renderedSteps.push(currentStep);
      previousSteps.push(currentStep);

      this.setState(
        {
          currentStep,
          renderedSteps,
          previousSteps,
          disabled: true,
          inputValue: ""
        },
        () => {
          if (this.input) {
            this.input.blur();
          }
        }
      );
      this.props.setPreviousSteps(previousSteps);
    }
  };

  checkInvalidInput = () => {
    const { enableMobileAutoFocus } = this.props;
    const { currentStep, inputValue } = this.state;
    const result = currentStep.validator(inputValue);
    const value = inputValue;

    if (typeof result !== "boolean" || !result) {
      this.setState(
        {
          inputValue: result.toString(),
          inputInvalid: true,
          disabled: true
        },
        () => {
          setTimeout(() => {
            this.setState(
              {
                inputValue: value,
                inputInvalid: false,
                disabled: false
              },
              () => {
                if (enableMobileAutoFocus || !isMobile()) {
                  if (this.input) {
                    this.input.focus();
                  }
                }
              }
            );
          }, 2000);
        }
      );

      return true;
    }

    return false;
  };

  toggleChatBot = opened => {
    const { toggleFloating } = this.props;
    if (window.top) {
      if (opened) {
        this.setState({
          notificationCount: 0,
          notificationItems: [],
          clearTimeOutOfNotification: false
        });
        window.top.postMessage(
          {
            type: "gw_iframe_open",
            moduleId: this.props.moduleId,
            popup: !this.props.inPage,
            height: this.props.height,
            width: this.props.width
          },
          "*"
        );
      } else {
        window.top.postMessage(
          {
            type: "gw_iframe_close",
            moduleId: this.props.moduleId,
            popup: !this.props.inPage
          },
          "*"
        );
      }
    }
    if (toggleFloating) {
      toggleFloating({ opened });
    } else {
      this.setState({ opened });
    }
    if (!this.props.isPreview) setGlobalItem("groweoSession", opened);
  };

  onPublishStep(data) {
    if (this.props.isPreview || !this.props.isSessionStart || this.state.publishedSteps.indexOf(data?.id) !== -1) return;
    const step = {
      id: data?.id,
    };
    const { trackingId, moduleId, workspaceId, baseUrl, type } = this.props;
    if (!workspaceId || !trackingId || !moduleId || !baseUrl) return;
    this.setState({ publishedSteps: [...this.state.publishedSteps, step.id] });
    Object.assign(step, {
      trackingId,
      moduleId,
      workspaceId,
      type
    })
    if (window.location.host === "editor.unlayer.com") return;
    let url = "https://2tn2gr0q94.execute-api.eu-north-1.amazonaws.com";
    if (baseUrl.search('localhost') === -1) url = baseUrl.split("/api")[0];
    try {
      axios.post(`${url}/collectSteps`, step);
    } catch (err) {
      console.error("error sending start-session request:", err);
    }
  }

  renderStep = (step, index) => {
    const { renderedSteps } = this.state;
    const {
      avatarStyle,
      bubbleStyle,
      bubbleOptionStyle,
      customStyle,
      hideBotAvatar,
      hideUserAvatar,
      speechSynthesis,
      headerTitle,
      botAvatar,
      smallAvatarColor,
      inPage,
      showTimestamp,
      fontSizeMultiplier,
      appearance,
    } = this.props;
    const { options, component, asMessage } = step;
    const steps = this.generateRenderedStepsById();
    const previousStep = index > 0 ? renderedSteps[index - 1] : {};
    if (!step.date) {
      step.date = new Date();
    }

    const isFirst = this.isFirstPosition(step);
    const isLast = this.isLastPosition(step);
    let hours = step.date.getHours().toString();
    if (hours.length === 1) {
      hours = `0${hours}`;
    }
    let minutes = step.date.getMinutes().toString();
    if (minutes.length === 1) {
      minutes = `0${minutes}`;
    }
    const isShowTimeStamp = (!isFirst && isLast) || (isFirst && isLast);

    if (component && !asMessage) {
      return (
        <>
          <CustomStep
            showTimestamp={showTimestamp}
            key={index}
            speak={this.speak}
            step={step}
            steps={steps}
            style={customStyle}
            previousStep={previousStep}
            previousValue={previousStep.value}
            triggerNextStep={this.triggerNextStep}
            fontSizeMultiplier={fontSizeMultiplier}
            onFirstClick={() => this.onFirstClick()}
          />
{/*           {isShowTimeStamp && showTimestamp && (
            <Timestamp isRight={!!step.user}>{`${hours}:${minutes}`}</Timestamp>
          )} */}
        </>
      );
    }

    if (options) {
      return (
        <OptionsStep
          key={index}
          showTimestamp={showTimestamp}
          step={step}
          previousValue={previousStep.value}
          triggerNextStep={this.triggerNextStep}
          bubbleOptionStyle={bubbleOptionStyle}
          fontSizeMultiplier={fontSizeMultiplier}
          optionLayout={appearance?.optionLayout}
          onClick={() => this.onFirstClick()}
        />
      );
    }

    return (
      <>
        <TextStep
          key={index}
          fontSizeMultiplier={fontSizeMultiplier}
          showTimestamp={showTimestamp}
          botAvatar={botAvatar}
          avatarColor={smallAvatarColor}
          headerTitle={headerTitle}
          step={step}
          steps={steps}
          speak={this.speak}
          previousStep={previousStep}
          previousValue={previousStep.value}
          triggerNextStep={this.triggerNextStep}
          avatarStyle={avatarStyle}
          bubbleStyle={bubbleStyle}
          hideBotAvatar={hideBotAvatar}
          hideUserAvatar={hideUserAvatar}
          speechSynthesis={speechSynthesis}
          isFirst={this.isFirstPosition(step)}
          isLast={this.isLastPosition(step)}
          timestamp={inPage}
        />
        {isShowTimeStamp && showTimestamp && (
          <Timestamp isRight={!!step.user}>{`${hours}:${minutes}`}</Timestamp>
        )}
      </>
    );
  };

  updateDimentions = () => {
    const el = document.getElementsByClassName('pre-notification')[0]
    window.top.postMessage(
      {
        type: "bot_pre_notification",
        moduleId: this.props.moduleId,
        popup: !this.props.inPage,
        dimentions: { height: el.offsetHeight, width: el.offsetWidth },
        notificationCount: this.state.notificationItems.length
      },
      "*"
    );
  };

  onBubbleClose = index => {
    this.state.notificationItems.splice(index, 1);
    this.setState({
      notificationItems: [...this.state.notificationItems]
    });
    this.updateDimentions();
  };

  render() {
    const {
      currentStep,
      disabled,
      inputInvalid,
      inputValue,
      opened,
      renderedSteps,
      speaking,
      recognitionEnable,
      notificationItems,
      notificationCount
    } = this.state;
    const {
      className,
      contentStyle,
      extraControl,
      floating,
      floatingIcon,
      iconBgType,
      floatingStyle,
      footerStyle,
      headerComponent,
      headerTitle,
      hideHeader,
      hideSubmitButton,
      inputStyle,
      placeholder,
      inputAttributes,
      recognitionPlaceholder,
      style,
      submitButtonStyle,
      width,
      height,
      closeButton,
      avatarColor,
      footerComponent,
      smallAvatarSize,
      smallAvatarTextColor,
      smallAvatarColor,
      smallBotAvatar,
      smallAvatartitle,
      hideBotAvatar,
      inPage,
      restart,
      botAvatar,
      fontSizeMultiplier,
      moduleId,
      delay,
      mode,
      appearance
    } = this.props;

    const header = headerComponent || (
      <>
        <Header className="rsc-header">
          <div style={{ display: "flex", alignItems: "center" }}>
            <BotHeaderIcon
              width="48px"
              height="48px"
              avatarColor={appearance?.headerFontColor}
              src={botAvatar}
            />
            <HeaderTitle
              fontSizeMultiplier={fontSizeMultiplier}
              className="rsc-header-title"
            >
              {headerTitle}
            </HeaderTitle>
          </div>
          <div style={{ display: "flex" }}>
            {restart ? (
              <HeaderCloseIcon
                className="rsc-restart-icon"
                onClick={() => this.onRestartClicked()}
              >
                <RestartIcon />
              </HeaderCloseIcon>
            ) : (
              ""
            )}
            {(floating || closeButton) && (
              <HeaderCloseIcon
                className="rsc-header-close-button"
                onClick={() => {
                  if (delay && !inPage && !this.props.isPreview) {
                    window.top.postMessage(
                      {
                        type: "set_bot_session",
                        moduleId,
                        popup: !inPage,
                        botData: { show: +new Date() }
                      },
                      "*"
                    );
                  }
                  this.toggleChatBot(false);
                }}
              >
                <CloseIcon color={appearance?.headerFontColor}/>
              </HeaderCloseIcon>
            )}
          </div>
        </Header>
        {/* <div style={{ height: "1px", background: "#dbdbdb" }}></div> */}
      </>
    );

    let customControl;
    if (extraControl !== undefined) {
      customControl = React.cloneElement(extraControl, {
        disabled,
        speaking,
        invalid: inputInvalid
      });
    }

    const icon =
      (this.isInputValueEmpty() || speaking) && recognitionEnable ? (
        <MicIcon />
      ) : (
        <SubmitIcon />
      );

    const inputPlaceholder = speaking
      ? recognitionPlaceholder
      : currentStep.placeholder || placeholder;

    const inputAttributesOverride =
      currentStep.inputAttributes || inputAttributes;

    const { hideInput } = currentStep;

    const controlStyle = Object.assign({}, this.props.controlStyle);
    controlStyle.position = "relative";
    controlStyle.right = 0;
    controlStyle.top = 0;
    // let policyText = `<span style="color:gray">Lähettämällä lomakkeen hyväksyn <a href="https://www.groweo.com/kayttoehdot/" target="_blank" style="text-decoration:underline;">Groweon</a> käyttöehdot</span>`;

    return (
      <>
        {notificationItems.length ? <div className="pre-notification" style={{ flexDirection: "column"}}>
          {notificationItems.map((item, i) => (
            <Bubble
              className="rsc-ts-bubble"
              // style={{transitionDelay: (notificationItems.length - i - 1) + "s"}}
              user={mode === "dark"}
              key={`bubble_${i}`}
              preNotification
            >
              <span className="btn-close" onClick={()=> this.onBubbleClose(i)}>
                <CloseIcon color={appearance?.chatNotificationTextColor} height={18} width={18} />
              </span>
              <div
                className="bubble-container"
                onClick={() => this.toggleChatBot(true)}
              >
                {this.props.preNotification?.title && (<div className="bubble-header">
                  <div className="bubble-title">{this.props.preNotification?.title}</div>
                </div>)}
                <p className="text" dangerouslySetInnerHTML= {{__html: item.text}}></p>
              </div>
            </Bubble>
          ))}
        </div> : ''}
        <div className={`rsc ${className}`}>
          {floating && (
            <>
              {notificationCount ? <span
                  className="symbol-badge"
                  onClick={() => this.state.clearTimeOutOfNotification && this.showAllPreNotifications()}
                >{notificationCount}</span> : ''
              }
              <FloatButton
                className="rsc-float-button"
                style={floatingStyle}
                opened={opened}
                onClick={() => this.toggleChatBot(true)}
              >
                {iconBgType !== "image" &&
                  (typeof floatingIcon === "string" ? (
                  <FloatingIcon src={floatingIcon} />
                ) : (
                  floatingIcon
                ))}
              </FloatButton>
            </>
          )}
          <ChatBotContainer
            className="rsc-container"
            floating={floating}
            floatingStyle={floatingStyle}
            inPage={inPage}
            opened={opened}
            onChange={ev => {
              console.log("onChange ev:", ev);
            }}
            ref={ref => {
              if (!this.state.resizeObserver) {
                const observer = new ResizeObserver(entries => {
                  if (window.top && this.props.moduleId) {
                    window.top.postMessage(
                      {
                        type: "resize",
                        height: ref.offsetHeight,
                        moduleId: this.props.moduleId,
                        popup: !this.props.inPage
                      },
                      "*"
                    );
                  }
                });
                if(ref) {
                  observer.observe(ref);
                  this.setState({
                    resizeObserver: observer
                  });
              }
              }
              // if (ref) {
              //   console.log('chatbot offset height: ', ref.offsetHeight)
              //   if (window.top) {
              //     window.top.postMessage({
              //       type: "resize",
              //       height: ref.offsetHeight
              //     });
              //   }
              // }
            }}
            style={style}
            width={width}
            height={height}
            isIframe={this.props.isIframe}
          >
            {!hideHeader && header}
            <Content
              className="rsc-content"
              ref={this.setContentRef}
              floating={floating}
              style={contentStyle}
              height={height}
              hideInput={hideInput}
            >
              {renderedSteps.map((step, index) => {
                const showSmallAvatar =
                  !hideBotAvatar && !step?.user && this.isFirstPosition(step) && step?.component?.props.currentElement.type !== "carousel";
                return showSmallAvatar ? (
                  <div style={{ display: "flex" }}>
                    <ImageContainer
                      className="rsc-ts-image-container"
                      user={step.user}
                    >
                      <div
                        className="rsc-ts-small-avatar-wrapper"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <BotHeaderIcon
                          src={smallBotAvatar}
                          avatarColor={smallAvatarColor}
                          textColor={smallAvatarTextColor}
                          size={smallAvatarSize}
                        />
                      </div>
                    </ImageContainer>
                    <div style={{ width: "100%" }}>
                      <div
                        style={{
                          marginLeft: 4,
                          color: smallAvatarTextColor || "black"
                        }}
                      >
                        {smallAvatartitle}
                      </div>
                      {this.renderStep(step, index)}
                    </div>
                  </div>
                ) : (
                  <div
                    style={
                      !hideBotAvatar &&
                      !step?.user &&
                      !this.isFirstPosition(step) &&
                      step?.component?.props.currentElement.type !== "carousel"
                        ? {
                            marginLeft: smallAvatarSize
                              ? `${Number(smallAvatarSize) + 4}px`
                              : "52px"
                          }
                        : {}
                    }
                  >
                    {this.renderStep(step, index)}
                  </div>
                );
              })}
              {!hideInput && !this.props.isSession ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    margin: "10px 0px 10px 0px",
                    position: "relative"
                  }}
                >
                  <Input
                    type="textarea"
                    style={inputStyle}
                    ref={this.setInputRef}
                    className="rsc-input"
                    placeholder={inputInvalid ? "" : inputPlaceholder}
                    onKeyPress={this.handleKeyPress}
                    onChange={this.onValueChange}
                    value={inputValue}
                    floating={floating}
                    invalid={inputInvalid}
                    disabled={disabled}
                    hasButton={!hideSubmitButton}
                    {...inputAttributesOverride}
                  />
                  <div style={controlStyle} className="rsc-controls">
                    {!hideInput && !currentStep.hideExtraControl && customControl}
                    {!hideInput && !hideSubmitButton && (
                      <SubmitButton
                        className="rsc-submit-button"
                        style={submitButtonStyle}
                        onClick={this.handleSubmitButton}
                        invalid={inputInvalid}
                        disabled={disabled}
                        speaking={speaking}
                      >
                        {icon}
                      </SubmitButton>
                    )}
                  </div>
                </div>
              ) : (
                <></>
              )}
            </Content>
            {inPage && !this.state.termsAccepted ? (
              <div
                className="rsc-legal"
                style={{
                  backgroundColor: this.props.inPage ? 'transparent' : "#cccccc",
                  display: "flex",
                  justifyContent: "space-between",
                  alignContent: "center",
                  padding: "0 24px"
                }}
              >
                {!this.state.termsAccepted ? (
                  <a
                    href="https://www.groweo.com/kayttoehdot/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <PolicyTextIcon />
                  </a>
                ) : (
                  <></>
                )}
                <GroweoIcon />
              </div>
            ) : (
              ""
            )}
            <Footer className="rsc-footer" style={{ ...footerStyle, margin: 0 }}>
              {/* <TrademarkContainer className="rsc-trademark-container">
                <TrademarkFooter className="rsc-trademark-footer">
                  <TrademarkIcon />
                </TrademarkFooter>
              </TrademarkContainer> */}
              {!inPage ? (
                <div
                  style={{
                    backgroundColor: "#cccccc",
                    height: "30px",
                    display: "flex",
                    justifyContent: !this.state.termsAccepted ? "space-between" : "flex-end",
                    alignContent: "center",
                    padding: "0 24px"
                  }}
                >
                  {!this.state.termsAccepted ? (
                    <a
                      href="https://www.groweo.com/kayttoehdot/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <PolicyTextIcon />
                    </a>
                  ) : (
                    <></>
                  )}
                  <GroweoIcon />
                </div>
              ) : (
                ""
              )}
            </Footer>
          </ChatBotContainer>
        </div>
      </>
    );
  }
}

ChatBot.propTypes = {
  avatarStyle: PropTypes.objectOf(PropTypes.any),
  botAvatar: PropTypes.string,
  isPreview: PropTypes.bool,
  botName: PropTypes.string,
  botDelay: PropTypes.number,
  bubbleOptionStyle: PropTypes.objectOf(PropTypes.any),
  bubbleStyle: PropTypes.objectOf(PropTypes.any),
  cache: PropTypes.bool,
  cacheName: PropTypes.string,
  className: PropTypes.string,
  contentStyle: PropTypes.objectOf(PropTypes.any),
  customDelay: PropTypes.number,
  customStyle: PropTypes.objectOf(PropTypes.any),
  controlStyle: PropTypes.objectOf(PropTypes.any),
  enableMobileAutoFocus: PropTypes.bool,
  enableSmoothScroll: PropTypes.bool,
  extraControl: PropTypes.objectOf(PropTypes.element),
  floating: PropTypes.bool,
  floatingIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  floatingStyle: PropTypes.objectOf(PropTypes.any),
  footerStyle: PropTypes.objectOf(PropTypes.any),
  handleEnd: PropTypes.func,
  headerComponent: PropTypes.element,
  headerTitle: PropTypes.string,
  height: PropTypes.string,
  hideBotAvatar: PropTypes.bool,
  hideHeader: PropTypes.bool,
  hideSubmitButton: PropTypes.bool,
  hideUserAvatar: PropTypes.bool,
  inputAttributes: PropTypes.objectOf(PropTypes.any),
  inputStyle: PropTypes.objectOf(PropTypes.any),
  opened: PropTypes.bool,
  toggleFloating: PropTypes.func,
  placeholder: PropTypes.string,
  recognitionEnable: PropTypes.bool,
  recognitionLang: PropTypes.string,
  recognitionPlaceholder: PropTypes.string,
  speechSynthesis: PropTypes.shape({
    enable: PropTypes.bool,
    lang: PropTypes.string,
    voice:
      typeof window !== "undefined"
        ? PropTypes.instanceOf(window.SpeechSynthesisVoice)
        : PropTypes.any
  }),
  steps: PropTypes.arrayOf(PropTypes.object).isRequired,
  style: PropTypes.objectOf(PropTypes.any),
  submitButtonStyle: PropTypes.objectOf(PropTypes.any),
  userAvatar: PropTypes.string,
  userDelay: PropTypes.number,
  width: PropTypes.string
};

ChatBot.defaultProps = {
  avatarStyle: {},
  botDelay: 1000,
  botName: "",
  isPreview:false,
  bubbleOptionStyle: {},
  bubbleStyle: {},
  cache: false,
  cacheName: "rsc_cache",
  className: "",
  contentStyle: {},
  customStyle: {},
  controlStyle: { position: "absolute", right: "0", top: "0" },
  customDelay: 1000,
  enableMobileAutoFocus: false,
  enableSmoothScroll: false,
  extraControl: undefined,
  floating: false,
  floatingIcon: <ChatIcon />,
  floatingStyle: {},
  footerStyle: {},
  handleEnd: undefined,
  headerComponent: undefined,
  headerTitle: "Chat",
  height: "442px",
  hideBotAvatar: false,
  hideHeader: false,
  hideSubmitButton: false,
  hideUserAvatar: false,
  inputStyle: {},
  opened: undefined,
  placeholder: "Kirjoita tänne ...",
  inputAttributes: {},
  recognitionEnable: false,
  recognitionLang: "en",
  recognitionPlaceholder: "Listening ...",
  speechSynthesis: {
    enable: false,
    lang: "en",
    voice: null
  },
  style: {},
  submitButtonStyle: {},
  toggleFloating: undefined,
  userDelay: 1000,
  width: "340px",
  botAvatar: undefined,
  //"data:image/svg+xml,%3csvg version='1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3e%3cpath d='M303 70a47 47 0 1 0-70 40v84h46v-84c14-8 24-23 24-40z' fill='%2393c7ef'/%3e%3cpath d='M256 23v171h23v-84a47 47 0 0 0-23-87z' fill='%235a8bb0'/%3e%3cpath fill='%2393c7ef' d='M0 240h248v124H0z'/%3e%3cpath fill='%235a8bb0' d='M264 240h248v124H264z'/%3e%3cpath fill='%2393c7ef' d='M186 365h140v124H186z'/%3e%3cpath fill='%235a8bb0' d='M256 365h70v124h-70z'/%3e%3cpath fill='%23cce9f9' d='M47 163h419v279H47z'/%3e%3cpath fill='%2393c7ef' d='M256 163h209v279H256z'/%3e%3cpath d='M194 272a31 31 0 0 1-62 0c0-18 14-32 31-32s31 14 31 32z' fill='%233c5d76'/%3e%3cpath d='M380 272a31 31 0 0 1-62 0c0-18 14-32 31-32s31 14 31 32z' fill='%231e2e3b'/%3e%3cpath d='M186 349a70 70 0 1 0 140 0H186z' fill='%233c5d76'/%3e%3cpath d='M256 349v70c39 0 70-31 70-70h-70z' fill='%231e2e3b'/%3e%3c/svg%3e",
  userAvatar:
    "data:image/svg+xml,%3csvg viewBox='-208.5 21 100 100' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3e%3ccircle cx='-158.5' cy='71' fill='%23F5EEE5' r='50'/%3e%3cdefs%3e%3ccircle cx='-158.5' cy='71' id='a' r='50'/%3e%3c/defs%3e%3cclipPath id='b'%3e%3cuse overflow='visible' xlink:href='%23a'/%3e%3c/clipPath%3e%3cpath clip-path='url(%23b)' d='M-108.5 121v-14s-21.2-4.9-28-6.7c-2.5-.7-7-3.3-7-12V82h-30v6.3c0 8.7-4.5 11.3-7 12-6.8 1.9-28.1 7.3-28.1 6.7v14h100.1z' fill='%23E6C19C'/%3e%3cg clip-path='url(%23b)'%3e%3cdefs%3e%3cpath d='M-108.5 121v-14s-21.2-4.9-28-6.7c-2.5-.7-7-3.3-7-12V82h-30v6.3c0 8.7-4.5 11.3-7 12-6.8 1.9-28.1 7.3-28.1 6.7v14h100.1z' id='c'/%3e%3c/defs%3e%3cclipPath id='d'%3e%3cuse overflow='visible' xlink:href='%23c'/%3e%3c/clipPath%3e%3cpath clip-path='url(%23d)' d='M-158.5 100.1c12.7 0 23-18.6 23-34.4 0-16.2-10.3-24.7-23-24.7s-23 8.5-23 24.7c0 15.8 10.3 34.4 23 34.4z' fill='%23D4B08C'/%3e%3c/g%3e%3cpath d='M-158.5 96c12.7 0 23-16.3 23-31 0-15.1-10.3-23-23-23s-23 7.9-23 23c0 14.7 10.3 31 23 31z' fill='%23F2CEA5'/%3e%3c/svg%3e"
};

export default ChatBot;
