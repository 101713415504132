(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("styled-components"));
	else if(typeof define === 'function' && define.amd)
		define(["react", "styled-components"], factory);
	else if(typeof exports === 'object')
		exports["GroweoSmartbar"] = factory(require("react"), require("styled-components"));
	else
		root["GroweoSmartbar"] = factory(root["react"], root["styled-components"]);
})(typeof self !== 'undefined' ? self : this, (__WEBPACK_EXTERNAL_MODULE__155__, __WEBPACK_EXTERNAL_MODULE__313__) => {
return 