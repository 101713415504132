import React, { useState } from "react";
import Card from "./Card";
import CardImage from "./CardImage";
import CardBody from "./CardBody";
import CardTitle from "./CardTitle";
import CardText from "./CardText";
import CardLinkContainer from "./CardLinkContainer";
import CardLink from "./CardLink";
import { isMobile } from "../utils";

class CardComponent extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      mouseIn: false
    };
  }

  componentDidMount() {
    // If markAsComplete is true then publish session
    if (this.props.currentElement.data?.markAsComplete) {
      console.log("Mark scussessfull conversation");
      this.props.onLinkClick(this.props?.step?.key, this.props.currentElement.data?.markAsComplete);
    }
    this.onPublishStep();
  }

  // const [mouseIn, setMouseIn] = useState(false);

  // const mouseIn = true;

  onPublishStep(link) {
    if (!this.props.step || this.props.isPreview) return
    const { data } = this.props.currentElement;
    const name = data.name || data.text;
    this.props.step.name = name;
    data.name = name;
    this.props.step.value = link && this.props.currentElement.type === 'linkk' ? `${link} is clicked` : data?.imageSource;
    this.props.step.metadata = data;
    this.props.step.type = 'card'
  }

  onLinkClick = link => {
    if (!this.props.step || this.props.isPreview) {
      this.props.onLinkClick(link, true);
      return;
    }
    const { data } = this.props.currentElement;
    const name = data.name || data.text;
    this.props.step.name = name;
    data.name = name;
    this.props.step.metadata = data;
    this.props.step.value = link;
    if(!this.props.step.component) return;
    this.props.onFirstClick();
    this.props.onLinkClick(this.props?.step?.key, true)
    this.props.onTrackClick({
      id: `${this.props?.step?.id}-click-${link}`,
      link,
    })
  };

  render() {
    const {
      currentElement,
      index,
      onRightClick,
      onLeftClick,
      showLeftButton,
      showRightButton,
      fontSizeMultiplier,
      cardWidth,
      cardSpacing
    } = this.props;

    const { mouseIn } = this.state;

    const cw = cardWidth || currentElement.data.cardWidth
    const actualCardWidth = Number(cw) ? cw+'px' : cw

    const formatLinkText = (linkText) => {
      if (linkText.match(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)) {
        return 'mailto:' + linkText;
      } else if (linkText.match(/[+\-0-9]+/) && linkText.match(/[+\-0-9]+/)[0] === linkText) {
        return 'tel:' + linkText;
      } else {
        return linkText;
      }
    }


    return (
      <Card
        className="rsc-card-container"
        cardWidth={isMobile() ? `100%` : actualCardWidth}
        cardSpacing={cardSpacing}
        index={index}
        onMouseLeave={() => {
          this.setState(() => ({ mouseIn: false }));
        }}
        onMouseEnter={() => {
          this.setState(() => ({ mouseIn: true }));
        }}
      >
        {currentElement.type === "card" && currentElement.data.imageSource && (
          <CardImage
            src={currentElement.data.imageSource}
            className="rsc-card-img-top"
            alt="Card"
          />
        )}
        {/* {onLeftClick ? (
          <svg
            style={{
              position: "absolute",
              width: "48px",
              height: "48px",
              left: "-10%",
              top: "calc(50% - 24px)",
              cursor: "pointer",
              visibility: showLeftButton() ? "visible" : "hidden"
            }}
            onClick={onLeftClick}
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="rgba(0,0,0,0.20)"
            className="bi bi-chevron-left"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
            />
          </svg>
        ) : (
          ""
        )}
        {onRightClick ? (
          <svg
            style={{
              position: "absolute",
              width: "48px",
              height: "48px",
              right: "-10%",
              top: "calc(50% - 24px)",
              cursor: "pointer",
              visibility: showRightButton() ? "visible" : "hidden"
            }}
            onClick={onRightClick}
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="rgba(0,0,0,0.25)"
            className="bi bi-chevron-right"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
            />
          </svg>
        ) : (
          ""
        )} */}

        <CardBody className="rsc-card-body">
          {currentElement.data.cardTitle && <CardTitle  fontSizeMultiplier={fontSizeMultiplier} className="rsc-card-title">
            {currentElement.data.cardTitle}
          </CardTitle>}
          {currentElement.data.text && ["card", "linkk"].includes(currentElement.type) && (
            <CardText fontSizeMultiplier={fontSizeMultiplier}  className="rsc-card-text">
              <span dangerouslySetInnerHTML= {{__html: currentElement.data.text || ''}}></span>
            </CardText>
          )}
          {currentElement.data.links?.length ? (
            <CardLinkContainer className="rsc-card-link-container">
              {currentElement.data.links.map(link => (
                <CardLink
                  target="_blank"
                  href={formatLinkText(link.destination)}
                  className="rsc-card-link"
                  fontSizeMultiplier={fontSizeMultiplier}
                  onClick={() => this.onLinkClick(link.destination)}
                >
                  {link.text}
                </CardLink>
              ))}
            </CardLinkContainer>
          ) : ""}
        </CardBody>
      </Card>
    );
  }
}


export default CardComponent;
