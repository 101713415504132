import styled from "styled-components";
import defaultTheme from "../theme";

const SliderBuilderContainer = styled.div`
  word-wrap: break-word;
  width: ${({ theme }) => `1200px`};
  font-family: ${({ theme }) => theme.fontFamily};
  flex-direction: column;
  box-sizing: border-box;
  position: relative;
  ${({ isEditor }) => !isEditor ? `
    animation: fadeIn 3s;
    @keyframes fadeIn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  ` : '' }
`;

SliderBuilderContainer.defaultProps = {
  theme: defaultTheme
};

export default SliderBuilderContainer;
