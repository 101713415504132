import styled from 'styled-components';
import { scale } from '../../common/animations';
import defaultTheme from '../../theme';

const Bubble = styled.div`
  animation: ${scale} 0.3s ease forwards;
  background: ${props => (props.preNotification ? props.theme.chatNotificationBgColor : props.user ? props.theme.userBubbleColor : props.theme.botBubbleColor)};
  border-radius: ${props => {
    const { isFirst, isLast, user } = props;

    if (!isFirst && !isLast) {
      return user ? '8px 8px 8px 8px' : '8px 8px 8px 8px';
    }

    if (!isFirst && isLast) {
      return user ? '8px 0 8px 8px' : '0 8px 8px 8px';
    }

    return props.user ? '8px 8px 0 8px' : '8px 8px 8px 0';
  }};
  color: ${props => (props.preNotification ? props.theme.chatNotificationTextColor : props.user ? props.theme.userFontColor : props.theme.botFontColor)};
  display: inline-block;
 
  margin: ${props => {
    const { isFirst, showAvatar, user, preNotification } = props;
    if (preNotification) {
      return '6px';
    }

    if (!isFirst && showAvatar) {
      return user ? '-8px 46px 10px 0' : '2px';
    }

    if (!isFirst && !showAvatar) {
      return user ? '-8px 0px 10px 0' : '2px';
    }

    return user ? '4px' : '2px';
  }};
  overflow: hidden;
  position: relative;
  padding: 12px;
  transform: scale(0);
  transform-origin: ${props => {
    const { isFirst, user } = props;
    if (isFirst) {
      return user ? 'bottom right' : 'bottom left';
    }
    return user ? 'top right' : 'top left';
  }};
  box-shadow: ${props => (props.preNotification ? 'rgb(128 128 128 / 20%) 0px 0px 7px 0px' : 'none')};
  fill: ${props => (props.preNotification ? (props.user ? props.theme.userFontColor : props.theme.botFontColor) : '')};
  font-weight: ${props => { const { isFirst, user } = props; return user ? 100 : 400}};
  font-size: ${({ fontSizeMultiplier }) => fontSizeMultiplier*14 + 'px' };
  line-height: ${({ fontSizeMultiplier }) => fontSizeMultiplier*24 + 'px' };
`;

/*
 max-width: 75%;
 */
Bubble.defaultProps = {
  theme: defaultTheme
};

export default Bubble;
