import styled from "styled-components";
import defaultTheme from "../theme";

const WrapText = styled.div`
  padding: ${({ padding }) => padding};
`;

WrapText.defaultProps = {
  theme: defaultTheme
};

export default WrapText;
