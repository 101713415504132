import React from "react";

const LeftArrow = () => (
  <svg
    width="24"
    height="24"
    viewBox="1 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
   <path
      d="M17.6 4L9.6 12L17.6 20H13.6L6.3 12.7C5.9 12.3 5.9 11.7 6.3 11.3L13.6 4H17.6Z"
      fill="inherit"
    />
  </svg>
);

export default LeftArrow;
