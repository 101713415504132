import styled from "styled-components";

const Button = styled.button`
  display: inline-block;
  outline: 0px;
  border: 0px;
  cursor: pointer;
  border-radius: 8px;
  margin: 0 35px;
  padding: 14px 24px 16px;
  font-weight: 600;
  line-height: 1;
  letter-spacing: 0.1em;
  vertical-align: baseline;
  text-shadow: none;
  opacity: 100%;
  &:hover {
    opacity: 80%;
  }
`;

export default Button;
