import React, { Component } from "react";
import PropTypes from "prop-types";
import { ThemeProvider } from "styled-components";
import { CloseIcon, GroweoIcon, PolicyTextIcon } from "../icons";
import SuccessMessageComponent from "./SuccessMessageComponent";
import { getSmartBarTheme, isMobile, setGlobalItem } from "../utils";
import {
	BarContent,
	BarTitle,
	Card,
	CardText,
	CardTitle,
	CloseButton,
	Footer,
	SmartBarContainer,
} from "../custom_components";
import FormComponent from "./FormComponent";
import "../css/Smartbar.css";
import conf from "../conf/conf";
import BarButton from "../custom_components/BarButton";
import BarIcon from "../custom_components/BarIcon";
import BarDefaultIcon from "../custom_components/BarDefaultIcon";
import SmartBarMobileContainer from "../custom_components/SmartBarMobileContainer"

class SmartBar extends Component {
	constructor(props) {
		super(props);
		this.state = {
			opened: false,
			isFormSubmit: false,
			barHeight: "auto",
			contentHeight: "0px",
			barWidth: 0,
			isSessionStart: false,
			startSessionTime: '',
			showBarIcon: true,
		};
	}

	sendPostMessage = (data) => {
		const id = `${this.props?.moduleId}-${this.props?.workspaceId}-smartbar`;
		window.top && window.top.postMessage({ id, ...data }, "*");
	};

	setIsFormSubmit = (isFormSubmit) => {
		this.setState({
			isFormSubmit,
		});
	};
	setOpened = (isOpened) => {
		this.setState({
			opened: isOpened,
		});
		if (isOpened) {
			this.setState({
				barHeight: this.props.appearance.height,
				contentHeight: "auto",
				showBarIcon: true,
			});
			this.sendPostMessage({
				type: "gsb_opened",
				moduleId: this.props.moduleId,
			});
			this.sendPostMessage({
				width: this.props?.appearance?.width,
				height: this.props?.appearance?.height,
				type: "smartbar-iframe-size",
			});
			this.createActivity('START-SESSION', {});
		} else {
			setTimeout(() => {
				this.setState({
					barHeight: "auto",
					contentHeight: "0px",
					showBarIcon: false,
				});
				const el = document.querySelector(".gsb-bar-content");
				const barWidth = this.props?.appearance?.barTitleFontSize * 40;
				if (el) {
					if((isMobile() && this.props?.appearance?.iconBgType !== "bar") && this.props?.appearance?.SwitchToMobileMode) {
						this.sendPostMessage({
							type: "gsb_closed",
							moduleId: this.props.moduleId,
						});
					} else {
						el.style.height = 'auto';
						this.sendPostMessage({
							type: "gsb_closed",
							moduleId: this.props.moduleId,
						});
						this.sendPostMessage({
							width: barWidth + "px",
							height: el.offsetHeight + "px",
							type: "smartbar-iframe-size",
						});
					}
					this.setState({ barWidth: barWidth });
				}
			}, 600);
		}
		if(this.props?.isPreview === false) setGlobalItem("groweoSession", isOpened);
	};

	postActivity = (activity, objData) => {
		const activityData = {
			type: 'smartbar-activity',
			activityObj: {
				type: this.props?.response?.data?.type,
				workspaceId: this.props.workspaceId,
				activity,
				moduleId: this.props.moduleId,
				...objData,
			},
			baseUrl: this.props.url,
		};
		this.sendPostMessage(activityData);
	};

	createActivity(activity, objData) {
		if (this.props?.isPreview) return;
		if (activity === "START-SESSION" && this.state.isSessionStart) return;
		if (activity === "START-SESSION") {
			this.setState({
				isSessionStart: true,
				startSessionTime: new Date(),
			});
		}
		try {
			const groweoAppName = this.props?.response?.data?.name;
			if (activity === 'START-SESSION')
				this.pushGtmData({
					event: conf.BAR_START_EVENT_NAME,
					eventProps: {
						category: conf.GTM_CATEGORY,
						action: conf.BAR_START_EVENT_NAME,
						label: groweoAppName
					},
				});
			else if (activity === 'END-SESSION')
				this.pushGtmData({
					event: conf.BAR_END_EVENT_NAME,
					eventProps: {
						category:conf.GTM_CATEGORY,
						action:conf.BAR_END_EVENT_NAME,
						label:groweoAppName
					},
				});
			this.postActivity(activity, objData);
		} catch (err) {
			console.error("error sending end or success activity request:", err);
		}
	}

	pushGtmData = (eventData) => {
		this.sendPostMessage({
			type: "smartbar-tagEvent",
			eventData
		})
	}
	
	componentDidMount() {
		if(this.props.isSession) {
			this.setOpened(true)
			const el = document.querySelector(".gsb-bar-content");
			if (el) el.style.height = 'auto';
		} else {
			this.setOpened(false)
		}
	}

	componentDidUpdate() {
		const { activeStep, appearance } = this.props;
		const { opened } = this.state;
		if (this.props.isPreview) {
			if (activeStep === 2) {
				if (opened) {
					this.setOpened(false);
				}
				if (this.state.isFormSubmit) {
					this.setIsFormSubmit(false);
				}
			}
			if ([1, 3, 4, 5].includes(activeStep)) {
				if (!opened) {
					this.setOpened(true);
				}
				if (this.state.isFormSubmit) {
					this.setIsFormSubmit(false);
				}
			}
			if (activeStep === 6) {
				if (!opened) {
					this.setOpened(true);
				}
				if (!this.state.isFormSubmit) {
					this.setIsFormSubmit(true);
				}
			}
			if (opened &&  appearance?.height) {
				if (this.state.barHeight !== appearance.height)
					this.setState({
						barHeight: appearance.height,
						contentHeight: "auto",
					});
			}
			if (appearance.barTitleFontSize && this.state.barWidth) {
				const barWidth = appearance?.barTitleFontSize * 40;
				if (barWidth !== this.state.barWidth) {
					this.setState({ barWidth });
				}
			}
		}
	}

	render() {
		const { opened, barHeight, contentHeight, barWidth, showBarIcon } = this.state;
		const { appearance, isSession, floatingIcon } = this.props;
		const props = Object.assign({}, this.props);
		const mobileSwitchTheme = getSmartBarTheme(appearance, this.props.activeStep);
		const onFormSubmit = (formData) => {
			if (props?.isPreview) return;
			const duration = Math.round(
				Math.abs(new Date() - this.state.startSessionTime) / 1000
			);
			const responseData = {
				type: "send-response-smartbar",
				publishUrl: this.props.publishUrl,
				language: this.props.language,
				objData: {
					trackingId: this.props.moduleId,
					workspaceId: this.props.workspaceId,
					moduleId: this.props.moduleId,
					data: {
						...formData,
						for_notification: { ...formData },
					},
				},
			};
			this.sendPostMessage(responseData);
			this.createActivity("SUCCESSFULL-CONVERSATION", {});
			this.createActivity("END-SESSION", { duration });
			if (formData.email || formData.phone) {
				const groweoAppName = this.props?.response?.data?.name;
				this.pushGtmData({
					event: conf.BAR_LEAD_EVENT_NAME,
					eventProps: {
						category: conf.GTM_CATEGORY,
						action: conf.BAR_LEAD_EVENT_NAME,
						label:groweoAppName,
					},
				});
			}
		};

		const onFormBtnSubmit = (data)  => {
			if (props?.isPreview) return;
			const duration = Math.round(
				Math.abs(new Date() - this.state.startSessionTime) / 1000
			);
			const responseData = {
				type: "send-response-smartbar",
				publishUrl: this.props.publishUrl,
				language: this.props.language,
				objData: {
					trackingId: this.props.moduleId,
					workspaceId: this.props.workspaceId,
					moduleId: this.props.moduleId,
					data: {
						...data,
						for_notification: { ...data },
					},
				},
			};
			this.sendPostMessage(responseData);
			this.createActivity("SUCCESSFULL-CONVERSATION", {});
			this.createActivity("END-SESSION", { duration });
		}

		return (
			<>
				{isMobile() && !mobileSwitchTheme.SwitchToMobileMode ? '' : isMobile() && mobileSwitchTheme.SwitchToMobileMode && mobileSwitchTheme.iconBgType !== 'bar' ?
					(
						<ThemeProvider
							theme={getSmartBarTheme(appearance, this.props.activeStep)}
						>
							<div className="gsb">
								<BarButton
									className="gsb-mobile-icon"
									opened={showBarIcon}
									onClick={() => this.setOpened(!opened)}
								>
									{appearance?.iconBgType !== "image" &&
										(typeof floatingIcon === "string" ? (
										<BarIcon
										src={floatingIcon}
										/>
										) : (
										floatingIcon
									))}
								</BarButton>
								<SmartBarMobileContainer
									className="gsb-mobile-container"
									opened={opened}
									height={barHeight}
									isSession={isSession}
									barWidth={barWidth}
								>
									{ mobileSwitchTheme?.showCloseButton && (
										<CloseButton
											className="gsb-close-button"
											barWidth={barWidth}
											onClick={() => {
												this.setOpened(!opened);
											}}
											disabled={isSession}
										>
											<CloseIcon color={appearance?.closeIconColor} />
										</CloseButton>
									) }
									<BarContent
									className="gsb-bar-content"
									opened={opened}
									onClick={() => {
										if ([1, 2, 3, 4, 5, 6].includes(this.props.activeStep) || isSession) return;
										this.setOpened(!opened);
									}}
									>
										<BarTitle
											opened={opened}
											className="gsb-bar-title"
											dangerouslySetInnerHTML={{
												__html: appearance?.barTitle,
											}}
										>
										</BarTitle>
									</BarContent>
									<Card
										className="gsb-card-container"
										opened={opened}
										height={contentHeight}
									>
										{!this.state.isFormSubmit ? (
											<>
												<div
													style={{
														marginBottom: "5%",
													}}
												>
													<CardTitle
														fontSizeMultiplier={this.props?.fontSizeMultiplier}
														className="gsb-card-title"
														color={appearance?.contentTitleFontColor} 
													>
														<span
															dangerouslySetInnerHTML={{
																__html: appearance?.contentTitle
															}}
														></span>
													</CardTitle>
													<CardText
														fontSizeMultiplier={this.props.fontSizeMultiplier}
														className="gsb-card-text"
														color={appearance?.contentSubTitleFontColor} 
													>
														<span
															dangerouslySetInnerHTML={{
																__html: appearance?.contentSubTitle
															}}
														></span>
													</CardText>
												</div>
												<FormComponent
													moduleId={this.props.moduleId}
													trackingId={this.props.trackingId}
													currentElement={this.props.code}
													appearance={appearance}
													isPreview={this.props.isPreview}
													isSession={isSession}
													onFormSubmit={onFormSubmit}
													language={this.props.language}
													fontSizeMultiplier={this.props.fontSizeMultiplier}
													isFormSubmit={this.state.isFormSubmit}
													setIsFormSubmit={this.setIsFormSubmit}
													formData={this.props.formData}
												></FormComponent>
											</>
										) : (
											<SuccessMessageComponent
												fontSizeMultiplier={this.props.fontSizeMultiplier}
												setOpened={(isOpended) => {
													if ([1, 2, 3, 4, 5, 6].includes(this.props.activeStep))
														return;
													this.setOpened(isOpended);
												}}
												setIsFormSubmit={this.setIsFormSubmit}
												appearance={appearance}
											/>
										)}
										<Footer className="gsb-footer" style={{ margin: 0 }}>
											<div
												style={{
													display: "flex",
													justifyContent: "space-between",
													alignContent: "center",
												}}
											>
												<a
													href="https://www.groweo.com/kayttoehdot/"
													target="_blank"
													rel="noreferrer"
												>
													<PolicyTextIcon color={appearance?.footerFontColor} />
												</a>
												<GroweoIcon color={appearance?.footerFontColor} />
											</div>
										</Footer>
									</Card>
								</SmartBarMobileContainer>
							</div>
						</ThemeProvider>
					) 
					: 
					(
						<ThemeProvider
							theme={getSmartBarTheme(appearance, this.props.activeStep)}
						>
							<div className="gsb">
								<SmartBarContainer
									className="gsb-container"
									opened={opened}
									height={barHeight}
									isSession={isSession}
									barWidth={barWidth}
								>
									{ mobileSwitchTheme?.showCloseButton && (
										<CloseButton
											className="gsb-close-button"
											barWidth={barWidth}
											onClick={() => {
												this.setOpened(!opened);
											}}
											disabled={isSession}
										>
											<CloseIcon color={appearance?.closeIconColor} />
										</CloseButton>
									) }
									<BarContent
										className="gsb-bar-content"
										opened={opened}
										onClick={() => {
											if ([1, 2, 3, 4, 5, 6].includes(this.props.activeStep) || isSession) return;
											this.setOpened(!opened);
										}}
									>
										<BarTitle
											opened={opened}
											className="gsb-bar-title"
											dangerouslySetInnerHTML={{
												__html: appearance?.barTitle,
											}}
										>
										</BarTitle>
									</BarContent>
									<Card
										className="gsb-card-container"
										opened={opened}
										height={contentHeight}
									>
										{!this.state.isFormSubmit ? (
											<>
												<div
													style={{
														marginBottom: "5%",
													}}
												>
													<CardTitle
														fontSizeMultiplier={this.props?.fontSizeMultiplier}
														className="gsb-card-title"
														color={appearance?.contentTitleFontColor} 
													>
														<span
															dangerouslySetInnerHTML={{
																__html: appearance?.contentTitle
															}}
														></span>
													</CardTitle>
													<CardText
														fontSizeMultiplier={this.props.fontSizeMultiplier}
														className="gsb-card-text"
														color={appearance?.contentSubTitleFontColor} 
													>
														<span
															dangerouslySetInnerHTML={{
																__html: appearance?.contentSubTitle
															}}
														></span>
													</CardText>
												</div>
												<FormComponent
													moduleId={this.props.moduleId}
													trackingId={this.props.trackingId}
													currentElement={this.props.code}
													appearance={appearance}
													isPreview={this.props.isPreview}
													isSession={isSession}
													onFormSubmit={onFormSubmit}
													language={this.props.language}
													fontSizeMultiplier={this.props.fontSizeMultiplier}
													isFormSubmit={this.state.isFormSubmit}
													setIsFormSubmit={this.setIsFormSubmit}
													formData={this.props.formData}
													onFormBtnSubmit={onFormBtnSubmit}
												></FormComponent>
											</>
										) : (
											<SuccessMessageComponent
												fontSizeMultiplier={this.props.fontSizeMultiplier}
												setOpened={(isOpended) => {
													if ([1, 2, 3, 4, 5, 6].includes(this.props.activeStep))
														return;
													this.setOpened(isOpended);
												}}
												setIsFormSubmit={this.setIsFormSubmit}
												appearance={appearance}
											/>
										)}
										<Footer className="gsb-footer" style={{ margin: 0 }}>
											<div
												style={{
													display: "flex",
													justifyContent: "space-between",
													alignContent: "center",
												}}
											>
												<a
													href="https://www.groweo.com/kayttoehdot/"
													target="_blank"
													rel="noreferrer"
												>
													<PolicyTextIcon color={appearance?.footerFontColor} />
												</a>
												<GroweoIcon color={appearance?.footerFontColor} />
											</div>
										</Footer>
									</Card>
								</SmartBarContainer>
							</div>
						</ThemeProvider>
					)
				}
			</>
		);
	}
}

SmartBar.propTypes = {
	fontSizeMultiplier: PropTypes.number,
	code: PropTypes.objectOf(PropTypes.any),
	appearance: PropTypes.object,
	floatingIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
};

SmartBar.defaultProps = {
	fontSizeMultiplier: 1,
	code: {},
	appearance: {},
	floatingIcon: <BarDefaultIcon />
};

export default SmartBar;
