import React, { Component } from "react";
import "../sdk/components/flow.css";
import { ThemeProvider } from "styled-components";
import SlideBuilder from "./SlideBuilder";
import {
  defaultStyle,
  getSlideTheme,
  slideStyle,
  isMobile as isMobileDevice,
  FONT_STYLE_MOBILE,
  FONT_STYLE_DESKTOP
} from "./utils";

class SlideBuilderX extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { appearance, inPage, isEditor, isSession, isPreview } = this.props;
    const { style } = appearance;
    const isMobile = this.props.isMobile || isMobileDevice();
    const mobileFontSize = (fontSize) => {
      return (fontSize / 100) * 80;
    };
    
    return (
        <ThemeProvider theme={getSlideTheme(appearance, inPage)}>
          {style?.googleFontUrl && <link rel="stylesheet" href={style?.googleFontUrl}/>}
          <style>{defaultStyle({inPage, isEditor, isMobile, isSession})}</style>
          <style>{slideStyle({ isMobile })}</style>
          <link rel="stylesheet" type="text/css" href="//cdn.jsdelivr.net/npm/slick-carousel@1.8.1/slick/slick.css"/>
          <link rel="stylesheet" type="text/css" href="//cdn.jsdelivr.net/npm/slick-carousel@1.8.1/slick/slick-theme.css"/>
          <link
            rel="stylesheet"
            href="https://test.groweo.com/sdk/form-appearance.css"
          />
          <div style={{
             '--wrapperHeight': `${this.props.isMobile ? 'auto' : `${style?.wrapperHeight}px`}`,
             '--wrapperFontFamily': style?.wrapperFontFamily,
             '--wrapperWidth': isMobile ? '100%' : '1000px' || style?.wrapperWidth,
             '--wrapperBgColor': style?.wrapperBgColor,
             '--wrapperBgImage': `url(${isMobile ? style?.mobileWrapperBgImage : style?.wrapperBgImage})`,
             '--mobileWrapperBgImage': `url(${style?.mobileWrapperBgImage})`,
             '--titleColor': style?.titleColor,
             '--titleFontSize': `${isMobile ? FONT_STYLE_MOBILE[style?.titleFontStyle]?.fontSize : FONT_STYLE_DESKTOP[style?.titleFontStyle]?.fontSize}px`,
             '--titleFontWeight': style?.titleFontWeight,
             '--titleLineHeight': `${isMobile ? FONT_STYLE_MOBILE[style?.titleFontStyle]?.lineHeight : FONT_STYLE_DESKTOP[style?.titleFontStyle]?.lineHeight}px`,
             '--titleTop': `calc(20px + ${style?.titleTop}%)`,
             '--titleWrapPadding': isMobile ? "0px" : style?.titleWrapPadding,
             '--subtitleColor': style?.subtitleColor,
             '--subtitleFontSize': `${isMobile ? FONT_STYLE_MOBILE[style?.subtitleFontStyle]?.fontSize : FONT_STYLE_DESKTOP[style?.subtitleFontStyle]?.fontSize}px`,
             '--subtitleFontWeight': style?.subtitleFontWeight,
             '--subtitleLineHeight': `${isMobile ? FONT_STYLE_MOBILE[style?.subtitleFontStyle]?.lineHeight : FONT_STYLE_DESKTOP[style?.subtitleFontStyle]?.lineHeight}px`,
             '--subtitleTop': `calc(20px + ${style?.subtitleTop}%)`,
             '--subtitleWrapPadding': isMobile ? "8px" : style?.subtitleWrapPadding,
             '--contentColor': style?.contentColor,
             '--contentFontSize': `${isMobile ? FONT_STYLE_MOBILE[style?.contentFontStyle]?.fontSize : FONT_STYLE_DESKTOP[style?.contentFontStyle]?.fontSize}px`,
             '--contentFontWeight': style?.contentFontWeight,
             '--contentLineHeight': `${isMobile ? FONT_STYLE_MOBILE[style?.contentFontStyle]?.lineHeight : FONT_STYLE_DESKTOP[style?.contentFontStyle]?.lineHeight}px`,
             '--contentTop': `calc(20px + ${style?.contentTop}%)`,
             '--contentWrapPadding': isMobile ? "8px" : style?.contentWrapPadding,
             '--opTitleColor': style?.opTitleColor,
             '--opTitleFontSize': `${isMobile ? FONT_STYLE_MOBILE[style?.opTitleFontStyle]?.fontSize : FONT_STYLE_DESKTOP[style?.opTitleFontStyle]?.fontSize}px`,
             '--opTitleFontWeight': style?.opTitleFontWeight,
             '--opTitleLineHeight': `${isMobile ? FONT_STYLE_MOBILE[style?.opTitleFontStyle]?.lineHeight : FONT_STYLE_DESKTOP[style?.opTitleFontStyle]?.lineHeight}px`,
             '--opSubtitleColor': style?.opSubtitleColor,
             '--opSubtitleFontSize': `${isMobile ? FONT_STYLE_MOBILE[style?.opSubtitleFontStyle]?.fontSize : FONT_STYLE_DESKTOP[style?.opSubtitleFontStyle]?.fontSize}px`,
             '--opSubtitleFontWeight': style?.opSubtitleFontWeight,
             '--opSubtitleLineHeight': `${isMobile ? FONT_STYLE_MOBILE[style?.opSubtitleFontStyle]?.lineHeight : FONT_STYLE_DESKTOP[style?.opSubtitleFontStyle]?.lineHeight}px`,
             '--opImgOutlineColor': style?.opImgOutlineColor,
             '--optionBgColor': style?.optionBgColor || '#00000024',
             '--opSelectionColor': style?.opSelectionColor,
             '--opImgHoverOutlineColor': style?.opImgHoverOutlineColor,
             '--badgeColor': style?.badgeColor,
             '--badgeDisableColor': style?.badgeDisableColor,
             '--submitDisableColor': style?.submitDisableColor || "#dbdbdb",
             '--dotsDisableColor': style?.dotsDisableColor || "#000000",
             '--dotsColor': style?.dotsColor || "#1A175E",
             '--formLabelColor': style?.formLabelColor,
             '--formLabelFontSize': `${isMobile ? FONT_STYLE_MOBILE[style?.formLabelFontStyle]?.fontSize : FONT_STYLE_DESKTOP[style?.formLabelFontStyle]?.fontSize}px`,
             '--formLabelFontWeight': style?.formLabelFontWeight,
             '--formLabelLineHeight': `${isMobile ? FONT_STYLE_MOBILE[style?.formLabelFontStyle]?.lineHeight : FONT_STYLE_DESKTOP[style?.formLabelFontStyle]?.lineHeight}px`,
             '--formMultiColumn': `${style?.formMultiColumn}%`,
             '--formWrapPadding': isMobile ? '0px' : style?.formWrapPadding,
             '--fieldBGColor': style?.fieldBGColor,
             '--fieldTextColor': style?.fieldTextColor || 'rgb(0, 0, 0)',
             '--fieldPlaceholderColor': style?.fieldPlaceholderColor,
             '--formFieldBGPercentage': `rgba(255, 255, 255, ${style?.formFieldBGPercentage / 100})`,
             '--footerTop': `calc(20px + ${style?.footerTop}%)`,
             '--col1Alignment': style?.col1Alignment,
             '--col2Alignment': style?.col2Alignment,
             '--footerMargin': isMobile ? "20px" : style?.footerMargin,
             '--submitColor': !style?.footerMode ? style?.submitColor : style?.submitBgColor,
             '--submitFontSize': `${isMobile ? style?.submitFontSize : 16 }px`,
             '--submitBgColor': !style?.footerMode ? style?.submitBgColor : style?.submitColor,
             '--submitBtnPadding': isMobile ? '12px 15px 14px' : '12px 86px 14px',
             '--submitHoverColor': !style?.footerMode ? style?.submitHoverColor : style?.submitHoverBgColor,
             '--submitHoverBgColor': !style?.footerMode ? style?.submitHoverBgColor : style?.submitHoverColor,
             '--closeBgColor': style?.closeBgColor,
             '--closeBorderRadius': style?.closeBorderRadius,
             '--closeMargin': style?.closeMargin,
             '--closeIconColor': style?.closeIconColor,
             '--groweoIconColor': style?.groweoIconColor,
             '--navigationBtnColor': style?.navigationBtnColor,
             '--groweoFontColor': style?.groweoFontColor,
             '--buttonBorder': style?.footerMode && `2px solid ${style?.submitBgColor}`,
             '--mobileMinHeight': inPage ? '750px' : '100%',
             '--dateFieldIcon': `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 24 24"><path fill="${style?.fieldTextColor || 'rgb(0, 0, 0)'}" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/></svg>')`,
             '--selectFieldIcon': `url('data:image/svg+xml;utf8,<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.375 7.1875L10 12.8125L15.625 7.1875" stroke="${style?.fieldTextColor || 'rgb(0, 0, 0)'}" stroke-width="1.875" stroke-linecap="round" stroke-linejoin="round"/></svg>')`,
             '--mobileZoom': isPreview ? 1 : `${Number(style?.mobileZoom || 100)/100}`
          }}>
            <SlideBuilder {...this.props} isMobile={isMobile}></SlideBuilder>
          </div>
        </ThemeProvider>
    );
  }
}

export default SlideBuilderX;
