import axios from "axios";

export const setGlobalItem = (name, value) => {
  window.sessionStorage.setItem(name, JSON.stringify(value));
}

export const getGlobalItem = (name) => {
  return JSON.parse(window.sessionStorage.getItem(name));
}

export const isMobile = () =>
  /iphone|ipod|android|ie|blackberry|fennec/i.test(navigator.userAgent);

export const isString = value => typeof value === "string";

export const getRenderedSteps = previousSteps => {
  const renderedSteps = previousSteps.map(step => {
    const { id, message, value, metadata } = step;

    return { id, message, value, metadata };
  });

  const steps = [];

  for (let i = 0, len = previousSteps.length; i < len; i += 1) {
    const { id, message, value, metadata } = previousSteps[i];

    steps[id] = { id, message, value, metadata };
  }

  const values = previousSteps
    .filter(step => step.value)
    .map(step => step.value);

  return { renderedSteps, steps, values };
};

export const prepareEndData = ({ stps: steps, renderedSteps }) => {
  const endData = {
    _renderedSteps: renderedSteps,
    for_notification: {}
  };
  for (let i = 0; i < renderedSteps.length; i++) {
    const step = renderedSteps[i];
    if (step.value !== undefined) {
      if (step.type === "form") {
        Object.assign(endData.for_notification, {
          ...step.value
        });
      } else
        endData.for_notification[renderedSteps[i - 1].message] = step.value;
    }
  }
  for (const key in steps) {
    const stp = steps[key];
    if (stp.value !== undefined) {
      if (stp.type === "form") {
        Object.assign(endData, stp.value);
      } else if (
        stp.metadata &&
        (stp.metadata.type === "email" || stp.metadata.name)
      ) {
        if (stp.metadata.type === "email") {
          endData.email = stp.value;
        } else if (stp.metadata.name.toLowerCase() === "phone") {
          endData.phone = stp.value;
        } else {
          endData[stp.metadata.name] = stp.value;
        }
      } else endData[stp.id] = stp.value;
    }
  }
  return endData;
};

export const postActivity = objData => {
  window.top.postMessage(
    {
      ...objData
    },
    "*"
  );
};

export const getThemeByScriptVersion = (isInPage, appearance) => {
  const primaryColor = appearance.primaryColor || "#1A175E";
  const theme = {
    light: {
      botBubbleColor: appearance?.botBubbleColor || "#F4F4F4",
      avatarColor: appearance?.userFontColor || primaryColor,
      smallAvatarColor: appearance?.smallAvatarColor || primaryColor,
      smallAvatarTextColor: appearance?.smallAvatarTextColor || "#4F4F4F",
      background: isInPage
        ? appearance?.background || "transparent"
        : appearance?.background || "#fff",
      headerFontSize: "18px",
      headerFontColor: appearance?.headerFontColor || primaryColor,
      userBubbleColor: appearance?.userBubbleColor || primaryColor,
      headerBgColor: appearance?.headerBgColor || "#fff",
      userFontColor: appearance?.userFontColor || "#fff",
      optionFontColor: appearance?.optionFontColor || "#fff",
      optionBubbleColor: appearance?.optionBubbleColor || primaryColor,
      iconColor: appearance?.iconColor || "#fff",
      iconBgColor:
        appearance?.iconBgType === "image"
          ? appearance?.iconBgImage
          : appearance?.iconBgColor || primaryColor,
      botFontColor: appearance?.botFontColor || "#4F4F4F",
      onOptionHoverTextColor:
        appearance?.onOptionHoverTextColor || appearance?.optionBubbleColor,
      onOptionHoverBgColor:
        appearance?.onOptionHoverBgColor || appearance?.optionFontColor,
      botFormLabelColor: appearance?.botFormLabelColor || "#000000",
      botFormButtonTextColor: appearance?.botFormButtonTextColor || "#fff",
      botFormButtonBgColor: appearance?.botFormButtonBgColor || primaryColor,
      botFormButtonHoverTextColor:
        appearance?.botFormButtonHoverTextColor || primaryColor,
      botFormButtonHoverBgColor:
        appearance?.botFormButtonHoverBgColor || "#fff",
      chatNotificationTextColor:
        appearance?.chatNotificationTextColor || "#4F4F4F",
      chatNotificationBgColor: appearance?.chatNotificationBgColor || "#fff",
      notificationBubbleHoverBgColor:
        appearance?.notificationBubbleHoverBgColor || appearance?.optionBubbleColor,
      notificationBubbleBgColor:
        appearance?.notificationBubbleBgColor || appearance?.optionFontColor,
      cardBtnTextColor: appearance?.cardBtnTextColor || "#fff",
      cardBtnBgColor: appearance?.cardBtnBgColor || primaryColor,
      cardBtnHoverTextColor: appearance?.cardBtnHoverTextColor || primaryColor,
      cardBtnHoverBgColor: appearance?.cardBtnHoverBgColor || "#fff"
    },
    dark: {
      botBubbleColor: appearance?.botBubbleColor || "#F4F4F4",
      avatarColor: appearance?.userFontColor || "#fff",
      smallAvatarColor: appearance?.smallAvatarColor || primaryColor,
      smallAvatarTextColor: appearance?.smallAvatarTextColor || "#4F4F4F",
      background: isInPage
        ? appearance?.background || "transparent"
        : appearance?.background || "#fff",
      headerFontSize: "18px",
      headerFontColor: appearance?.headerFontColor || "#fff",
      userBubbleColor: appearance?.userBubbleColor || primaryColor,
      headerBgColor: appearance?.headerBgColor || primaryColor,
      userFontColor: appearance?.userFontColor || "#fff",
      optionFontColor: appearance?.optionFontColor || "#fff",
      optionBubbleColor: appearance?.optionBubbleColor || primaryColor,
      iconColor: appearance?.iconColor || "#fff",
      iconBgColor:
        appearance?.iconBgType === "image"
          ? appearance?.iconBgImage
          : appearance?.iconBgColor || primaryColor,
      botFontColor: appearance?.botFontColor || "#4F4F4F",
      onOptionHoverTextColor:
        appearance?.onOptionHoverTextColor || appearance?.optionBubbleColor,
      onOptionHoverBgColor:
        appearance?.onOptionHoverBgColor || appearance?.optionFontColor,
      botFormLabelColor: appearance?.botFormLabelColor || "#000000",
      botFormButtonTextColor: appearance?.botFormButtonTextColor || "#fff",
      botFormButtonBgColor: appearance?.botFormButtonBgColor || primaryColor,
      botFormButtonHoverTextColor:
        appearance?.botFormButtonHoverTextColor || primaryColor,
      botFormButtonHoverBgColor:
        appearance?.botFormButtonHoverBgColor || "#fff",
      chatNotificationTextColor:
        appearance?.chatNotificationTextColor || "#4F4F4F",
      chatNotificationBgColor: appearance?.chatNotificationBgColor || "#fff",
      notificationBubbleHoverBgColor:
        appearance?.notificationBubbleHoverBgColor || appearance?.optionBubbleColor,
      notificationBubbleBgColor:
      appearance?.notificationBubbleBgColor || appearance?.optionFontColor,
      cardBtnTextColor: appearance?.cardBtnTextColor || "#fff",
      cardBtnBgColor: appearance?.cardBtnBgColor || primaryColor,
      cardBtnHoverTextColor: appearance?.cardBtnHoverTextColor || primaryColor,
      cardBtnHoverBgColor: appearance?.cardBtnHoverBgColor || "#fff"
    }
  };
  return theme;
};
