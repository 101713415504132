import styled from "styled-components";
import defaultTheme from "../theme";

const PopupHeader = styled.div`
  position: relative;
`;

PopupHeader.defaultProps = {
  theme: defaultTheme
};

export default PopupHeader;
