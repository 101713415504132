export const setGlobalItem = (name, value) => {
  window.sessionStorage.setItem(name, JSON.stringify(value));
}

export const isMobile = () =>
  /iphone|ipod|android|ie|blackberry|fennec/i.test(navigator.userAgent);

export const postActivity = objData => {
  window.top.postMessage(
    {
      ...objData
    },
    '*'
  );
};

export const getSmartBarTheme = (appearance, activeStep) => {
  const primaryColor = appearance?.primaryColor || '#1A175E';
  const theme = {
    width: appearance?.width || '400px',
    height: appearance?.height || '400px',
    direction: appearance?.direction || 'right',
    barTitleFontSize: appearance?.barTitleFontSize || 1,
    barFontColor: appearance?.barFontColor || 'black',
    barBgColor: appearance?.barBgColor || '#FED900',
    contentBgImage: appearance?.contentBgImage || '',
    contentBgColor: appearance?.contentBgColor || '#FED900',
    contentTitleFontColor : appearance?.contentTitleFontColor || 'black',
    contentSubTitleFontColor : appearance?.contentSubTitleFontColor || 'black',
    footerFontColor: appearance?.footerFontColor || 'black',
    successTitleFontColor : appearance?.successTitleFontColor || 'black',
    successSubTitleFontColor : appearance?.successSubTitleFontColor || 'black',
    barFieldBGPercentage: appearance?.barFieldBGPercentage || 100,
    barFormPadding: appearance?.barFormPadding || '5px',
    barFormLabelColor: appearance?.barFormLabelColor || '#000000',
    barFormButtonTextColor: appearance?.barFormButtonTextColor || '#fff',
    barFormButtonBgColor: appearance?.barFormButtonBgColor || '#c6a23f',
    invertBarColor: !!appearance?.invertBarColor,
    autoBarHeight: !!appearance?.autoBarHeight,
    barFormButtonHoverTextColor:
      appearance?.barFormButtonHoverTextColor || primaryColor,
    barFormButtonHoverBgColor: appearance?.barFormButtonHoverBgColor || '#fff',
    fontFamily: 'Helvetica',
    iconBgColor: appearance?.iconBgType === "image"
      ? appearance?.iconBgImage
      : appearance?.iconBgColor || primaryColor,
    iconColor: typeof appearance?.iconColor === 'undefined' ? '#fff' : appearance?.iconColor,
    SwitchToMobileMode: typeof appearance?.SwitchToMobileMode === 'undefined' ? true : appearance?.SwitchToMobileMode,
    iconBgType: typeof appearance?.iconBgType === 'undefined' ? 'bar' : appearance?.iconBgType,
    iconColorPosition: typeof appearance?.iconColorPosition === 'undefined' ? 'middle' : appearance?.iconColorPosition,
    iconImagePosition: typeof appearance?.iconImagePosition === 'undefined' ? 'middle' : appearance?.iconImagePosition,
    imageBgColor: typeof appearance?.imageBgColor === 'undefined' ? '#1A175E' : appearance?.imageBgColor,
    showCloseButton: appearance?.closeBgColor ? true : false,
    closeBgColor: appearance?.closeBgColor || 'transparent',
    closeIconColor: appearance?.closeIconColor || '#000000',
    closeBorderRadius: appearance?.closeBorderRadius || '0px',
    closeMargin: appearance?.closeMargin || '0px',
    barFormInputTextColor: appearance?.barFormInputTextColor || '#0000',
    barFormInputBgColor: appearance?.barFormInputBgColor || 'rgba(245, 248, 250, 1)',
    barFormInputPlaceholderColor: appearance?.barFormInputPlaceholderColor || '#999',
    formButtonType: appearance?.formButtonType || 'Form Submit',
    barFormAutoWidth: appearance?.barFormAutoWidth || true,
    barFormAutoWidthRange: appearance?.barFormAutoWidthRange || 100,
    barFormButtonPosition: appearance?.barFormButtonPosition || 'left'
  };
  return theme;
};
