import styled from 'styled-components';
import defaultTheme from '../theme';

const FormButton = styled.button`
  background-color: ${({ theme }) => theme.barFormButtonBgColor};
  border: 2px solid ${({ theme }) => theme.barFormButtonBgColor};
  text-transform: 'none';
  display: inline-flex;
  justify-content: center;
  border-radius: 6px;
  cursor: pointer;
  width: ${({ barFormAutoWidth, barFormAutoWidthRange}) => (typeof barFormAutoWidth === 'undefined' || barFormAutoWidth) ? 'auto !important' : `${typeof barFormAutoWidthRange === 'undefined' ? 100 : barFormAutoWidthRange}%`};
  color: ${({ theme }) => theme.barFormButtonTextColor};
  font-size: ${({ fontSizeMultiplier }) =>
    `${fontSizeMultiplier * 14 || 14}px`};
  line-height: ${({ fontSizeMultiplier }) =>
    `${fontSizeMultiplier * 20 || 20}px`};
  padding: 11px 16px;
  font-weight: 600;
  text-transform: unset;
  &:hover {
    color: ${({ theme }) => `${theme.barFormButtonHoverTextColor} !important`};
    background: ${({ theme }) => theme.barFormButtonHoverBgColor};
    border: 2px solid ${({ theme }) => theme.barFormButtonHoverTextColor};
  }
  &:active,
  &:hover:focus {
    outline: none;
  }
`;

FormButton.defaultProps = {
  theme: defaultTheme
};

export default FormButton;
