import styled from "styled-components";

const LeftArrowContainer = styled.div`
  position: absolute;
  top: 25%;
  left: -50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 50%;
  padding: 2px;
  fill: ${({ theme }) => (theme.userFontColor ? theme.userFontColor : "black")};
`;

// LeftArrowContainer.defaultProps = {
//   theme: defaultTheme,
// };

export default LeftArrowContainer;
