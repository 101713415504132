import styled from 'styled-components';
import defaultTheme from '../theme';

const CardTitle = styled.h4`
  margin: 10px 0px 0px !important;
  color: ${({color}) => color};
  font-size: ${({ fontSizeMultiplier }) => `${fontSizeMultiplier * 28}px`};
  line-height: ${({ fontSizeMultiplier }) => `${fontSizeMultiplier * 34}px`};
`;

CardTitle.defaultProps = {
  theme: defaultTheme
};
export default CardTitle;
