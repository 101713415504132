/* eslint-disable react/no-unused-state */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React, { Component } from "react";
import { flexGrowContent } from "../utils";

class End extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const content = this.props.data?.data?.content;

    return (
      <div className="gsf-container" style={flexGrowContent}>
        <div className="gsf-title">
          <div
            className="gsf-wrap-text"
            dangerouslySetInnerHTML={{ __html: content?.title }}
          />
        </div>
        <div className="gsf-subtitle">
          <div
            className="gsf-wrap-text"
            dangerouslySetInnerHTML={{ __html: content?.paragraph1 }}
          />
        </div>
        <div className="gsf-content">
          <div
            className="gsf-wrap-text"
            dangerouslySetInnerHTML={{ __html: content?.paragraph2 }}
          ></div>
        </div>
      </div>
    );
  }
}
export default End;
