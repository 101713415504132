import React from "react";

const GroweoIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_2"
      data-name="Layer 2"
      viewBox="0 0 92.67 9.03"
      width="125"
      height="30"
    >
      <g id="Layer_3" data-name="Layer 3" stroke="none" fill={props.color}>
        <g>
          <g>
            <path d="m63.01.4h.14c.34,0,.61.28.61.61v.09c0,.34-.28.61-.61.61h0c-.53,0-.75.21-.75.76v3.44c0,.4-.33.73-.73.73h0c-.4,0-.73-.33-.73-.73v-3.41c0-1.47.73-2.12,2.07-2.12Z" />
            <path d="m63.69,3.53c0-1.91,1.44-3.28,3.28-3.28s3.28,1.37,3.28,3.28-1.44,3.29-3.28,3.29c-1.83,0-3.28-1.39-3.28-3.29Zm3.28,1.91c1.03,0,1.81-.78,1.81-1.91s-.78-1.91-1.81-1.91-1.81.78-1.81,1.91.78,1.91,1.81,1.91Z" />
            <path d="m70.97.4h0c.32,0,.61.21.7.52l1.12,3.78,1.17-3.66c.12-.39.48-.65.89-.65h0c.4,0,.76.26.89.65l1.13,3.55,1.15-3.7c.09-.29.37-.5.68-.5h0c.48,0,.83.47.67.93l-1.56,4.69c-.13.38-.49.64-.89.64h0c-.4,0-.76-.26-.89-.64l-1.11-3.29c-.03-.09-.16-.09-.19,0l-1.1,3.25c-.13.4-.51.67-.93.67h0c-.43,0-.81-.28-.94-.69l-1.47-4.62c-.16-.47.2-.95.69-.95Z" />
            <path d="m79.39,3.54c0-1.92,1.37-3.28,3.18-3.28,1.58,0,3.01,1.02,3.01,3.25,0,.18-.15.33-.33.33h-4.49c.12.99.87,1.68,1.93,1.68.61,0,1.14-.22,1.57-.64.22-.21.57-.22.81-.03h0c.29.23.32.67.05.93-.6.6-1.43,1-2.47,1-1.95,0-3.26-1.37-3.26-3.25Zm4.8-.75c-.18-.88-.8-1.33-1.63-1.33-.88,0-1.54.55-1.75,1.33h3.38Z" />
            <path d="m86.12,3.53c0-1.91,1.44-3.28,3.28-3.28s3.28,1.37,3.28,3.28-1.44,3.29-3.28,3.29c-1.83,0-3.28-1.39-3.28-3.29Zm3.28,1.91c1.03,0,1.81-.78,1.81-1.91s-.78-1.91-1.81-1.91-1.81.78-1.81,1.91.78,1.91,1.81,1.91Z" />
            <path d="m60.3,3.54c0-1.91-1.44-3.28-3.28-3.28s-3.28,1.37-3.28,3.28,1.44,3.29,3.28,3.29c.61,0,1.18-.15,1.66-.43-.28.77-.99,1.25-1.9,1.25-.2,0-.39-.02-.58-.07-.32-.08-.65.07-.8.37h0c-.18.4.04.85.45.96.31.08.63.12.96.12,2.05,0,3.48-1.43,3.48-3.46v-2.03h0Zm-3.28,1.91c-1.03,0-1.81-.78-1.81-1.91s.78-1.91,1.81-1.91,1.81.78,1.81,1.91-.78,1.91-1.81,1.91Z" />
          </g>
          <g>
            <path d="m2.76.35c1.5,0,2.28.68,2.28,2.01s-.78,2.02-2.28,2.02H.92v2.35h-.92V.35h2.76Zm-.04,3.21c.92,0,1.38-.4,1.38-1.2s-.46-1.19-1.38-1.19H.92v2.39h1.8Z" />
            <path d="m10.32,4.47c0,1.37-.99,2.34-2.38,2.34s-2.38-.97-2.38-2.34.99-2.34,2.38-2.34,2.38.97,2.38,2.34Zm-3.94,0c0,.95.63,1.61,1.56,1.61s1.56-.66,1.56-1.61-.64-1.61-1.56-1.61-1.56.66-1.56,1.61Z" />
            <path d="m15.36,6.72l-1.16-3.29h-.02l-1.17,3.29h-.8l-1.58-4.49h.87l1.12,3.47h.02l1.23-3.47h.66l1.23,3.46h.02l1.13-3.46h.83l-1.58,4.49h-.8Z" />
            <path d="m18.87,4.74c.12.84.79,1.34,1.8,1.34.66,0,1.24-.21,1.66-.61h.04v.83c-.4.33-1.02.52-1.72.52-1.58,0-2.61-.93-2.61-2.34s.97-2.34,2.34-2.34,2.28.85,2.28,2.16v.45h-3.79Zm0-.68h3.03c-.12-.73-.69-1.2-1.51-1.2-.77,0-1.37.48-1.53,1.2Z" />
            <path d="m26.47,2.3v.82h-.04c-.19-.13-.43-.21-.7-.21-.86,0-1.28.6-1.28,1.77v2.04h-.82V2.23h.69l.02.72h.02c.27-.54.74-.82,1.42-.82.3,0,.53.08.68.17Z" />
            <path d="m27.65,4.74c.12.84.79,1.34,1.81,1.34.66,0,1.24-.21,1.66-.61h.04v.83c-.4.33-1.02.52-1.72.52-1.58,0-2.61-.93-2.61-2.34s.97-2.34,2.34-2.34,2.28.85,2.28,2.16v.45h-3.79Zm0-.68h3.03c-.12-.73-.69-1.2-1.51-1.2-.77,0-1.37.48-1.53,1.2Z" />
            <path d="m36.15,6.72l-.03-.71h-.02c-.34.5-.95.81-1.74.81-1.31,0-2.25-.98-2.25-2.34s.94-2.34,2.25-2.34c.73,0,1.29.25,1.64.68h.02V0h.82v6.72h-.69Zm-.1-2.25c0-.95-.64-1.61-1.56-1.61s-1.56.66-1.56,1.61.63,1.61,1.56,1.61,1.56-.66,1.56-1.61Z" />
            <path d="m44.95,4.47c0,1.36-.95,2.34-2.26,2.34-.79,0-1.39-.3-1.74-.81h-.02l-.02.71h-.69V0h.82v2.8h.02c.36-.42.91-.67,1.63-.67,1.31,0,2.26.98,2.26,2.34Zm-.83,0c0-.95-.64-1.61-1.56-1.61s-1.56.66-1.56,1.61.63,1.61,1.56,1.61,1.56-.66,1.56-1.61Z" />
            <path d="m46.19,8.54l.91-1.99-1.88-4.33h.88l1.43,3.48h.02l1.44-3.48h.84l-2.8,6.32h-.84Z" />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default GroweoIcon;
