export const setGlobalItem = (name, value) => {
  window.sessionStorage.setItem(name, JSON.stringify(value));
}

export const getGlobalItem = (name) => {
  return JSON.parse(window.sessionStorage.getItem(name));
}

export const isMobile = () =>
  /iphone|ipod|android|ie|blackberry|fennec/i.test(navigator.userAgent);

export const isString = value => typeof value === "string";

export const prepareEndData = steps => {
  const endData = {
    _renderedSteps: steps,
    for_notification: {}
  };
  for (const key in steps) {
    const step = steps[key];
    const {
      currentSlide: { data, value },
      type,
      id
    } = step;
    if (value) {
      if (type === "content") {
        Object.assign(endData.for_notification, { ...value });
      } else endData.for_notification[data?.name || id] = value;
    }
  }
  Object.assign(endData, {
    ...endData.for_notification
  });
  return endData;
};

export const postActivity = objData => {
  window.top.postMessage(
    {
      ...objData
    },
    "*"
  );
};

export const getSlideTheme = (appearance, inPage) => {
  const theme = {
    inPage: !!inPage,
    width: appearance?.width || "auto",
    height: appearance?.height || 650,
    titleFontSize: appearance?.titleFontSize || 50,
    titlePadding: appearance?.titlePadding || "5px",
    titleTop: appearance?.titleTop || 0,
    para1FontSize: appearance?.para1FontSize || "18",
    para1Padding: appearance?.para1Padding || "5px",
    para1Top: appearance?.para1Top || 12.3,
    para2FontSize: appearance?.para2FontSize || 18,
    buttonFontSize: appearance?.buttonFontSize || 18,
    optionTitleFontSize: appearance?.optionTitleFontSize || 20,
    optionSubtitleFontSize: appearance?.optionSubtitleFontSize || 18,
    optTitleFontWeight: appearance?.optTitleFontWeight || 500,
    optSubTitleFontWeight: appearance?.optSubTitleFontWeight || 400,
    labelFontSize: appearance?.labelFontSize || 18,
    labelFontWeight: appearance?.labelFontWeight || 400,
    titleFontColor: appearance?.titleFontColor || "#000",
    para1FontColor: appearance?.para1FontColor || "#000",
    para2FontColor: appearance?.para2FontColor || "#000",
    bgType: appearance?.bgType || "color",
    background: appearance?.background || "#ffffff",
    titleFontWeight: appearance?.titleFontWeight || 700,
    para1FontWeight: appearance?.para1FontWeight || 600,
    para2FontWeight: appearance?.para2FontWeight || 500,
    backgroundImage: appearance?.backgroundImage || "",
    btnBackground: appearance?.btnBackground,
    btnTextColor: appearance?.btnTextColor || "#ffffff",
    buttonHoverColor: appearance?.buttonHoverColor || "#FFF",
    buttonTop: appearance?.buttonTop || 12.3,
    optionBorderColor: appearance?.optionBorderColor || "#000",
    optionHoverBorderColor: appearance?.optionHoverBorderColor || "#41B35C",
    optionTitleColor: appearance?.optionTitleColor || "#000",
    formLabelColor: appearance?.formLabelColor || "rgb(0 0 0 / 50%)",
    optionSubtitleColor: appearance?.optionSubtitleColor || "#000000",
    navigationBtnColor: appearance?.navigationBtnColor || "#000000",
    badgeColor: appearance?.badgeColor || "#999",
    badgeDisableColor: appearance?.badgeDisableColor || "#999",
    fieldPlaceholderColor: appearance?.fieldPlaceholderColor || "#999",
    groweoFontColor: appearance?.groweoFontColor || "#4F4F4F",
    groweoIconColor: appearance?.groweoIconColor || "#4F4F4F",
    fontFamily: appearance?.fontFamily || "Helvetica",
    closeBtnBackgroundColor: appearance?.closeBtnBackgroundColor || "#DDD",
    closeIconColor: appearance?.closeIconColor || "#000",
    closeBtnBorderRadius: appearance?.closeBtnBorderRadius || "0px",
    closeBtnMargin: appearance?.closeBtnMargin || "0px"
  };
  return theme;
};

export const defaultStyle = ({inPage, isEditor, isMobile, isSession}) => `
.gsf-slide-wrapper {
  word-wrap: break-word;
  display: flex;
  position: relative;
  justify-content: center;
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-position-x: left;
  background-position-y: ${isMobile ? 'top' : 'bottom'};
  ${isMobile ? `min-height: ${inPage ? '750px' : '100%'};
  background-size: 100%;` : '' }
}
.gsf-root {
  ${isSession ? `
    overflow: auto;
  ` : `
    display: flex;
    justify-content: center;
  `}
}
.gsf-slide-container {
  word-wrap: break-word;
  width: ${isMobile ? '100%' : '1000px'};
  flex-direction: column; 
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: ${isMobile ? '20px 0' : '20px'};
  justify-content: space-between;
}
${
  !isEditor
    ? `
      @-webkit-keyframes fadeIn {
        0% {
            opacity: 0
        }
        to {
            opacity: 1
        }
      }
      @keyframes fadeIn {
        0% {
            opacity: 0
        }
        to {
            opacity: 1
        }
      }
      .animate__fadeIn {
        -webkit-animation: fadeIn 3s;
        animation: fadeIn 3s;
      }
    `
    : ""
}
.gsf-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
}
.gsf-title {
  text-align: center;
  margin: 0;
  word-break: break-word;
  width: 100%;
  line-height: 34px;
  align-self: center;
}
.gsf-subtitle {
  text-align: center;
  margin: 0;
  word-break: break-word;
  width: 100%;
  line-height: 20px;
  align-self: center;
}
.gsf-content {
  text-align: center;
  margin: 0 auto;
  word-break: break-word;
  width: 100%;
  line-height: 24px;
  display: flex;
  flex-direction: column;
}
.gsf-option-wrapper {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  width: 100%;
}
.gsf-left-arrow {
  position: absolute;
  top: 25%;
  left: -50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 50%;
  padding: 2px;
  fill: black;
}
.gsf-right-arrow {
  position: absolute;
  top: 25%;
  right: -50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 50%;
  padding: 2px;
  fill: black;
}
.gsf-options {
  margin: 0 auto;
  width: 716px;
  line-height: 24px;
  display: flex;
  flex-direction: column;
}
.gsf-option-slider {
  display: flex;
  transition: all 0.5s ease;
}
.gsf-option-imagewrapper {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  width: 160px;
  height: 160px;
  margin: 8px;
}
.option-selected::before ${!isMobile ? ',.gsf-option-imagewrapper:hover::before' : ''} {
  background-color: var(--opSelectionColor);
  content: '';
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0.5;
  border-radius: 12px;
}
.gsf-checkmark {
  position: absolute;
  width: 22px;
  height: 22px;
  top: 4px;
  right: 4px;
  border-radius: 50%;
}
.gsf-option-image {
  overflow: hidden;
  height: 160px;
  border-radius: 12px;
}
.gsf-titles-wrapper {
  display: flex;
  margin: 5px 10px;
  flex-direction: column;
  word-break: break-word;
  width: 159px;
  text-align: center;
}
.gsf-option-title {
  align-self: center;
  line-height: 20px;
}
.gsf-option-subtitle {
  align-self: center;
  line-height: 20px;
}
.gsf-slider-badgewrapper {
  display: flex;
  justify-content: ${isMobile ? "start" : "end"};
  margin-top: 12px;
  margin-bottom: 8px;
}
.gsf-slider-badge {
  display: inline-block;
  outline: 0;
  border: 0;
  margin-right: 9px;
  margin-left: 9px;
  cursor: pointer;
  color: black !important;
  border-radius: 15px;
  padding: 8px 14px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  vertical-align: baseline;
  text-shadow: none;
  z-index: 1;
}
.gsf-input-wrapper {
  margin-top: 4px !important;
}
.gsf-input-file{
  color: var(--fieldPlaceholderColor) !important;
  text-transform: lowercase;
}
.gsf-form-label {
  line-height: 20px !important;
}
.gsf-form {
  max-height: 335px;
  overflow: auto;
  line-height: 20px !important;
  background: transparent !important;
  width: inherit;
  border-radius: 0px !important;
}
.gsf-form-mobile {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  width: 100%;
}
.gsf-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100%);
  z-index: 500;
  ${isMobile ? 'margin: 20px 0;' : ''}
}
.gsf-right-navigation {
  display: flex;
  cursor: pointer;
  padding: 8px 10px 8px 7px;
  -webkit-tap-highlight-color: transparent;
}
.gsf-left-navigation {
  display: flex;
  cursor: pointer;
  padding: 8px 7px 8px 10px;
}
.classic-btn {
  border-radius: 0px;
}
.classic-rounded-btn {
  border-radius: 25px;
}
.modern-btn {
  border-radius: 8px;
}
.gsf-policy {
  position: absolute;
  width: 100%;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
  max-width: 1000px;
  display: flex;
  justify-content: space-between;
  text-decoration: none;
}
.gsf-policy-text {
  margin-left: 20px;
}
.gsf-powered-by {
  margin-right: 20px;
}
.gsf-submit-btn {
  display: inline-block;
  outline: 0;
  border: 0;
  cursor: pointer;
  font-weight: 500;
  letter-spacing: 0.02em;
  vertical-align: baseline;
  text-shadow: none;
  opacity: 100;
  line-height: 20px;
  -webkit-tap-highlight-color: transparent;
}
.badge-fix {
  position: fixed;
  top: 10px;
}
.gsf-popup-container {
  display: flex;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: rgba(0,0,0,0.4);
}
.gsf-popup-main {
  width: 100%;
  max-width: 1000px;
  height: auto;
  margin: auto;
}
.gsf-popup-header {
  position: relative;
}
.gsf-popup-close-button {
  position: ${isMobile ? "fixed" : "absolute"};
  top: 0px;
  right: 0px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  margin: 0px;
  padding: 0px;
  width: 40px;
  height: 40px;
  border: 0;
  cursor: pointer;
  z-index: 99;
}
.gsf-container p,
.gsf-container h1,
.gsf-container h2,
.gsf-container h3,
.gsf-container h4,
.gsf-container h5,
.gsf-container h6,
.gsf-container ul,
.gsf-container ol,
.gsf-container button,
.gsf-submit-btn p {
  margin: 0 !important;
}
.slick-prev:before,
.slick-next:before {
  display: none;
}
.slick-next {
  right: -50px !important;
  top: 38% !important;
  width: 43px !important;
  height: 43px !important;
}
.gsf-option-placeholder{
  background-color: var(--optionBgColor);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  color: black;
}
.slick-dots li button:before {
  font-family: auto !important;
  font-size: 33px !important;
  color: var(--dotsDisableColor) !important;
}
.slick-dots li {
  margin: 0px 3px !important;
}
.slick-dots {
  top: -35px !important;
  bottom: auto !important;
}
.slick-dots li.slick-active button:before {
  color: var(--dotsColor) !important;
}
.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: .75 !important;
  color: var(--dotsColor) !important;
}
.slick-prev {
  left: -55px !important;
  top: 38% !important;
  width: 43px !important;
  height: 43px !important;
}
.slick-slide {
  width: auto !important;
}
.gsf-options .row::-webkit-scrollbar { 
  display: none; 
}
@media only screen and (max-width: 1030px) {
  .gsf-options {
    width: 720px
  }
}
@media only screen and (max-width: 825px) {
  .gsf-options {
    width: 540px
  }
}
@media only screen and (max-width: 480px) {
  .gsf-options {
    width: 360px
  }
  .gsf-popup-main {
    height: 100% !important;
  }
  .gsf-slide-wrapper {
    min-height: var(--mobileMinHeight);
    width: 100% !important;
    height: auto !important;
    background-image: var(--mobileWrapperBgImage);
    background-position-y: top;
    background-size: 100%;
    zoom: var(--mobileZoom);
  }
  .gsf-root {
    min-height: var(--mobileMinHeight);
    width: 100% !important;
    height: auto !important;
  }
  .gsf-footer {
    margin: 20px 0; 
  }
}
.slick-track {
  display: flex !important;
  justify-content: center;
};`;

export const FONT_STYLE_DESKTOP = {
  heading: { fontSize: 42, lineHeight: 48 },
  xlarge: { fontSize: 26, lineHeight: 30 },
  large: { fontSize: 20, lineHeight: 26 },
  normal: { fontSize: 18, lineHeight: 23 },
  small: { fontSize: 16, lineHeight: 22 },
  xysmall: { fontSize: 14, lineHeight: 20 }
};

export const FONT_STYLE_MOBILE = {
  heading: { fontSize: 28, lineHeight: 33 },
  xlarge: { fontSize: 20, lineHeight: 24 },
  large: { fontSize: 18, lineHeight: 23 },
  normal: { fontSize: 16, lineHeight: 22 },
  small: { fontSize: 14, lineHeight: 20 },
  xysmall: { fontSize: 12, lineHeight: 16 }
};

export const flexGrowContent = {
  flexGrow: 1,
  justifyContent: "center",
  alignSelf: "center"
};

export const slideStyle = ({isMobile}) => `:host {
  --containerTopMargin: 20px;
  --wrapperHeight: 650;
  --wrapperFontFamily: Proxima Nova;
  --wrapperWidth: auto;
  --wrapperBgColor: #ffffff;
  --wrapperBgImage: url();
  --mobileWrapperBgImage: url();
  --titleColor: #000000;
  --titleFontSize: 50px;
  --titleFontWeight: 0;
  --titleLineHeight: 140%;
  --titleFontStyle: heading;
  --titleTop: 20px;
  --titleWrapPadding: 5px;
  --subtitleColor: #000000;
  --subtitleFontSize: 18px;
  --subtitleFontWeight: 0;
  --subtitleLineHeight: 140%;
  --subtitleFontStyle: large;
  --subtitleTop: 20px;
  --subtitleWrapPadding: 20px 5px 5px 5px ;
  --contentColor: #000000;
  --contentFontSize: 24px;
  --contentFontWeight: 0;
  --contentLineHeight: 140%;
  --contentFontStyle: normal;
  --contentTop: 30px;
  --contentWrapPadding: 20px;
  --opTitleColor: #000000;
  --opTitleFontSize: 20px;
  --opTitleFontWeight: 0;
  --opTitleLineHeight: 140%;
  --opTitleFontStyle: small;
  --opSubtitleColor: #000000;
  --opSubtitleFontSize: 18px;
  --opSubtitleFontWeight: 0;
  --opSubtitleLineHeight: 140%;
  --opSubtitleFontStyle: small;
  --opImgOutline: 1px solid;
  --opImgOutlineColor: #000000;
  --opImgHoverOutlineColor: #41B35C;
  --optionBgColor: #00000024;
  --opSelectionColor: #41B35C;
  --badgeColor: #41B35C;
  --badgeDisableColor: #dbdbdb;
  --submitDisableColor: #dbdbdb;
  --dotsDisableColor: #000000;
  --dotsColor: #1A175E;
  --formLabelColor: #000000;
  --formLabelFontSize: 16px;
  --formLabelFontWeight: 0;
  --formLabelLineHeight: 140%;
  --formFieldBGPercentage: rgba(255, 255, 255, 0);
  --fieldBGColor: #f4f4f4;
  --fieldTextColor: rgb(0, 0, 0);
  --fieldPlaceholderColor: #999;
  --formMultiColumn: 100%;
  --col1Alignment: start;
  --col2Alignment: start;
  --footerTop: 30px;
  --footerMargin: 40px 0px 40px 0px;
  --submitColor: #fff;
  --submitFontSize: 18px;
  --submitBtnPadding: 12px 86px 14px;
  --submitBgColor: #4F4F4F;
  --submitHoverColor: #fff;
  --submitHoverBgColor: #000;
  --closeBgColor: #DDDDDD;
  --closeBorderRadius: 0px;
  --closeMargin: 0px;
  --closeIconColor: #000000;
  --groweoIconColor: #000000;
  --groweoFontColor: #000000;
  --navigationBtnColor: #00000;
  --buttonBorder: 0px solid;
  --formWrapPadding: 0px; 
  --mobileMinHeight: 750px;
  --dateFieldIcon: '';
  --selectFieldIcon: '';
  --mobileZoom: 1
}
.gsf-root {
  background-color: var(--wrapperBgColor);
  height: var(--wrapperHeight);
  width: auto;
}

.gsf-slide-container {
  margin: var(--footerMargin);
}

.gsf-slide-wrapper {
  height: var(--wrapperHeight);
  font-family: var(--wrapperFontFamily);
  width: var(--wrapperWidth);
  background-image: var(--wrapperBgImage);
}
.gsf-title {
  color: var(--titleColor);
  font-size: var(--titleFontSize);
  font-weight: var(--titleFontWeight);
  top: var(--titleTop);
  line-height: var(--titleLineHeight)
}
.gsf-title .gsf-wrap-text {
  padding: var(--titleWrapPadding);
}
.gsf-subtitle {
  color: var(--subtitleColor);
  font-size: var(--subtitleFontSize);
  font-weight: var(--subtitleFontWeight);
  top: var(--subtitleTop);
  line-height: var(--subtitleLineHeight)
}
.gsf-content {
  color: var(--contentColor);
  font-size: var(--contentFontSize);
  font-weight: var(--contentFontWeight);
  top: var(--contentTop);
  line-height: var(--contentLineHeight)
}
.gsf-content .gsf-wrap-text {
  padding: var(--contentWrapPadding);
}
.gsf-subtitle .gsf-wrap-text {
  padding: var(--subtitleWrapPadding);
}
.gsf-option-title {
  color: var(--opTitleColor);
  font-size: var(--opTitleFontSize);
  font-weight: var(--opTitleFontWeight);
  line-height: var(--opTitleLineHeight)
}
.gsf-option-subtitle {
  color: var(--opSubtitleColor);
  font-size: var(--opSubtitleFontSize);
  font-weight: var(--opSubtitleFontWeight);
  line-height: var(--opSubtitleLineHeight)
}
.gsf-option-imagewrapper {
  outline: 1px solid;
  outline-color: transparent  ;
}
.gsf-badge-disable {
  color: var(--opTitleColor);
  background-color: var(--badgeDisableColor);
}
.gsf-selected-badge {
  color: var(--opTitleColor);
  background-color: var(--badgeColor);
}
.gsf-form input, .gsf-form textarea, .gsf-form select {
  background: var(--formFieldBGPercentage);
}
.gsf-cross-icon {
  fill: var(--closeIconColor);
}
.gsf-groweo-icon {
  fill: var(--groweoIconColor);
}
.gsf-groweo-font {
  fill: var(--groweoFontColor);
}
.gsf-powered-text {
  margin-right: 4px;
  color: var(--groweoFontColor);
}
.gsf-return-arrow {
  stroke: var(--navigationBtnColor);
}
.gsf-next-arrow {
  fill: var(--submitColor);
}
.gsf-slick-arr {
  fill: var(--navigationBtnColor);
}
.gsf-form {
  padding: var(--formWrapPadding) !important;
}
.gsf-form-wrapper {
  color: var(--contentColor);
  font-size: var(--contentFontSize);
  font-weight: var(--contentFontWeight);
  top: var(--contentTop);
}
.gsf-form-label {
  color: var(--formLabelColor);
  font-size: var(--formLabelFontSize);
  font-weight: var(--formLabelFontWeight);
  line-height: var(--formLabelLineHeight);
}
.gsf-form input::placeholder, .gsf-form textarea::placeholder {
  color: var(--formLabelColor);
}
form.simple-form input::placeholder,
form.simple-form textarea::placeholder {
  color: var(--fieldPlaceholderColor) !important;
}
form.simple-form input::-webkit-calendar-picker-indicator {
  background-image: var(--dateFieldIcon);
}
form.simple-form select {
  background-image: var(--selectFieldIcon) !important;
  appearance: none;
  background-position: calc(100% - 20px) 50%;
  background-repeat: no-repeat;
  background-size: 18px 18px;
}
form.simple-form input,
form.simple-form textarea,
form.simple-form select {
  font-family: inherit !important;
  font-size: var(--formLabelFontSize) !important;
  color: var(--fieldTextColor);
}
.classic-style .gsf-input-wrapper input,
.classic-rounded-style .gsf-input-wrapper input,
.modern-style .gsf-input-wrapper input,
.modern-rounded-style .gsf-input-wrapper input,
.none-style .gsf-input-wrapper input,
.classic-style .gsf-input-wrapper textarea,
.classic-rounded-style .gsf-input-wrapper textarea,
.modern-style .gsf-input-wrapper textarea,
.modern-rounded-style .gsf-input-wrapper textarea,
.none-style .gsf-input-wrapper textarea,
.classic-style .gsf-input-wrapper select,
.classic-rounded-style .gsf-input-wrapper select,
.modern-style .gsf-input-wrapper select,
.modern-rounded-style .gsf-input-wrapper select,
.none-style .gsf-input-wrapper select {
  background-color: var(--fieldBGColor) !important;
}
.gsf-right-navigation {
  background-color: var(--submitBgColor);
  border: var(--buttonBorder);
}
.gsf-right-navigation${isMobile ? '.gsf-mobile' : ':hover'} {
  background-color: var(--submitHoverBgColor);
  border: var(--buttonBorder);
}
.gsf-right-navigation${isMobile ? '.gsf-mobile' : ':hover'} .gsf-next-arrow {
  fill: var(--submitHoverColor);
}
.gsf-submit-btn {
  color: var(--submitColor);
  font-size: var(--submitFontSize);
  background-color: var(--submitBgColor);
  border: var(--buttonBorder);
  padding: var(--submitBtnPadding);
  font-family: inherit !important;
}
.gsf-submit-btn${isMobile ? '.gsf-mobile' : ':hover'} {
  color: var(--submitHoverColor);
  background-color: var(--submitHoverBgColor);
  opacity: 80%;
}
.gsf-popup-close-button {
  background-color: var(--closeBgColor);
  border-radius: var(--closeBorderRadius);
  margin: var(--closeMargin);
}
.gsf-disable-submit {
  background-color: var(--submitDisableColor) !important;
  pointer-events: none;
}
.row {
  display: flex;
  flex-wrap: wrap;
  overflow: auto;
}
.col-md-6 {
  width: 50%;
}

@keyframes pulse {
    0% {
        -webkit-transform: scaleX(1);
        transform: scaleX(1)
    }

    50% {
        -webkit-transform: scale3d(1.05,1.05,1.05);
        transform: scale3d(1.05,1.05,1.05)
    }

    to {
        -webkit-transform: scaleX(1);
        transform: scaleX(1)
    }
}

.animate__pulse {
    -webkit-animation: pulse 1s;
    animation: pulse 1s;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
}
@media only screen and (min-width: 768px) {
  .form-column1 {
    display: flex;
    flex-direction: column;
    justify-content: var(--col1Alignment);
    padding: 0 30px;
    width: var(--formMultiColumn);
  }
  .form-column2 {
    display: flex;
    flex-direction: column;
    justify-content: var(--col2Alignment);
    padding: 0 30px;
    width: var(--formMultiColumn);
  }
}
@media only screen and (max-width: 480px) {
  .gsf-title .gsf-wrap-text {
    padding: 0;
  }
  .gsf-subtitle .gsf-wrap-text {
    padding: 20px;
  }
  .gsf-content .gsf-wrap-text, .gsf-form {
    padding: 10px;
  }
  .gsf-slide-container {
    padding: 20px 0;
    width: auto;
  }
  .gsf-slider-badgewrapper {
    justify-content: start;
  }
}
@media only screen and (max-width: 320px) {
  .gsf-title .gsf-wrap-text {
    padding: 0px;
  }
  .gsf-subtitle .gsf-wrap-text {
    padding: 20px;
  }
  .gsf-content .gsf-wrap-text, .gsf-form {
    padding: 10px;
  }
}
`;
