import styled from "styled-components";
import defaultTheme from "../theme";

const LeftArrowContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  cursor: pointer;
  border-radius: 50%;
  padding: 2px;
  background: ${({ theme }) => theme.userBubbleColor};
  fill: ${({ theme }) => theme.userFontColor};
`;

LeftArrowContainer.defaultProps = {
  theme: defaultTheme
};

export default LeftArrowContainer;
