import React from "react";
import styled, { css } from "styled-components";
import Slider from "react-slick";
import Card from "./Card";
import CardImage from "./CardImage";
import CardBody from "./CardBody";
import CardTitle from "./CardTitle";
import CardText from "./CardText";
import CardLinkContainer from "./CardLinkContainer";
import CardLink from "./CardLink";
import CardComponent from "./CardComponent";
import { isMobile } from "../utils";
import RightArrow from "../icons/RightArrow";
import LeftArrow from "../icons/LeftArrow";
import RightArrowContainer from "./RightArrowContainer";
import LeftArrowContainer from "./LeftArrowContainer";
import CarouselStep from "./CarouselStep";

// const CarouselWrapper = styled.div`
//   display: flex;
//   align-items: center;
//   width: 100%;
//   ${props =>
//     props.currentSlide
//       ? `transform: translateX(calc(-${props.currentSlide} * (${props.cardWidth} + ${props.cardSpacing})));`
//       : ""};
//   transition: all 0.5s ease;
// `;

// const Slide = styled.div`
//   flex: 0 0 auto;
//   opacity: ${props => (props.active ? 1 : 0)};
//   transition: all 0.5s ease;
//   width: 100%;
// `;

// const Slides = styled.div`
//   display: flex;
//   ${props =>
//     props.currentSlide
//       ? `transform: translateX(-${props.currentSlide * 100}%);`
//       : ""};
//   transition: all 0.5s ease;
// `;

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: `${!props.currentSlide && !props.isInfinite ? "none" : "block"}` }}
      onClick={onClick}
    >
      <LeftArrowContainer className="rsc-left-arrow">
        <LeftArrow />
      </LeftArrowContainer>
    </div>
  );
}
function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: `${props.isLast && !props.isInfinite ? "none" : "block"}` }}
      onClick={onClick}
    >
      <RightArrowContainer className="rsc-right-arrow">
        <RightArrow />
      </RightArrowContainer>
    </div>
  );
}

class CarouselComponent extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    this.state = {
      currentSlide: 0,
      carouselWidth: 0
    };
  }

  componentDidMount() {
    this.onPublishStep(this.state.currentSlide);
    setTimeout(() => {
      const el = document.querySelector(".rsc-content");
      if (el.childNodes.length && !this.props.inPage) {
        el.childNodes[el.childNodes.length - 1].scrollIntoView();
      }
    }, 600);
  }

  onMarkAsComplete() {
    // If markAsComplete is true then publish session
    if (this.props.currentElement.data?.markAsComplete) {
      console.log("Mark scussessfull conversation");
      this.props.onLinkClick(this.props?.step?.key, this.props.currentElement.data?.markAsComplete);
    }
  }

  onPublishStep(currentSlide) {
    if (!this.props.step || this.props.isPreview) return;
    const slide = this.props.currentElement.data.slides[currentSlide];
    const name = this.props.currentElement.data?.name || this.props.currentElement.data.text;
    this.props.step.name = name;
    if (slide) slide.name = name;
    this.props.step.value = slide?.data?.imageSource;
    this.props.step.metadata = slide;
    this.props.onPublishStep(this.props.step)
  }

  render() {
    const { currentSlide, carouselWidth } = this.state;
    const { currentElement, fontSizeMultiplier, inPage } = this.props;

    const cw = currentElement.data.cardWidth || "100%";
    const cardWidth = Number(cw) ? cw + "px" : cw;

    const cs = currentElement.data.cardSpacing || "18%";
    const cardSpacing = Number(cs) ? cs + "px" : cs;
    const slidesToShow = inPage ? Number(currentElement.data.slidesToShow) || 1 : 1
    const totalSlide = currentElement.data.slides.length / slidesToShow;
    const isInfinite = isMobile() ? true : currentElement.data["infinite"] !== undefined ? currentElement.data.infinite : true
    const isLastSlide = currentSlide === (Math.ceil(totalSlide) - 1)
    console.log("carousel, cardWidth:", cardWidth, "cardSpacing:", cardSpacing);
    const settings = {
      dots: true,
      infinite: isInfinite,
      arrows: !isMobile(),
      slidesToShow,
      slidesToScroll: slidesToShow,
      speed: 500,
      cssEase: "linear",
      nextArrow: <NextArrow isLast={isLastSlide} isInfinite={isInfinite} />,
      prevArrow: (
        <PrevArrow currentSlide={currentSlide} isInfinite={isInfinite} />
      ),
      customPaging: i => (
        <CarouselStep
          className="rsc-carousel-step"
          isActive={currentSlide === i}
        />
      ),
      beforeChange: (current, next) =>
        this.setState({ currentSlide: Math.ceil(next / slidesToShow) })
    };

    // const onRightClick = () => {
    //   this.onMarkAsComplete();
    //   if (currentSlide < currentElement.data.slides.length - 1) {
    //     this.setState(state => ({ currentSlide: state.currentSlide + 1 }));
    //   }
    // };

    // const onLeftClick = () => {
    //   if (currentSlide > 0) {
    //     this.setState(state => ({ currentSlide: state.currentSlide - 1 }));
    //   }
    // };

    // const showLeftButton = () => {
    //   return currentSlide > 0;
    // };

    // const showRightButton = () => {
    //   if (Number(cw) && Number(cs)) {
    //     const visibleCardsWidth = (currentElement.data.slides.length - currentSlide) * (Number(cw) + Number(cs));
    //     return visibleCardsWidth > carouselWidth;
    //   }
    //   return currentSlide < currentElement.data.slides.length - 1;
    // };

    const onLinkClick = link => {
      if (!this.props.step || this.props.isPreview) return;
      this.onMarkAsComplete();
      const { data } = this.props.currentElement;
      const name = data.name || data.text;
      this.props.step.name = name;
      data.name = name;
      this.props.step.value = link && this.props.currentElement.type === 'linkk' ? `${link} is clicked` : data?.imageSource;
      this.props.step.metadata = data;
      this.props.step.value = link;
      if(!this.props.step.component) return;
      this.props.onFirstClick();
      this.props.onLinkClick(this.props?.step?.key, true)
      this.props.onTrackClick({
        id: `${this.props?.step?.id}-click-${link}`,
        link,
      })
    };

    return (
      <div style={{ width: "100%", position: "relative", marginTop: "35px" }}>
        {/* <div style={{ display: "flex", justifyContent: "center" }}>
          {[...Array(currentElement?.data?.slides?.length || 0)].map(
            (_n, i) => {
              return (
                <CarouselStep
                  className="rsc-carousel-step"
                  isActive={currentSlide === i}
                  onClick={() => this.setState(() => ({ currentSlide: i }))}
                />
              );
            }
          )}
        </div>
        <CarouselWrapper
          currentSlide={currentSlide}
          cardWidth={isMobile() ? `100%` : cardWidth}
          cardSpacing={cardSpacing}
          ref={ref => {
            if (ref && this.state.carouselWidth === 0) {
              this.setState({
                carouselWidth: ref.offsetWidth
              });
            }
          }}
        > */}
        <Card
        className="rsc-card-container"
        style={{ padding: 0 }}
        >
        <Slider {...settings}>
          {currentElement.data.slides.length && currentElement.data.slides.map((slide, index) => {
            return (
              <CardComponent
                // cardWidth={currentElement.data.cardWidth}
                // cardSpacing={cardSpacing}
                // onRightClick={onRightClick}
                // onLeftClick={onLeftClick}
                fontSizeMultiplier  ={fontSizeMultiplier}
                currentElement={slide}
                index={index}
                onLinkClick={onLinkClick}
              ></CardComponent>
            );
          })}
        </Slider>
        {/* </CarouselWrapper>
        <RightArrowContainer
          style={{ visibility: showRightButton() ? "visible" : "hidden" }}
          className="rsc-right-arrow"
          onClick={onRightClick}
        >
          <RightArrow />
        </RightArrowContainer>
        <LeftArrowContainer
          style={{ visibility: showLeftButton() ? "visible" : "hidden" }}
          className="rsc-left-arrow"
          onClick={onLeftClick}
        >d
        className="rsc-card-container"
        >.data?.slides?.length && (
          <div style={{ fontSize: 12, textAlign: "right" }}>
            Slide {currentSlide + 1}/{currentElement.data.slides.length - 1}
          </div>
        )} */}
        </Card>
      </div>
    );
  }
}

export default CarouselComponent;

/* 
const CarouselComponent = ({ currentElement }) => {
  return (
    <div id={`carousel-${currentElement.id}`} className="carousel slide" data-bs-touch="false" data-bs-interval="false">
      <div className="carousel-inner">
        {currentElement.data.slides.map((slide, index) => {
          return (
            <Card className={`carousel-item rsc-card-container ${index === 0 ? "active" : ""}`}>
              {slide.data.imageSource && <CardImage src={slide.data.imageSource} className="rsc-card-img-top" alt="Card"/>}
              <br />
              <br />
              <CardBody className="rsc-card-body">
              <CardTitle className="rsc-card-title">{slide.data.cardTitle}</CardTitle>
               
              <CardText className="rsc-card-text">{slide.data.text}</CardText>
                {slide.data.links && (
                  <CardLinkContainer className="rsc-card-link-container">
                    {slide.data.links.map((link) => 
                      <CardLink target="_blank" href={link.destination} className="rsc-card-link">{link.text}</CardLink>)}
                  </CardLinkContainer>
                )}
              </CardBody>
            </Card>)
        })}
      </div>
      <button
        className="carousel-control-prev"
        type="button"
        data-bs-target={`#carousel-${currentElement.id}`}
        data-bs-slide="prev"
      >
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button
        className="carousel-control-next"
        type="button"
        data-bs-target={`#carousel-${currentElement.id}`}
        data-bs-slide="next"
      >
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
      </button>
    </div>
  )
}

export default CarouselComponent; */
