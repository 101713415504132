import styled from 'styled-components';
import defaultTheme from '../theme';

const CardTitle = styled.h4`
margin: 10px 0px 0px !important;
color: ${props => props.theme.botFontColor};
font-size: ${({ fontSizeMultiplier }) => fontSizeMultiplier*22 + 'px' };
line-height: ${({ fontSizeMultiplier }) => fontSizeMultiplier*30 + 'px' };
`;

CardTitle.defaultProps = {
    theme: defaultTheme
  };
export default CardTitle;