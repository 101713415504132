/* eslint-disable no-nested-ternary */
import styled from 'styled-components';
import defaultTheme from '../theme';

const BarButton = styled.a`
    width: 54px; 
    height: 54px; 
    background: ${({ theme }) => theme.iconBgColor ? theme.iconBgColor.startsWith('http') ? `${theme.imageBgColor} url(${theme.iconBgColor}) no-repeat center center/cover` : theme.iconBgColor : `${theme.imageBgColor} url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40px' fill='white' height='40px' viewBox='0 0 24 24' version='1.1'%3E%3Cg stroke='none' stroke-width='1' %3E%3Crect x='4' y='11' width='16' height='2' rx='1'/%3E%3Crect opacity='0.3' transform='translate(12.000000, 12.000000) rotate(-270.000000) translate(-12.000000, -12.000000) ' x='4' y='11' width='16' height='2' rx='1'/%3E%3C/g%3E%3C/svg%3E") no-repeat center`};
    ${({ theme }) => theme?.iconBgType === 'color' ? `fill: ${theme.iconColor};` : '' }
    position: fixed;
    ${({ theme }) => theme?.iconImagePosition === 'middle' ? 
    `top: 50%;
    transform: translate(0,-50%);` : 'bottom: 0;' }
    ${({ theme }) => theme.direction}: 0;
    display: ${({ opened }) => opened ? 'none' : 'flex'};
    align-items: center;
    border-radius: 5px 5px 5px 5px;
    ${({ theme }) => theme?.iconBgType !== 'image' ? 'box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 4px, rgba(0, 0, 0, 0.2) 0px 2px 12px;' : '' }
    justify-content: center;
    ${({ theme }) => `margin-${theme.direction}`}: 10px;
    margin-bottom: 10px;
    transition: transform 0.3s ease;
`;

BarButton.defaultProps = {
  theme: defaultTheme
};
export default BarButton;
