import styled from "styled-components";
import defaultTheme from "../theme";

const SubTitle = styled.div`
  text-align: center;
  margin: 0;
  word-break: break-word;
  position: absolute;
  width: 100%;
  top: ${({ slideData }) => `calc(20px + ${slideData?.subtitleTop}%)`};
`;

SubTitle.defaultProps = {
  theme: defaultTheme
};

export default SubTitle;
