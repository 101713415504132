import styled from 'styled-components';


const CardText = styled.p`
color: ${props => props.theme.botFontColor};
font-size: ${({ fontSizeMultiplier }) => fontSizeMultiplier*14 + 'px' };
line-height: ${({ fontSizeMultiplier }) => fontSizeMultiplier*20 + 'px' };
margin: 0.5rem 0 0 !important;
display: block;
`;

export default CardText;