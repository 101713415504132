/* eslint-disable react/no-unused-state */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React, { Component } from "react";
import Title from "../components/Title";
import SubTitle from "../components/SubTitle";
import Content from "../components/Content";
import WrapText from "../components/WrapText";
import { flexGrowContent } from "../utils";

class Intro extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onContentEdit = (key, value) => {
    if (this.props.isGrid) this.props.setSlideContent(key, value);
  };

  render() {
    const content = this.props.data?.data?.content;
    const { id } = this.props.data;
    const { isMobile } = this.props;

    return (
      <div className="gsf-container" style={isMobile ? flexGrowContent : {}}>
        <div className={`gsf-title gsf-title-${id}`}>
          <div
            className="gsf-wrap-text"
            dangerouslySetInnerHTML={{ __html: content?.title }}
          />
        </div>
        <div className={`gsf-subtitle gsf-subtitle-${id}`}>
          <div
            className="gsf-wrap-text"
            dangerouslySetInnerHTML={{ __html: content?.paragraph1 }}
          />
        </div>
        <div
          className={`gsf-content gsf-content-${id}`}
          style={!isMobile ? flexGrowContent : {}}
        >
          <div
            className="gsf-wrap-text"
            dangerouslySetInnerHTML={{ __html: content?.paragraph2 }}
          ></div>
        </div>
      </div>
    );
  }
}
export default Intro;
