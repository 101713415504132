import styled from 'styled-components';

const TextStepContainer = styled.div`
  align-items: ${props => (props.user ? 'flex-end' : 'flex-start')};
  display: flex;
  flex-direction: ${props => (props.user ? 'column-reverse' : 'column')};
  justify-content: ${props => (props.user ? 'flex-end' : 'flex-start')};
  font-size: ${({ fontSizeMultiplier }) => fontSizeMultiplier*16 + 'px' };
  line-height: ${({ fontSizeMultiplier }) => fontSizeMultiplier*16 + 'px' };
`;

export default TextStepContainer;
