/* eslint-disable no-nested-ternary */
import styled from 'styled-components';
import defaultTheme from '../theme';

const BarContent = styled.div`
  background: ${({ theme, opened }) =>
    theme.invertBarColor
      ? opened
        ? theme.barFontColor
        : theme.barBgColor
      : theme.barBgColor};
  writing-mode: tb-rl;
  height: auto;
  transform: rotate(-180deg);
  text-align: center;
  cursor: pointer;
  width: auto;
  padding: 20px 0px;
  text-orientation: sideways;
`;

BarContent.defaultProps = {
  theme: defaultTheme
};

export default BarContent;
