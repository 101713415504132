import React from "react";
import defaultTheme from "../theme";

const FIPolicyTextIcon = (props) => {
  return (
    <svg
      id="Layer_2"
      data-name="Layer 2"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 153.01 8.74"
      width="200"
      height="30"
    >
      <g id="Layer_1-2" data-name="Layer 1" stroke="none" fill={props.color}>
        <g>
          <path d="m.94,3.87v3h-.94V.35h.94v2.95L4.01.35h1.23L1.83,3.56l3.5,3.31h-1.28L.94,3.87Z" />
          <path d="m6.15,0h.83v6.87h-.83V0Z" />
          <path d="m9.25.83c0,.32-.23.55-.55.55s-.55-.23-.55-.55.23-.55.55-.55.55.23.55.55Zm-.96,1.44h.83v4.59h-.83V2.28Z" />
          <path d="m11.24,6.87h-.83V0h.83v4.28l2.06-2h1.09l-2.36,2.25,2.45,2.35h-1.14l-2.11-2.05v2.05Z" />
          <path d="m16.1,6.87h-.83V0h.83v4.28l2.06-2h1.09l-2.36,2.25,2.45,2.35h-1.14l-2.11-2.05v2.05Z" />
          <path d="m23.63,6.87l-.03-.73h-.02c-.34.51-.97.82-1.78.82-1.34,0-2.3-1-2.3-2.4s.96-2.4,2.3-2.4c.8,0,1.43.31,1.78.83h.02l.03-.74h.71v4.59h-.71Zm-.1-2.3c0-.97-.66-1.65-1.59-1.65s-1.59.68-1.59,1.65.65,1.65,1.59,1.65,1.59-.68,1.59-1.65Z" />
          <path d="m29.46,6.87l-.03-.73h-.02c-.34.51-.97.82-1.78.82-1.34,0-2.3-1-2.3-2.4s.96-2.4,2.3-2.4c.8,0,1.43.31,1.78.83h.02l.03-.74h.71v4.59h-.71Zm-.1-2.3c0-.97-.66-1.65-1.59-1.65s-1.59.68-1.59,1.65.65,1.65,1.59,1.65,1.59-.68,1.59-1.65Z" />
          <path d="m38.63,4.17v2.7h-.83v-2.61c0-.87-.39-1.34-1.12-1.34-.79,0-1.21.54-1.21,1.56v2.39h-.83v-2.61c0-.87-.38-1.34-1.12-1.34-.8,0-1.22.54-1.22,1.51v2.43h-.83V2.28h.7l.03.67h.02c.28-.49.79-.77,1.51-.77.69,0,1.21.3,1.48.88h.02c.28-.59.81-.88,1.66-.88,1.12,0,1.75.72,1.75,1.99Z" />
          <path d="m43.63,6.87l-.03-.73h-.02c-.34.51-.97.82-1.78.82-1.34,0-2.3-1-2.3-2.4s.96-2.4,2.3-2.4c.8,0,1.43.31,1.78.83h.02l.03-.74h.71v4.59h-.71Zm-.1-2.3c0-.97-.66-1.65-1.59-1.65s-1.59.68-1.59,1.65.65,1.65,1.59,1.65,1.59-.68,1.59-1.65Z" />
          <path d="m45.63,0h.83v6.87h-.83V0Z" />
          <path d="m47.76,0h.83v6.87h-.83V0Z" />
          <path d="m53.72,6.87l-.03-.73h-.02c-.34.51-.97.82-1.78.82-1.34,0-2.3-1-2.3-2.4s.96-2.4,2.3-2.4c.8,0,1.43.31,1.78.83h.02l.03-.74h.71v4.59h-.71Zm-.1-2.3c0-.97-.66-1.65-1.59-1.65s-1.59.68-1.59,1.65.65,1.65,1.59,1.65,1.59-.68,1.59-1.65Z" />
          <path d="m62.16,4.2v2.67h-.83v-2.57c0-.86-.47-1.37-1.25-1.37-.85,0-1.36.59-1.36,1.56v2.39h-.83V0h.83v2.85h.02c.32-.43.86-.67,1.54-.67,1.17,0,1.88.77,1.88,2.02Z" />
          <path d="m63.72,8.74l.93-2.03-1.92-4.43h.9l1.46,3.55h.02l1.47-3.55h.86l-2.87,6.46h-.86Z" />
          <path d="m69.55,6.87l-1.93-4.59h.9l1.46,3.72h.02l1.47-3.72h.86l-1.93,4.59h-.85Z" />
          <path d="m76.73,6.87l-.03-.73h-.02c-.34.51-.97.82-1.78.82-1.34,0-2.3-1-2.3-2.4s.96-2.4,2.3-2.4c.81,0,1.43.31,1.78.83h.02l.03-.74h.71v4.59h-.71Zm-.1-2.3c0-.97-.66-1.65-1.59-1.65s-1.59.68-1.59,1.65.65,1.65,1.59,1.65,1.59-.68,1.59-1.65Zm-2.05-3.74c0,.3-.22.52-.53.52-.29,0-.51-.22-.51-.52s.22-.51.51-.51c.31,0,.53.21.53.51Zm1.92,0c0,.3-.22.52-.51.52-.31,0-.53-.22-.53-.52s.22-.51.53-.51c.29,0,.51.21.51.51Z" />
          <path d="m79.57,6.87h-.83V0h.83v4.28l2.06-2h1.09l-2.36,2.25,2.45,2.35h-1.14l-2.11-2.05v2.05Z" />
          <path d="m86.56,2.53v.88h-.04c-.31-.32-.8-.49-1.44-.49-.59,0-.97.27-.97.69,0,.33.25.49,1.01.53,1.26.07,1.72.49,1.72,1.39s-.7,1.43-1.82,1.43c-.77,0-1.36-.17-1.75-.48v-.9h.04c.37.42.94.64,1.68.64.63,0,1.02-.27,1.02-.69,0-.37-.26-.55-1.08-.6-1.21-.08-1.65-.46-1.65-1.3s.71-1.46,1.78-1.46c.61,0,1.16.13,1.5.35Z" />
          <path d="m88.19,8.74l.93-2.03-1.92-4.43h.9l1.46,3.55h.02l1.47-3.55h.86l-2.87,6.46h-.86Z" />
          <path d="m95.29,6.8c-.21.11-.48.17-.83.17-.96,0-1.5-.57-1.5-1.6v-2.37h-.84v-.73h.86l.13-1.25h.69v1.25h1.48v.73h-1.48v2.35c0,.55.29.87.79.87.26,0,.49-.07.68-.27h.04v.84Z" />
          <path d="m99.28,6.87h-.83V0h.83v4.28l2.06-2h1.09l-2.36,2.25,2.45,2.35h-1.14l-2.11-2.05v2.05Z" />
          <path d="m106.81,6.87l-.03-.73h-.02c-.34.51-.97.82-1.78.82-1.34,0-2.3-1-2.3-2.4s.96-2.4,2.3-2.4c.8,0,1.43.31,1.78.83h.02l.03-.74h.71v4.59h-.71Zm-.1-2.3c0-.97-.66-1.65-1.59-1.65s-1.59.68-1.59,1.65.65,1.65,1.59,1.65,1.59-.68,1.59-1.65Zm-2.05-3.74c0,.3-.22.52-.53.52-.29,0-.51-.22-.51-.52s.22-.51.51-.51c.31,0,.53.21.53.51Zm1.92,0c0,.3-.22.52-.51.52-.31,0-.53-.22-.53-.52s.22-.51.53-.51c.29,0,.51.21.51.51Z" />
          <path d="m109.23,8.74l.93-2.03-1.92-4.43h.9l1.46,3.55h.02l1.47-3.55h.86l-2.87,6.46h-.86Z" />
          <path d="m116.34,6.8c-.21.11-.48.17-.83.17-.96,0-1.5-.57-1.5-1.6v-2.37h-.84v-.73h.86l.13-1.25h.69v1.25h1.48v.73h-1.48v2.35c0,.55.29.87.79.87.26,0,.49-.07.68-.27h.04v.84Z" />
          <path d="m119.53,6.8c-.21.11-.48.17-.83.17-.96,0-1.5-.57-1.5-1.6v-2.37h-.84v-.73h.86l.13-1.25h.69v1.25h1.48v.73h-1.48v2.35c0,.55.29.87.79.87.26,0,.49-.07.68-.27h.04v.84Z" />
          <path d="m125.04,4.57c0,1.4-1.01,2.4-2.43,2.4s-2.43-.99-2.43-2.4,1.01-2.4,2.43-2.4,2.43.99,2.43,2.4Zm-4.02,0c0,.97.65,1.65,1.59,1.65s1.59-.68,1.59-1.65-.66-1.65-1.59-1.65-1.59.68-1.59,1.65Zm1.15-3.74c0,.3-.22.52-.53.52-.29,0-.51-.22-.51-.52s.22-.51.51-.51c.31,0,.53.21.53.51Zm1.92,0c0,.3-.22.52-.51.52-.31,0-.53-.22-.53-.52s.22-.51.53-.51c.29,0,.51.21.51.51Z" />
          <path d="m126.6,4.85c.12.85.8,1.37,1.85,1.37.68,0,1.27-.22,1.7-.63h.04v.84c-.41.33-1.04.53-1.76.53-1.62,0-2.67-.95-2.67-2.4s.99-2.4,2.4-2.4,2.33.87,2.33,2.21v.46h-3.88Zm0-.7h3.1c-.12-.75-.71-1.23-1.54-1.23-.79,0-1.4.49-1.56,1.23Z" />
          <path d="m135.74,4.2v2.67h-.83v-2.57c0-.86-.47-1.37-1.25-1.37-.85,0-1.36.59-1.36,1.56v2.39h-.83V0h.83v2.85h.02c.32-.43.86-.67,1.54-.67,1.17,0,1.88.77,1.88,2.02Z" />
          <path d="m140.75,6.87l-.03-.73h-.02c-.34.51-.97.82-1.78.82-1.34,0-2.3-1-2.3-2.4s.96-2.4,2.3-2.4c.75,0,1.32.26,1.68.7h.02V0h.83v6.87h-.71Zm-.1-2.3c0-.97-.66-1.65-1.59-1.65s-1.59.68-1.59,1.65.65,1.65,1.59,1.65,1.59-.68,1.59-1.65Z" />
          <path d="m147.33,4.57c0,1.4-1.01,2.4-2.43,2.4s-2.43-.99-2.43-2.4,1.01-2.4,2.43-2.4,2.43.99,2.43,2.4Zm-4.02,0c0,.97.65,1.65,1.59,1.65s1.59-.68,1.59-1.65-.66-1.65-1.59-1.65-1.59.68-1.59,1.65Z" />
          <path d="m150.87,6.8c-.21.11-.48.17-.83.17-.96,0-1.5-.57-1.5-1.6v-2.37h-.84v-.73h.86l.13-1.25h.69v1.25h1.48v.73h-1.48v2.35c0,.55.29.87.79.87.26,0,.49-.07.68-.27h.04v.84Z" />
          <path d="m153.01,6.4c0,.32-.24.57-.56.57s-.57-.25-.57-.57.25-.57.57-.57.56.25.56.57Z" />
          <path d="m97.8,8.13v-.51h53.42v.51h-53.42Z" />
        </g>
      </g>
    </svg>
  );
};

FIPolicyTextIcon.defaultProps = {
  theme: defaultTheme,
};
export default FIPolicyTextIcon;
