/* eslint-disable react/prop-types */
import React, { Component } from "react";
import styled from "styled-components";
import axios from "axios";
import { SliderBuilderContainer } from "./components";
import Intro from "./custom_components/Intro";
import Form from "./custom_components/Form";
import PolicyText from "./components/PolicyText";
import OptionsV2 from "./custom_components/OptionsV2";
import SubmitButton from "./custom_components/SubmitButton";
import ENPolicyTextIcon from "./icons/ENPolicyTextIcon";
import FIPolicyTextIcon from "./icons/FIPolicyTextIcon";
import GroweoIcon from "./icons/GroweoIcon";
import SlideWrapper from "./components/SlideWrapper";
import { prepareEndData, setGlobalItem, getGlobalItem } from "./utils";
import conf from "./conf/conf";
import PopupContainer from "./components/PopupContainer";
import PopupMain from "./components/PopupMain";
import PopupHeader from "./components/PopupHeader";
import CloseIcon from "./icons/CloseIcon";
import CloseButton from "./components/CloseButton";
import OptionsV2Slick from "./custom_components/OptionsV2Slick";
import End from "./custom_components/End";

const PolicyTextWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  font-size: 10px;
  width: 150px;
  position: absolute;
  bottom: 0px;
  right: 20px;
`;

let activeOptions = [];
let canShow = false;
let isSessionStart = false;
let startSessionTime = "";
let timeDelay = 0;
class SlideBuilder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentStep: 0,
      preStep: [],
      isSubmitResponse: false,
      collectedSteps: [],
      opened: false
    };
  }

  componentDidMount() {
    const { inPage, isEditor } = this.props;
    const { popup, delay, repeatedType } = this.props.appearance;
    if (!inPage) {
      if (popup === "click") this.sendPostMessage({ type: "smartflow-onClick" });
      this.sendPostMessage({ type: "smartflow-iframe-size", height: "0px" });
      this.sendPostMessage({ type: "smartflow-check-session", repeatedType });
    }
    window.addEventListener("message", this.handlePostMessage);
    if (inPage && !isEditor) {
      window.addEventListener("resize", this.handleResize);
      window.addEventListener("DOMNodeInserted", this.handleResize);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("message", this.handlePostMessage);
  }

  handlePostMessage = ev => {
    if (ev.data.type === "smartflow-open-modal") {
      this.setOpened(true);
    }
    if (ev.data.type === "set-smartflow-session") {
      canShow = ev.data.canShow;
      const { delay, popup, repeatedType } = this.props?.appearance;
      const delaySeconds = Number(delay * 1000);
      if (popup === "load" && canShow) {
        const intervalId = setInterval(() => {
          const isGroweoSession = getGlobalItem('groweoSession');
          if (!isGroweoSession || this.props.isPreview) {
            this.setOpened(true);
            this.sendPostMessage({
              type: "smartflow-check-session",
              key: "shown",
              value: null,
              repeatedType
            });
            this.sendPostMessage({
              type: "smartflow-check-session",
              key: "submitted",
              value: null
            });
            clearInterval(intervalId);
          }
        }, ev.data.stopDelay ? 0 : delaySeconds);
      }
      if (popup === "scroll" && canShow) {
        if (delay) {
          this.sendPostMessage({
            type: "smartflow-onScroll",
            delay,
            repeatedType
          });
        }
      }
    }
    if (ev.data.type === 'open-smartflow-modal-onscroll') {
      const { repeatedType } = this.props?.appearance;
      const isGroweoSession = JSON.parse(window.sessionStorage.getItem('groweoSession'));
      if (!isGroweoSession || this.props.isPreview) {
        this.setOpened(true);
        this.sendPostMessage({type: 'smartflow-check-session', key: 'shown', value: null, repeatedType, popup: 'scroll'}) 
        this.sendPostMessage({type: 'smartflow-check-session', key: 'submitted', value: null})
      }
    }
  };

  handleResize = () => {
    const { inPage, isMobile, isPreview } = this.props;
    const { mobileZoom } = this.props?.appearance?.style;
    if (inPage) {
      let height = document.querySelector('.gsf-slide-wrapper')?.offsetHeight;
      if (!height) return;
      const zoom = Number(mobileZoom || 100)/100;
      if (isMobile && !isPreview) height = Math.ceil(height*zoom)
      this.sendPostMessage({
        type: 'smartflow-resize',
        height: `${height}px`
      });
    }
  }

  sendPostMessage = data => {
    const { isPreview, inPage, moduleId } = this.props;
    const id = `${moduleId}-${this.props.workspaceId}-smartflow-${
      inPage ? "inpage" : "popup"
    }`;
    window.top && window.top.postMessage({ id, ...data, isPreview, inPage, moduleId }, "*");
  };

  openPopup = () => {
    this.sendPostMessage({
      type: "open-smartflow-popup",
    });
  };

  closePopup = () => {
    this.sendPostMessage({
      type: "close-smartflow-popup",
    });
    if (this.props.inPage) {
      window.addEventListener("resize", this.handleResize);
      window.addEventListener("DOMNodeInserted", this.handleResize);
    }
  };

  setOpened = opened => {
    this.setState({ opened });
    if (opened) this.openPopup();
    else this.closePopup();
    if (!this.props.isPreview) setGlobalItem("groweoSession", opened);
  };

  render() {
    const { steps, appearance, isPreview, inPage, isEditor, isMobile, showPopup, setShowPopup, isSession, details } = this.props;
    const {
      currentStep,
      preStep,
      opened
    } = this.state;
    const slide = steps[currentStep];
    const content = slide?.currentSlide?.data?.content;
    const slideStyle = slide?.currentSlide?.data?.style;
    const checkTrigger = content?.options?.some(opt => opt?.target);

    const setActiveOptions = selectedOption => {
      activeOptions = selectedOption;
    };

    const preBtn = () => {
      if (!currentStep) return;
      this.setState({ currentStep: preStep.pop() });
      this.setState({ preStep: [...preStep] });
    };

    const nextSilde = () => {
      const findIndex = steps.findIndex(stp => stp.id === slide?.trigger);
      if (findIndex !== -1) {
        this.setState({ preStep: [...preStep, currentStep] });
        this.setState({ currentStep: findIndex }, () => {
          if (inPage && isMobile && !opened) convertInpageToPopup()
          if (steps[findIndex].type === "end") {
            timeDelay = steps[findIndex].currentSlide?.data?.content?.timeDelay;
            handleEnd();
          }
        });
      }
      setActiveOptions([]);
    };

    const currentIsOptSilde = () => {
      const option = slide?.currentSlide?.data.content.options;
      const matchTarget = activeOptions.every(
        opt => option[activeOptions[0]]?.target === option[opt]?.target
      );
      const findIndex = steps?.findIndex(
        stp => stp.id === option[activeOptions[0]]?.target
      );
      if (matchTarget && findIndex !== -1) {
        this.setState({ preStep: [...preStep, currentStep] });
        this.setState({ currentStep: findIndex }, () => {
          if (inPage && isMobile && !opened) convertInpageToPopup()
          if (steps[findIndex].type === "end") {
            timeDelay = steps[findIndex].currentSlide?.data?.content?.timeDelay;
            handleEnd();
          }
        });
        activeOptions = [];
      } else nextSilde();
    };
    const onCollectSteps = step => {
      if (isPreview || this.state.collectedSteps.indexOf(step.id) !== -1)
        return;
      this.setState({
        collectedSteps: [...this.state.collectedSteps, step.id]
      });
      const collectStep = {
        id: step.id,
      };
      const { trackingId, moduleId, workspaceId, baseUrl, type } = this.props;
      if (!workspaceId || !trackingId || !moduleId || !baseUrl) return;
      Object.assign(collectStep, {
        trackingId,
        moduleId,
        workspaceId,
        type
      })
      let url = "https://2tn2gr0q94.execute-api.eu-north-1.amazonaws.com";
      if (baseUrl.search("localhost") === -1) url = baseUrl.split("/api")[0];
      try {
        axios.post(`${url}/collectSteps`, collectStep);
      } catch (err) {
        console.error("error sending start-session request:", err);
      }
    };

    const onSaveOptionValue = () => {
      const options = slide?.currentSlide?.data.content.options;
      if (!options && !options.length) return;
      const selected = options
        .filter((data, index) => activeOptions.includes(index))
        .map(({ title, subTitle, text }) => `${title} ${subTitle}` || text);
      if (selected && selected.length) slide.currentSlide.value = selected;
    };

    const checkNextSilde = () => {
      if (slide?.trigger || slide?.type === "options")
        if (slide?.type === "options") {
          if (
            content.minOption &&
            activeOptions.length < Number(content.minOption)
          )
            return;
          onSaveOptionValue();
          currentIsOptSilde();
        } else nextSilde();
      onCollectSteps(slide);
      if (slide?.currentSlide?.data?.start) createActivity("START-SESSION", {});
      if (slide?.currentSlide?.data?.end && !this.state.isSubmitResponse)
        handleEnd();
      if (slide?.currentSlide?.data?.end)
        this.setState({
          collectedSteps: [],
        });
    };

    const resetProps = () => {
      steps?.map(step => delete step?.currentSlide?.value);
      activeOptions = [];
      canShow = false;
      isSessionStart = false;
      startSessionTime = "";
      this.setState({
        currentStep: 0,
        preStep: [],
        isSubmitResponse: false,
        opened: false
      });
    }

    const handleEnd = () => {
      if (isPreview) {
        setTimeout(() => {
          if (!this.props?.inPage || opened) this.setOpened(false);
          resetProps();
        }, (timeDelay * 1000) || 0);
        return;
      }
      if (!isSessionStart) return;
      const endData = prepareEndData(steps);
      const {
        moduleId,
        workspaceId,
        publishUrl,
        trackingId,
        language
      } = this.props;
      this.sendPostMessage({
        type: "send-response-smartflow",
        publishUrl,
        objData: {
          trackingId,
          workspaceId,
          moduleId,
          data: endData
        },
        language
      });
      this.setState({ isSubmitResponse: true });
      const duration = Math.round(
        Math.abs(new Date() - startSessionTime) / 1000
      );
      createActivity("SUCCESSFULL-CONVERSATION", {});
      createActivity("END-SESSION", { duration });
      if (endData.email || endData.phone) {
        const groweoAppName = this.props?.appName;
        pushGtmData({
          event: conf.FLOW_LEAD_EVENT_NAME,
          eventProps: {
            category: conf.GTM_CATEGORY,
            action: conf.FLOW_LEAD_EVENT_NAME,
            label: groweoAppName
          }
        });
      }
      setTimeout(() => {
        if (!this.props?.inPage || opened) this.setOpened(false);
        resetProps();
      }, (timeDelay * 1000) || 0);
    };

    const postActivity = (activity, objData) => {
      const activityData = {
        type: "smartflow-activity",
        activityObj: {
          type: this.props?.type,
          workspaceId: this.props.workspaceId,
          activity,
          moduleId: this.props.moduleId,
          ...objData
        },
        baseUrl: this.props.baseUrl
      };
      this.sendPostMessage(activityData);
    };

    const createActivity = (activity, objData) => {
      if (isPreview || (activity === "START-SESSION" && isSessionStart)) return;
      if (activity === "START-SESSION") {
        isSessionStart = true;
        startSessionTime = new Date();
      }
      try {
        const groweoAppName = this.props?.appName;
        if (activity === "START-SESSION")
          pushGtmData({
            event: conf.FLOW_START_EVENT_NAME,
            eventProps: {
              category: conf.GTM_CATEGORY,
              action: conf.FLOW_START_EVENT_NAME,
              label: groweoAppName
            }
          });
        else if (activity === "END-SESSION")
          pushGtmData({
            event: conf.FLOW_END_EVENT_NAME,
            eventProps: {
              category: conf.GTM_CATEGORY,
              action: conf.FLOW_END_EVENT_NAME,
              label: groweoAppName
            }
          });
        postActivity(activity, objData);
      } catch (err) {
        console.error("error sending end or success activity request:", err);
      }
    };

    const pushGtmData = eventData => {
      this.sendPostMessage({
        type: "smartflow-tagEvent",
        eventData
      });
    };

    const onSessionNext = () => {
      if (slide?.type === "options" && activeOptions.length)
        currentIsOptSilde();
      else nextSilde();
    };

    const SlideContainer = () => (
      <div className="gsf-root" onLoad={inPage && isMobile && !isEditor && !opened && this.handleResize} style={{
        '--wrapperBgColor': slideStyle?.wrapperBgColor || appearance.style?.wrapperBgColor,
        '--wrapperBgImage': isMobile ? `url(${slideStyle?.mobileWrapperBgImage || appearance.style?.mobileWrapperBgImage})` : `url(${slideStyle?.wrapperBgImage || appearance.style?.wrapperBgImage})`,
        '--mobileWrapperBgImage': `url(${slideStyle?.mobileWrapperBgImage || appearance.style?.mobileWrapperBgImage})`,
      }} >
        <div className="gsf-slide-wrapper animate__fadeIn">
          <div className="gsf-slide-container">
            {slide?.type === "text" && (
              <Intro
                data={slide?.currentSlide}
                preBtn={preBtn}
                nexBtn={checkNextSilde}
                appearance={appearance}
                isGrid={this.props.isGrid || false}
                setSlideContent={this.props.setSlideContent}
                isPreview={this.props.isPreview}
                isMobile={this.props.isMobile}
              />
            )}
            {slide?.type === "end" && <End data={slide?.currentSlide} />}
            {/* {slide?.type === "options" && (
              <Options
              data={slide?.currentSlide}
              preBtn={preBtn}
              nexBtn={checkNextSilde}
              appearance={appearance}
              />
            )} */}
            {slide?.type === "options" && (
              <OptionsV2Slick
                data={slide?.currentSlide}
                preBtn={preBtn}
                isEditor={this.props?.isEditor}
                nexBtn={checkNextSilde}
                appearance={appearance}
                isGrid={this.props.isGrid || false}
                setSlideContent={this.props.setSlideContent}
                setActiveOptions={setActiveOptions}
                isMobile={this.props.isMobile || false}
                isSession={isSession || false}
                details={details}
                inPage={this.props.inPage}
                isMobilePopup={inPage && isMobile && opened}
              />
            )}
            {slide?.type === "content" && (
              <Form
                data={slide?.currentSlide}
                preBtn={preBtn}
                nexBtn={checkNextSilde}
                appearance={appearance}
                isGrid={this.props.isGrid || false}
                setSlideContent={this.props.setSlideContent}
                moduleId={this.props.moduleId}
                trackingId={this.props.trackingId}
                baseUrl={this.props.baseUrl}
                isEditor={this.props.isEditor}
                isMobile={this.props.isMobile}
                isSession={isSession}
                details={details}
              />
            )}
            {slide?.type !== "end" && (
              <SubmitButton
                slide={slide}
                preBtn={this.props.isEditor ? this.props.preBtnEditor : preBtn}
                nexBtn={
                  this.props.isEditor
                    ? this.props.nxtBtnEditor
                    : !!isSession
                    ? onSessionNext
                    : checkNextSilde
                }
                // nextArrHide={!!slide?.trigger || checkTrigger}
                // currentStep={!currentStep}
                appearance={appearance}
                isGrid={this.props.isGrid || false}
                setSlideContent={this.props.setSlideContent}
                isEditor={this.props.isEditor}
                isSession={isSession || false}
                isSubmitResponse={this.state.isSubmitResponse}
                language={this.props.language}
                isMobile={this.props.isMobile}
                sendPostMessage={this.sendPostMessage}
                inPage={this.props.inPage}
                isMobilePopup={isEditor ? !isEditor : inPage && isMobile && !opened }
              />
            )}
            <div className="gsf-policy">
              <a
                className="gsf-policy-text"
                href="https://www.groweo.com/kayttoehdot/"
                target="_blank"
                rel="noreferrer"
              >
                <FIPolicyTextIcon color={appearance?.style?.groweoFontColor} />
              </a>
              <a
                className="gsf-powered-by"
                href="https://www.groweo.com/kayttoehdot/"
                target="_blank"
                rel="noreferrer"
              >
                <GroweoIcon color={appearance?.style?.groweoIconColor}/>
              </a>
            </div>
          </div>
        </div>
      </div>
    );

    const convertInpageToPopup = () => {;
      window.removeEventListener("resize", this.handleResize);
      window.removeEventListener("DOMNodeInserted", this.handleResize);
      this.setOpened(true);
    };

    return (
      <>
        {inPage && !isMobile || isEditor ? (
          <>
            {this.props?.isEditor ? (
              <div className="gsf-popup-header">
                <div
                  className="gsf-popup-close-button"
                  style={{ pointerEvents: this.props?.isEditor ? "none" : "unset" }}
                  isEditor={this.props.isEditor}
                  onClick={() => {
                    this.setOpened(false);
                    this.sendPostMessage({
                      type: "smartflow-check-session",
                      key: "shown",
                      value: +new Date()
                    });
                  }}
                >
                  <CloseIcon />
                </div>
              </div>
            ) : (
              ""
            )}
            <SlideContainer />
          </>
        ) : inPage && isMobile && !opened ? (
          <SlideContainer />
        ): opened ? (
          <div
            className="gsf-popup-container"
            style={{ display: opened || showPopup ? "flex" : "none", '--mobileMinHeight': '100%', }}
          >
            <div className="gsf-popup-main">
              <div className="gsf-popup-header">
                <div
                  className="gsf-popup-close-button"
                  // style={{ pointerEvents: this.props?.isEditor ? "none" : "unset" }}
                  onClick={() => {
                    resetProps()
                    if (isEditor) {
                      setShowPopup(false)
                      return;
                    }
                    this.setOpened(false);
                    this.sendPostMessage({
                      type: "smartflow-check-session",
                      key: "shown",
                      value: +new Date()
                    });
                  }}
                >
                  <CloseIcon />
                </div>
              </div>
              <SlideContainer />
            </div>
          </div>
        ) : ""}
      </>
    );
  }
}

// SlideBuilder.propTypes = {
//   sliderDelay: PropTypes.number,
//   isPreview: PropTypes.bool,
//   cache: PropTypes.bool,
//   cacheName: PropTypes.string,
//   handleEnd: PropTypes.func,
// };

// SlideBuilder.defaultProps = {
//   sliderDelay: 1000,
//   isPreview: false,
//   cache: false,
//   cacheName: "rsc_cache",
//   handleEnd: undefined,
// };

export default SlideBuilder;
