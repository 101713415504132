import styled from 'styled-components';
import defaultTheme from '../theme';

const SmartBarMobileContainer = styled.div`
  box-shadow: ${({ opened }) =>
    opened ? '0 12px 24px 0 rgba(0, 0, 0, 0.15)' : '' };
  font-family: ${({ theme }) => theme.fontFamily};
  overflow: hidden;
  display: flex;
  height: ${({ height, theme }) => (theme.autoBarHeight ? height : theme.height)};
  width: ${({ theme }) => theme.width};
  ${({ isSession, theme, opened, barWidth  }) => isSession ? 
    `border-radius: 15px 0 0 15px;
    position: relative;` : 
    `position: fixed;
    top: 50%;
    transform: translate(0, -50%);
    ${theme?.direction}: ${opened ? `0` : `calc(-${theme.width})`};
    flex-direction: ${theme?.direction === 'left' ? `row-reverse` : `row`};
    border-radius: ${theme?.direction === 'left' ? '0 15px 15px 0' : '15px 0 0 15px'};`
  };
  z-index: 999;
  transition: ${({ theme }) => `${theme?.direction} 500ms linear`};
  @media screen and (max-width: 480px) {
    width: ${({ opened, theme }) => opened ? '100vw !important' : theme.width};
  };
`;

SmartBarMobileContainer.defaultProps = {
  theme: defaultTheme
};

export default SmartBarMobileContainer;