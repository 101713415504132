import styled from "styled-components";
import defaultTheme from "../theme";

const SlideWrapper = styled.div`
  ${({ theme }) => theme?.inPage ? '' : `
    border-radius: 10px;
    @media screen and (max-width: 480px) {
      height: 100vh;
      border-radius: 0px;
    };
  `}
`;

SlideWrapper.defaultProps = {
  theme: defaultTheme
};

export default SlideWrapper;
