import React from "react";
import defaultTheme from "../theme";

const ENPolicyTextIcon = (props) => {
  return (
    <svg
      id="Layer_2"
      data-name="Layer 2"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 205.8 8.74"
      width="300"
      height="30"
    >
      <g id="Layer_3" data-name="Layer 3" stroke="none" fill={props.color}>
        <g>
          <path d="m3.93,3.57c.74.2,1.13.7,1.13,1.5,0,1.16-.76,1.75-2.2,1.75H0V.44h2.62c1.45,0,2.21.58,2.21,1.72,0,.68-.31,1.15-.9,1.39v.02Zm-1.37-.38c.91,0,1.36-.33,1.36-.97s-.44-.96-1.32-.96H.92v1.93h1.63Zm.28,2.81c.86,0,1.3-.33,1.3-1s-.44-1-1.35-1H.92v2h1.91Z" />
          <path d="m6.48,8.64l.91-1.99-1.88-4.33h.88l1.43,3.48h.02l1.44-3.48h.84l-2.8,6.32h-.84Z" />
          <path d="m16.55,2.67v.89h-.04c-.35-.41-.79-.6-1.35-.6-1.03,0-1.67.65-1.67,1.61s.64,1.61,1.67,1.61c.58,0,1.03-.2,1.35-.6h.04v.9c-.35.29-.84.43-1.47.43-1.46,0-2.42-.98-2.42-2.34s.96-2.34,2.42-2.34c.62,0,1.12.14,1.47.44Z" />
          <path d="m17.64.1h.82v6.72h-.82V.1Z" />
          <path d="m20.66.91c0,.32-.22.54-.54.54s-.54-.22-.54-.54.22-.54.54-.54.54.22.54.54Zm-.94,1.41h.82v4.49h-.82V2.32Z" />
          <path d="m25.39,2.67v.89h-.04c-.35-.41-.79-.6-1.35-.6-1.03,0-1.67.65-1.67,1.61s.64,1.61,1.67,1.61c.58,0,1.03-.2,1.35-.6h.04v.9c-.35.29-.84.43-1.47.43-1.46,0-2.42-.98-2.42-2.34s.96-2.34,2.42-2.34c.62,0,1.12.14,1.47.44Z" />
          <path d="m27.29,6.82h-.82V.1h.82v4.19l2.02-1.96h1.07l-2.3,2.2,2.4,2.29h-1.11l-2.06-2.01v2.01Z" />
          <path d="m32.17.91c0,.32-.22.54-.54.54s-.54-.22-.54-.54.22-.54.54-.54.54.22.54.54Zm-.94,1.41h.82v4.49h-.82V2.32Z" />
          <path d="m37.49,4.21v2.61h-.82v-2.52c0-.84-.46-1.34-1.22-1.34-.84,0-1.33.58-1.33,1.53v2.33h-.82V2.32h.69l.02.71h.02c.31-.51.85-.81,1.61-.81,1.14,0,1.84.75,1.84,1.98Z" />
          <path d="m43.08,6.46c0,1.42-.9,2.28-2.43,2.28-.79,0-1.46-.22-1.87-.6v-.85h.04c.36.45,1.01.72,1.75.72,1.09,0,1.7-.57,1.7-1.59v-.22h-.02c-.36.43-.91.68-1.64.68-1.31,0-2.25-.96-2.25-2.32s.94-2.32,2.25-2.32c.79,0,1.4.31,1.74.82h.02l.03-.72h.69v4.14Zm-.79-1.91c0-.95-.64-1.59-1.56-1.59s-1.56.64-1.56,1.59.63,1.59,1.56,1.59,1.56-.64,1.56-1.59Z" />
          <path d="m50.93,4.57c0,1.37-.99,2.34-2.38,2.34s-2.38-.97-2.38-2.34.99-2.34,2.38-2.34,2.38.97,2.38,2.34Zm-3.94,0c0,.95.63,1.61,1.56,1.61s1.56-.66,1.56-1.61-.64-1.61-1.56-1.61-1.56.66-1.56,1.61Z" />
          <path d="m54.74,2.4v.82h-.04c-.19-.13-.43-.21-.7-.21-.86,0-1.28.6-1.28,1.77v2.04h-.82V2.32h.69l.02.72h.02c.27-.54.74-.82,1.42-.82.3,0,.53.08.68.17Z" />
          <path d="m60.19,6.75c-.2.11-.47.16-.82.16-.94,0-1.47-.56-1.47-1.57v-2.31h-.83v-.71h.84l.12-1.22h.67v1.22h1.45v.71h-1.45v2.29c0,.54.29.85.77.85.25,0,.48-.07.66-.26h.04v.83Z" />
          <path d="m61.57,8.64l.91-1.99-1.88-4.33h.88l1.43,3.48h.02l1.44-3.48h.85l-2.8,6.32h-.84Z" />
          <path d="m70.66,4.57c0,1.36-.95,2.34-2.26,2.34-.72,0-1.28-.25-1.63-.67h-.02v2.4h-.82V2.32h.69l.02.71h.02c.35-.5.95-.81,1.74-.81,1.31,0,2.26.98,2.26,2.34Zm-.83,0c0-.95-.64-1.61-1.56-1.61s-1.56.66-1.56,1.61.63,1.61,1.56,1.61,1.56-.66,1.56-1.61Z" />
          <path d="m72.58.91c0,.32-.22.54-.54.54s-.54-.22-.54-.54.22-.54.54-.54.54.22.54.54Zm-.94,1.41h.82v4.49h-.82V2.32Z" />
          <path d="m77.9,4.21v2.61h-.82v-2.52c0-.84-.46-1.34-1.22-1.34-.84,0-1.33.58-1.33,1.53v2.33h-.82V2.32h.69l.02.71h.02c.31-.51.85-.81,1.61-.81,1.14,0,1.84.75,1.84,1.98Z" />
          <path d="m83.48,6.46c0,1.42-.9,2.28-2.43,2.28-.79,0-1.46-.22-1.87-.6v-.85h.04c.36.45,1.01.72,1.75.72,1.09,0,1.7-.57,1.7-1.59v-.22h-.02c-.36.43-.91.68-1.64.68-1.31,0-2.25-.96-2.25-2.32s.94-2.32,2.25-2.32c.79,0,1.4.31,1.74.82h.02l.03-.72h.69v4.14Zm-.79-1.91c0-.95-.64-1.59-1.56-1.59s-1.56.64-1.56,1.59.63,1.59,1.56,1.59,1.56-.64,1.56-1.59Z" />
          <path d="m87.27,8.64l.91-1.99-1.88-4.33h.88l1.43,3.48h.02l1.44-3.48h.85l-2.8,6.32h-.84Z" />
          <path d="m95.95,4.57c0,1.37-.99,2.34-2.38,2.34s-2.38-.97-2.38-2.34.99-2.34,2.38-2.34,2.38.97,2.38,2.34Zm-3.94,0c0,.95.63,1.61,1.56,1.61s1.56-.66,1.56-1.61-.64-1.61-1.56-1.61-1.56.66-1.56,1.61Z" />
          <path d="m100.31,6.82l-.02-.71h-.02c-.31.51-.85.81-1.61.81-1.14,0-1.84-.74-1.84-1.98v-2.61h.82v2.53c0,.84.46,1.33,1.22,1.33.84,0,1.33-.58,1.33-1.53v-2.33h.82v4.49h-.69Z" />
          <path d="m108.13,6.82l-.03-.71h-.02c-.34.5-.95.81-1.74.81-1.31,0-2.25-.98-2.25-2.34s.94-2.34,2.25-2.34c.79,0,1.4.31,1.74.82h.02l.03-.72h.69v4.49h-.69Zm-.1-2.25c0-.95-.64-1.61-1.56-1.61s-1.56.66-1.56,1.61.63,1.61,1.56,1.61,1.56-.66,1.56-1.61Z" />
          <path d="m113.69,2.67v.89h-.04c-.35-.41-.79-.6-1.35-.6-1.03,0-1.67.65-1.67,1.61s.64,1.61,1.67,1.61c.58,0,1.03-.2,1.35-.6h.04v.9c-.35.29-.84.43-1.47.43-1.46,0-2.42-.98-2.42-2.34s.96-2.34,2.42-2.34c.62,0,1.12.14,1.47.44Z" />
          <path d="m118.37,2.67v.89h-.04c-.35-.41-.79-.6-1.35-.6-1.03,0-1.67.65-1.67,1.61s.64,1.61,1.67,1.61c.58,0,1.03-.2,1.35-.6h.04v.9c-.35.29-.84.43-1.47.43-1.46,0-2.42-.98-2.42-2.34s.96-2.34,2.42-2.34c.62,0,1.12.14,1.47.44Z" />
          <path d="m119.99,4.84c.12.84.79,1.34,1.8,1.34.66,0,1.24-.21,1.66-.61h.04v.83c-.4.33-1.02.52-1.72.52-1.58,0-2.61-.93-2.61-2.34s.97-2.34,2.34-2.34,2.28.85,2.28,2.16v.45h-3.79Zm0-.68h3.03c-.12-.73-.69-1.2-1.51-1.2-.77,0-1.37.48-1.53,1.2Z" />
          <path d="m129.48,4.57c0,1.36-.95,2.34-2.26,2.34-.72,0-1.28-.25-1.63-.67h-.02v2.4h-.82V2.32h.69l.02.71h.02c.35-.5.95-.81,1.74-.81,1.31,0,2.26.98,2.26,2.34Zm-.83,0c0-.95-.64-1.61-1.56-1.61s-1.56.66-1.56,1.61.63,1.61,1.56,1.61,1.56-.66,1.56-1.61Z" />
          <path d="m132.94,6.75c-.2.11-.47.16-.82.16-.94,0-1.47-.56-1.47-1.57v-2.31h-.83v-.71h.84l.12-1.22h.67v1.22h1.45v.71h-1.45v2.29c0,.54.29.85.77.85.25,0,.48-.07.66-.26h.04v.83Z" />
          <path d="m138.62,6.75c-.2.11-.47.16-.82.16-.94,0-1.47-.56-1.47-1.57v-2.31h-.83v-.71h.84l.12-1.22h.67v1.22h1.45v.71h-1.45v2.29c0,.54.29.85.77.85.25,0,.48-.07.66-.26h.04v.83Z" />
          <path d="m143.77,4.21v2.61h-.82v-2.52c0-.84-.46-1.34-1.22-1.34-.84,0-1.33.58-1.33,1.53v2.33h-.82V.1h.82v2.78h.02c.32-.42.85-.65,1.51-.65,1.14,0,1.84.75,1.84,1.98Z" />
          <path d="m145.46,4.84c.12.84.79,1.34,1.8,1.34.66,0,1.24-.21,1.66-.61h.04v.83c-.4.33-1.02.52-1.72.52-1.58,0-2.61-.93-2.61-2.34s.97-2.34,2.34-2.34,2.28.85,2.28,2.16v.45h-3.79Zm0-.68h3.03c-.12-.73-.69-1.2-1.51-1.2-.77,0-1.37.48-1.53,1.2Z" />
          <path d="m154.93,6.75c-.2.11-.47.16-.82.16-.94,0-1.47-.56-1.47-1.57v-2.31h-.83v-.71h.84l.12-1.22h.67v1.22h1.45v.71h-1.45v2.29c0,.54.29.85.77.85.25,0,.48-.07.66-.26h.04v.83Z" />
          <path d="m156.39,4.84c.12.84.79,1.34,1.8,1.34.66,0,1.24-.21,1.66-.61h.04v.83c-.4.33-1.02.52-1.72.52-1.58,0-2.61-.93-2.61-2.34s.97-2.34,2.34-2.34,2.28.85,2.28,2.16v.45h-3.79Zm0-.68h3.03c-.12-.73-.69-1.2-1.51-1.2-.77,0-1.37.48-1.53,1.2Z" />
          <path d="m163.98,2.4v.82h-.04c-.19-.13-.43-.21-.7-.21-.86,0-1.28.6-1.28,1.77v2.04h-.82V2.32h.69l.02.72h.02c.27-.54.74-.82,1.42-.82.3,0,.53.08.68.17Z" />
          <path d="m171.74,4.18v2.64h-.82v-2.55c0-.85-.38-1.31-1.09-1.31-.77,0-1.18.53-1.18,1.53v2.33h-.82v-2.55c0-.85-.37-1.31-1.09-1.31-.78,0-1.19.53-1.19,1.48v2.38h-.82V2.32h.68l.03.65h.02c.28-.48.77-.75,1.48-.75.67,0,1.18.3,1.45.86h.02c.28-.58.8-.86,1.62-.86,1.09,0,1.71.7,1.71,1.95Z" />
          <path d="m175.86,2.57v.86h-.04c-.31-.32-.79-.48-1.41-.48-.58,0-.95.27-.95.67,0,.33.24.48.99.52,1.23.07,1.68.48,1.68,1.36s-.68,1.4-1.78,1.4c-.75,0-1.33-.16-1.71-.47v-.88h.04c.36.41.92.62,1.64.62.61,0,1-.26,1-.67,0-.36-.25-.54-1.06-.59-1.18-.08-1.61-.45-1.61-1.27s.69-1.43,1.74-1.43c.6,0,1.13.12,1.47.35Z" />
          <path d="m183.62,4.57c0,1.37-.99,2.34-2.38,2.34s-2.38-.97-2.38-2.34.99-2.34,2.38-2.34,2.38.97,2.38,2.34Zm-3.94,0c0,.95.63,1.61,1.56,1.61s1.56-.66,1.56-1.61-.64-1.61-1.56-1.61-1.56.66-1.56,1.61Z" />
          <path d="m187.21.16v.77h-.04c-.19-.12-.47-.2-.73-.2-.51,0-.8.31-.8.88v.71h1.44v.71h-1.44v3.78h-.83v-3.78h-.83v-.71h.83v-.73c0-1.02.54-1.59,1.51-1.59.37,0,.69.07.88.16Z" />
          <path d="m193.33,6.82l-.02-.71h-.02c-.31.51-.85.81-1.61.81-1.14,0-1.84-.74-1.84-1.98v-2.61h.82v2.53c0,.84.46,1.33,1.22,1.33.84,0,1.33-.58,1.33-1.53v-2.33h.82v4.49h-.69Z" />
          <path d="m198.25,2.57v.86h-.04c-.31-.32-.79-.48-1.41-.48-.58,0-.95.27-.95.67,0,.33.24.48.99.52,1.23.07,1.68.48,1.68,1.36s-.68,1.4-1.78,1.4c-.75,0-1.33-.16-1.71-.47v-.88h.04c.36.41.92.62,1.64.62.61,0,1-.26,1-.67,0-.36-.25-.54-1.06-.59-1.18-.08-1.61-.45-1.61-1.27s.69-1.43,1.74-1.43c.6,0,1.13.12,1.47.35Z" />
          <path d="m199.97,4.84c.12.84.79,1.34,1.8,1.34.66,0,1.24-.21,1.66-.61h.04v.83c-.4.33-1.02.52-1.72.52-1.58,0-2.61-.93-2.61-2.34s.97-2.34,2.34-2.34,2.28.85,2.28,2.16v.45h-3.79Zm0-.68h3.03c-.12-.73-.69-1.2-1.51-1.2-.77,0-1.37.48-1.53,1.2Z" />
          <path d="m205.8,6.36c0,.32-.23.56-.55.56s-.56-.24-.56-.56.24-.56.56-.56.55.24.55.56Z" />
          <path d="m151.7,8.05v-.5h52.34v.5h-52.34Z" />
        </g>
      </g>
    </svg>
  );
};

ENPolicyTextIcon.defaultProps = {
  theme: defaultTheme,
};
export default ENPolicyTextIcon;
