import styled from 'styled-components';

const CardBody = styled.div`
  flex: 1 1 auto;
  padding: unset;
  display: block;
  word-wrap: break-word;
`;

export default CardBody;
