import styled from "styled-components";

const RightArrowContainer = styled.div`
  position: absolute;
  top: 25%;
  right: -50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 50%;
  padding: 2px;
`;

// RightArrowContainer.defaultProps = {
//   theme: defaultTheme,
// };

export default RightArrowContainer;
