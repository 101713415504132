import React, { useState } from "react";
import styled from "styled-components";
import FormButton from "./FormButton";

const LabelWrapper = styled.label`
  width: 100%;
  color: ${({ theme }) => theme.botFormLabelColor};
`;
class FormComponent extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      fileS3Url: {},
      isFormSubmit: false,
    };
  }

  setNodeForm() {
      const form = document.getElementById('form-' + this.props.formId)
      if (!form) return
      const submitEl = form.querySelectorAll('[type=submit]')
      for (let i = 0; i < submitEl.length; i++) submitEl[i].disabled = true
      form.reset()
      Object.keys(this.props.formData).forEach((key) => {
        if (!key) return; 
        const el = form.querySelectorAll(`[name="${key}"]`)
        if (!el.length) {
          if (key === 'email' || key === 'phone') {
            const el = form.querySelector(`input[type=${key === 'email' ? 'email' : 'tel'}]`)
            if (el) el.value = this.props.formData[key]
          }
          return;
        }
        if (el[0].type === 'checkbox' || el[0].type === 'radio') {
          for (let i = 0; i < el.length; i++) el[i].checked = this.props.formData[key].includes(el[i].value)
        } else if (el[0].type === 'submit') el[0].disabled = true
        else if (el[0].type ==="date") el[0].value = this.props.formData[key].split("-").reverse().join("-");
        else if (el[0].type === 'file') el[0].src = this.props.formData[key]
        else el[0].value = this.props.formData[key]
      })
  }

  componentDidMount() {
    if (this.props.isSession) this.setNodeForm();
    this.onPublishStep();
    const getForm = document.getElementById(`form-${this.props.formId}`);
    const formEl = getForm.elements;
    if (formEl && formEl.length) {
      formEl[0].focus();
      formEl[0].blur();
    }
  }

  onPublishStep(link) {
    if (!this.props.step || this.props.isPreview) return;
    const { data } = this.props.currentElement;
    this.props.step.metadata = data;
    this.props.step.type = "form";
  }

  uploadFiles(el) {
    const fileData = new FormData();
    if (!el.target.value) return;
    this.setState({ isFormSubmit: true });
    const fileName = el.target.value.split("\\");
    fileData.append("files", el.target.files[0]);
    fileData.append("fileNames", fileName[fileName.length - 1]);
    el.persist();
    const xhr = new XMLHttpRequest();
    xhr.open(
      "POST",
      `${window.GROWEO_FRONTEND}/api/session/upload/${this.props.moduleId}/${this.props.trackingId}`,
      true
    );
    xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");
    xhr.send(fileData);
    xhr.onload = () => {
        this.state.fileS3Url[el.target.name] = JSON.parse(xhr.responseText)[0];
        this.setState({ isFormSubmit: false });
    };
  }

  // eslint-disable-next-line class-methods-use-this
  validateCheckboxInput(e) {
    if (!e.target.name) return;
    const checkboxGroup = document.getElementsByName(e.target.name);
    const checkboxInputList = Array.from(checkboxGroup);
    const isAnyChecked = checkboxInputList.find(item => item.checked);
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < checkboxInputList.length; i++) {
      if (isAnyChecked) {
        checkboxInputList[i].removeAttribute("required");
        checkboxInputList[i].setCustomValidity("");
      } else {
        checkboxInputList[i].setAttribute("required", "");
        this.errorMessage(e.target);
      }
    }
  }

  onFormSubmit(event) {
    const formData = {};
    const { elements } = event.target;
    this.props.onFirstClick();
    // If markAsComplete is true then publish session
    if (this.props.currentElement.data?.markAsComplete) {
      console.log("Mark scussessfull conversation");
      this.props.onLinkClick(this.props?.step?.key, this.props.currentElement.data?.markAsComplete);
    }
    for (let e = 0; e < elements.length; e++) {
      if (elements[e].type !== "submit")
        if (elements[e].type === "checkbox") {
          if (!elements[e].checked) continue;
          const a = formData[elements[e].name] || [];
          a.push(elements[e].value);
          formData[elements[e].name] = a;
        } else if (elements[e].type === "radio") {
          if (!elements[e].checked) continue;
          if (!formData[elements[e].name])
          formData[elements[e].name] = elements[e].value;
        } else if (elements[e].type === "date") {
          formData[elements[e].name] = elements[e].value.split("-").reverse().join("-");
        } else if (elements[e].type === "email" && !formData.email) {
          formData.email = elements[e].value;
        } else if (elements[e].type === "tel" && !formData.phone) {
          formData.phone = elements[e].value;
        } else formData[elements[e].name] = elements[e].value;
    }
    this.props.step.value = { ...formData, ...this.state.fileS3Url };
    this.props.triggerNextStep();
  }

  errorMessage(field) {
    let message = "";
    // eslint-disable-next-line react/destructuring-assignment, react/prop-types
    if (this.props.language === "fi") {
      if (["dropdwon"].includes(field.type)) message = "Valitse vähintään yksi valinta";
      else if (["radio"].includes(field.type)) message = "Valitse vähintään yksi vaihtoehto";
      else if (["checkbox"].includes(field.type)) message = "Tämä valinta on pakollinen";
      else if (["file"].includes(field.type)) message = "Valitse tiedosto";
      else message = "Tämä kenttä on pakollinen";
    }
    return message;
  }

  render() {
    const { currentElement, primaryColor, fileTextColor } = this.props;

    return (
      <div style={{ width: "100%" }}>
        <form
          id={`form-${this.props.formId}`}
          className="simple-form white classic-rounded-style"
          style={{
            padding: "5px",
            background: "transparent",
            fontSize: `${this.props.fontSizeMultiplier * 14 || 14}px`,
            lineHeight: `${this.props.fontSizeMultiplier * 20 || 20}px`,
          }}
          onSubmit={(event) => {
            event.preventDefault();
            this.onFormSubmit(event);
            this.setState({
              isFormSubmit: true,
            });
          }}
        >
          {currentElement?.data?.fields?.length &&
            currentElement?.data.fields.map((field, index) => (
              <div key={`field${index}`} style={{ marginBottom: "12px" }}>
                {field.showlabel && (
                  <LabelWrapper htmlFor={field.name}>
                    {field.label}
                    {field.showlabel && field.require && (
                      <span style={{ color: "red" }}>*</span>
                    )}
                  </LabelWrapper>
                )}
                {field.showlabel && <br />}
                {!["checkbox", "dropdwon", "textarea", "radio"].includes(
                  field.type
                ) && (
                  <input
                    id={field.name}
                    aria-label={field.name}
                    type={field.type}
                    name={field.name}
                    aria-required={field.require}
                    required={field.require}
                    style={{color: field.type === "file" ? fileTextColor : '' }}
                    onChange={e => {
                      field.type === "file" && this.uploadFiles(e);
                    }}
                    placeholder={field.placeholder}
                    onInvalid={e =>
                      e.target.setCustomValidity(this.errorMessage(field))
                    }
                    onInput={e => e.target.setCustomValidity("")}
                  />
                )}
                {field.type === "textarea" && (
                  <textarea
                    id={field.name}
                    aria-label={field.name}
                    name={field.name}
                    required={field.require}
                    aria-required={field.require}
                    placeholder={field.placeholder}
                    onInvalid={e =>
                      e.target.setCustomValidity(this.errorMessage(field))
                    }
                    onInput={e => e.target.setCustomValidity("")}
                  />
                )}
                {["dropdwon"].includes(field.type) && (
                  <select
                    name={field.name}
                    id={field.name}
                    aria-label={field.name}
                    aria-required={field.require}
                    required={field.require}
                    onInvalid={e =>
                      e.target.setCustomValidity(this.errorMessage(field))
                    }
                    onInput={e => e.target.setCustomValidity("")}
                  >
                    <option value=""></option>
                    {field.options?.split("\n").map((option, i) => (
                      <option key={`op${i}`} value={option.trim()}>
                        {option.trim()}
                      </option>
                    ))}
                  </select>
                )}
                {["checkbox", "radio"].includes(field.type) &&
                  field.options?.split("\n").map((option, i) => (
                    <div key={`op${i}`}>
                      <input
                        id={field.name + i}
                        type={field.type}
                        name={field.name}
                        aria-required={field.require}
                        aria-label={field.name}
                        style={{ marginLeft: "10px" }}
                        required={field.require}
                        onChange={this.validateCheckboxInput}
                        value={option.trim()}
                        onInvalid={e =>
                          e.target.setCustomValidity(this.errorMessage(field))
                        }
                        onInput={e => e.target.setCustomValidity("")}
                      />
                      <LabelWrapper htmlFor={field.name + i}>{option.trim()}</LabelWrapper>
                      <br />
                    </div>
                  ))}
              </div>
            ))}
          <FormButton
            type="submit"
            name="submit"
            role={"button"}
            aria-label={currentElement.data.submitButtonText || "Submit"}
            fontSizeMultiplier={this.props.fontSizeMultiplier}
            disabled={this.state.isFormSubmit}
          >
            {currentElement.data.submitButtonText || "Submit"}
          </FormButton>
        </form>
      </div>
    );
  }
}

export default FormComponent;
