import styled from 'styled-components';
import defaultTheme from '../theme';

const Header = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.headerBgColor};
  color: ${({ theme }) => theme.headerFontColor};
  display: flex;
  fill: ${({ theme }) => theme.headerFontColor};
  justify-content: space-between;
  padding: 10px 10px 0px 10px;
  height: 60px;
  border-radius: 24px 24px 0px 0px;
  bottom: 88.01%;
  margin-bottom: 1px;
  opacity: 1;
  font-family: ${({ theme }) => theme.headerFontFamily};
  @media screen and (max-width: 568px) {
    border-radius:0px;
  }
`;

Header.defaultProps = {
  theme: defaultTheme
};

export default Header;
