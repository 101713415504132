import React from "react";

const RoundTrue = () => (
  <svg
    width="26px"
    height="26px"
    strokeWidth="1.5"
    viewBox="3 3 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    color="#fff"
    style={{ position: "inherit" }}
  >
    <path
      d="M7 12.5l3 3 7-7"
      stroke="#fff"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></path>
  </svg>
);

export default RoundTrue;
