/* eslint-disable no-nested-ternary */
import styled from 'styled-components';
import defaultTheme from '../theme';

const BarTitle = styled.span`
  color: ${props =>
    props.theme.invertBarColor
      ? props?.opened
        ? props.theme.barBgColor
        : props.theme.barFontColor
      : props.theme.barFontColor};
  font-size: ${({ theme }) => `${theme.barTitleFontSize * 16}px`};
  line-height: ${({ theme }) => `${theme.barTitleFontSize * 16 * 2.5}px`};
  margin:0px;
  font-weight: bold;
`;

BarTitle.defaultProps = {
  theme: defaultTheme
};
export default BarTitle;
