import styled from 'styled-components';

const FloatButton = styled.a`
  align-items: center;
  cursor: pointer;
  background: ${({ theme }) => theme.iconBgColor ? theme.iconBgColor.startsWith('http') ? `white url(${theme.iconBgColor}) no-repeat fixed center center/cover` : theme.iconBgColor : `#1a175e url('data:image/svg+xml,%3Csvg width="34" height="34" stroke-width="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="text-bold"%3E%3Crect height="24" width="24" fill="%231a175e" /%3E%3Cpath d="M8 10L12 10L16 10" stroke="white" stroke-linecap="round" stroke-linejoin="round"%3E%3C/path%3E%3Cpath d="M8 14L10 14L12 14" stroke="white" stroke-linecap="round" stroke-linejoin="round"%3E%3C/path%3E%3Cpath d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 13.8214 2.48697 15.5291 3.33782 17L2.5 21.5L7 20.6622C8.47087 21.513 10.1786 22 12 22Z" stroke="white" stroke-linecap="round" stroke-linejoin="round"%3E%3C/path%3E%3C/svg%3E') no-repeat fixed center`};
  bottom: 14px !important;
  border-radius: 100%;
  box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.15);
  display: flex;
  fill: ${({ theme }) => theme.iconColor};
  height: 56px;
  justify-content: center;
  position: fixed;
  right: 14px !important;
  transform: ${props => (props.opened ? 'scale(0)' : 'scale(1)')};
  transition: transform 0.3s ease;
  width: 56px;
  z-index: 999;
`;

FloatButton.defaultProps = {
  theme: {
    iconBgColor: '#1A175E',
    iconColor: '#fff'
  }
};

export default FloatButton;
