// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gsf-container p,
.gsf-container h1,
.gsf-container h2,
.gsf-container h3,
.gsf-container h4,
.gsf-container h5,
.gsf-container h6,
.gsf-container ul,
.gsf-container ol,
.gsf-container button,
.gsf-submit-btn p {
  margin: 0 !important;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
}
                      						
/* .slick-slide > div > div {
  display: flex !important;
  flex-direction: column;
  align-items: center;
}

.slick-prev:before,
.slick-next:before {
  display: none;
}

.slick-next {
  right: -38px !important;
  top: 45% !important;
}

.slick-prev {
  left: -45px !important;
  top: 45% !important;
} */
`, "",{"version":3,"sources":["webpack://./sdk/components/flow.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;EAWE,oBAAoB;EACpB,oBAAoB;EACpB,kBAAkB;EAClB,oBAAoB;EACpB,oBAAoB;AACtB;;AAEA;;;;;;;;;;;;;;;;;;;GAmBG","sourcesContent":[".gsf-container p,\n.gsf-container h1,\n.gsf-container h2,\n.gsf-container h3,\n.gsf-container h4,\n.gsf-container h5,\n.gsf-container h6,\n.gsf-container ul,\n.gsf-container ol,\n.gsf-container button,\n.gsf-submit-btn p {\n  margin: 0 !important;\n  font-family: inherit;\n  font-size: inherit;\n  font-weight: inherit;\n  line-height: inherit;\n}\n                      \t\t\t\t\t\t\n/* .slick-slide > div > div {\n  display: flex !important;\n  flex-direction: column;\n  align-items: center;\n}\n\n.slick-prev:before,\n.slick-next:before {\n  display: none;\n}\n\n.slick-next {\n  right: -38px !important;\n  top: 45% !important;\n}\n\n.slick-prev {\n  left: -45px !important;\n  top: 45% !important;\n} */\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
