import styled from 'styled-components';
import defaultTheme from '../theme';

const ChatBotContainer = styled.div`
  background: ${({ theme, inPage }) => inPage ? 'transparent' : theme.background.startsWith('http') ? `white url(${theme.background}) no-repeat fixed center` : theme.background};
  border-radius: 24px;
  box-shadow: ${({ inPage }) => inPage ? '' : '0 12px 24px 0 rgba(0, 0, 0, 0.15)'};
  font-family: ${({ theme }) => theme.fontFamily};
  overflow: hidden;
  position: ${({ floating }) => (floating ? 'fixed' : 'relative')};
  bottom: ${({ floating, floatingStyle }) =>
    floating ? floatingStyle.bottom || '0' : 'initial'};
  top: ${({ floating, floatingStyle }) => (floating ? floatingStyle.top || 'initial' : 'initial')};
  right: ${({ floating, floatingStyle }) => (floating ? floatingStyle.right || '0' : 'initial')};
  left: ${({ floating, floatingStyle }) =>
    floating ? floatingStyle.left || 'initial' : 'initial'};
  width: ${({ width }) => width};
  z-index: 999;
  transform: ${({ opened }) => (opened ? 'scale(1)' : 'scale(0)')};
  transform-origin: ${({ floatingStyle }) => floatingStyle.transformOrigin || 'bottom right'};
  transition: transform 0.3s ease;
  display: flex;
  flex-direction: column;
  ${({ inPage }) => `
    &::-webkit-scrollbar {
      width: ${inPage? '0px': '3px'};
    }
  `}
  @media screen and (max-width: 480px) {
    border-radius: ${({ floating }) => (floating ? '0' : '')};
    bottom: 0 !important;
    left: initial !important;
    height: 100%;
    right: 0 !important;
    top: initial !important;
    width: 100%;
  }
`;

ChatBotContainer.defaultProps = {
  theme: defaultTheme
};

export default ChatBotContainer;
