import React, { Component } from "react";
import styled from "styled-components";
import Button from "../components/Button";
import ReturnArrow from "../icons/ReturnArrow";
import RightArrow from "../icons/RightArrow";
import SubmitButtonContainer from "../components/SubmitButtonContainer";
import LeftArrow from "../icons/LeftArrow";

const ButtonWrapper = styled.div`
  display: inline;
  cursor: pointer;
  visibility: ${({ visible }) => `${visible ? "" : "hidden"}`};
`;

class SubmitButton extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const {
      preBtn,
      nexBtn,
      appearance,
      slide,
      isEditor,
      isSubmitResponse,
      language,
      isSession,
      isMobile,
      sendPostMessage,
      inPage,
      isMobilePopup
    } = this.props;

    const content = slide?.currentSlide?.data?.content;
    const hidePre = !!slide?.currentSlide?.data?.start
    const { buttonStyle } = this.props?.appearance?.style;

    const validationErrorMessage = (el) => {
      if (language === 'fi') {
        for (let i = 0; i < el.length; i++) {
          if(['email', 'url', 'text', 'tel', 'date', 'url', 'number', 'password', 'file', 'textarea'].includes(el[i].type)) {
            el[i].oninvalid = function(event) {
              if(event.type === 'invalid') {
                event.target.setCustomValidity("Tämä kenttä on pakollinen");
              }
            }
            el[i].oninput = function(event) {
              event.target.setCustomValidity("");
            };
          }
          else if(['radio', 'checkbox'].includes(el[i].type)) {
            let text = el[i].type === 'radio' ? 'Valitse vähintään yksi vaihtoehto' : 'Valitse vähintään yksi valinta'
            el[i].oninvalid = function(event) {
              if(event.type === 'invalid') {
                event.target.setCustomValidity(`${text}`);
              }
            }
            el[i].oninput = function(e) {
              if (!e.target.name) return;
              const checkboxGroup = document.getElementsByName(e.target.name);
              const checkboxInputList = Array.from(checkboxGroup);
              const isAnyChecked = checkboxInputList.find(item => item.checked);
              for (let i = 0; i < checkboxInputList.length; i++) {
                if (isAnyChecked) {
                  checkboxInputList[i].removeAttribute("required");
                  checkboxInputList[i].setCustomValidity("");
                } else {
                  checkboxInputList[i].setAttribute("required", "");
                }
              }
            };
          }
        }
      }
    }

    const onFormSubmit = () => {
      const form = document.getElementById(`gsf-form-${slide?.id}`);
      validationErrorMessage(form)
      if (!form || !form.reportValidity()) return;
      const formObj = {};
      for (let e = 0; e < form.elements.length; e++) {
        let element = form.elements.item(e)
        if ('submit' !== element.type)
          if ('checkbox' === element.type) {
            let elByName = document.getElementsByName(element.name),
              a = []
            for (let t = 0; t < elByName.length; t++)
              elByName[t].checked && a.push(elByName[t].value)
            formObj[element.name] = a
          } else if ('radio' === element.type) {
            let elByName = document.getElementsByName(element.name)
            let a = ''
            for (let t = 0; t < elByName.length; t++)
              elByName[t].checked && (a = elByName[t].value);
            formObj[element.name] = a;
          } else if (element.type === "date") {
            formObj[element.name] = element.value
              .split("-")
              .reverse()
              .join("-");
          } else if (element.type === "email" && !formObj.email) {
            formObj.email = element.value;
          } else if (element.type === "tel" && !formObj.phone) {
            formObj.phone = element.value;
          } else if (element.type === "file") {
            formObj[element.name] = element.getAttribute("fileurl") || "";
          } else formObj[element.name] = element.value;
      }
      slide.currentSlide.value = { ...formObj };
      nexBtn();
    };

    const buttonMobileStyle = {
      width: '50%',
      margin: '0 22px',
    };

    return (
      <div className="gsf-footer">
        <div
          className={`gsf-right-navigation ${buttonStyle}`}
          onClick={() => {
            let searchModule;
            if (isEditor || isSession)
              searchModule = document.querySelector(".shadow-div");
            preBtn();
            if (isEditor) {
              if (searchModule) searchModule.scrollIntoView();
            } else {
              //if (inPage) sendPostMessage({ type: 'smartflow-scroll-top' });
              //else
              if (!inPage || !isMobilePopup) window.scrollTo({ top: 0 });
            }
          }}
          style={{
            visibility: `${hidePre || isSubmitResponse ? "hidden" : ""}`,
          }}
        >
          <LeftArrow size={"22"} className={"gsf-next-arrow"} />
        </div>

        <button
          className={`gsf-submit-btn ${buttonStyle}`}
          style={isMobile ? buttonMobileStyle : { margin: "0 36px" }}
          onClick={() => {
            let searchModule;
            if (isEditor || isSession)
              searchModule = document.querySelector(".shadow-div");
            if (this.props.isEditor) nexBtn();
            if (slide?.type === "content" && !isSession) onFormSubmit();
            else nexBtn();
            if (isEditor) {
              if (searchModule) searchModule.scrollIntoView();
            } else {
              if (!inPage || !isMobilePopup) window.scrollTo({ top: 0 });
            }
          }}
          disabled={isSubmitResponse}
        >
          <span
            dangerouslySetInnerHTML={{
              __html: content?.buttonText,
            }}
          ></span>
        </button>
        <div
          className={`gsf-left-navigation ${buttonStyle}`}
          style={{
            visibility: `hidden`,
          }}
          onClick={() => nexBtn()}
        >
          <RightArrow size={"22"} className={"gsf-next-arrow"} />
        </div>
      </div>
    );
  }
}
export default SubmitButton;
