import React from 'react';
import styled from 'styled-components';
import { FormButton } from '../custom_components';
import Form from '../custom_components/Form';
import FormButtonType from '../custom_components/FormButtonType';

const LabelWrapper = styled.label`
  color: ${({ theme }) => theme.barFormLabelColor};
`;
class FormComponent extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      fileS3Url: {},
      isFileUpload: false
    };
  }

  setNodeForm() {
    const form = document.getElementById(`smartBar-form-${this.props.moduleId}`);
    if (!form) return;
    const submitEl = form.querySelectorAll('[type=submit]');
    for (let i = 0; i < submitEl.length; i++) submitEl[i].disabled = true;
    form.reset();
    Object.keys(this.props.formData).forEach(key => {
      if (!key) return;
      const el = form.querySelectorAll(`[name="${key}"]`);
      if (!el.length) {
        if (key === 'email' || key === 'phone') {
          const el = form.querySelector(
            `input[type=${key === 'email' ? 'email' : 'tel'}]`
          );
          if (el) el.value = this.props.formData[key];
        }
        return;
      }
      if (el[0].type === 'checkbox' || el[0].type === 'radio') {
        for (let i = 0; i < el.length; i++)
          el[i].checked = this.props.formData[key].includes(el[i].value);
      } else if (el[0].type === 'submit') el[0].disabled = true;
      else if (el[0].type === 'date')
        el[0].value = this.props.formData[key]
          .split('-')
          .reverse()
          .join('-');
      else if (el[0].type === 'file') el[0].src = this.props.formData[key];
      else el[0].value = this.props.formData[key];
    });
  }

  componentDidMount() {
    const form = document.getElementById(
      `smartBar-form-${this.props.moduleId}`
    );
  }

  componentDidUpdate() {
    if (this.props.isSession) this.setNodeForm();
  }

  uploadFiles(el) {
    const fileData = new FormData();
    if (!el.target.value) return;
    this.setState({ isFileUpload: true });
    const fileName = el.target.value.split('\\');
    fileData.append('files', el.target.files[0]);
    fileData.append('fileNames', fileName[fileName.length - 1]);
    el.persist();
    const xhr = new XMLHttpRequest();
    xhr.open(
      'POST',
      `${window.GROWEO_FRONTEND}/api/session/upload/${this.props.moduleId}/${this.props.trackingId}`,
      true
    );
    xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
    xhr.send(fileData);
    xhr.onload = () => {
      this.state.fileS3Url[el.target.name] = JSON.parse(xhr.responseText)[0];
      this.setState({ isFileUpload: false });
    };
  }

  // eslint-disable-next-line class-methods-use-this
  validateCheckboxInput(e) {
    if (!e.target.name) return;
    const checkboxGroup = document.getElementsByName(e.target.name);
    const checkboxInputList = Array.from(checkboxGroup);
    const isAnyChecked = checkboxInputList.find(item => item.checked);
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < checkboxInputList.length; i++) {
      if (isAnyChecked) {
        checkboxInputList[i].removeAttribute('required');
        checkboxInputList[i].setCustomValidity('');
      } else {
        checkboxInputList[i].setAttribute('required', '');
        this.errorMessage(e.target);
      }
    }
  }

  onFormSubmit(event) {
    const formData = {};
    const { elements } = event.target;
    for (let e = 0; e < elements.length; e++) {
      if (elements[e].type === 'checkbox') {
        if (!elements[e].checked) continue;
        const a = formData[elements[e].name] || [];
        a.push(elements[e].value);
        formData[elements[e].name] = a;
      } else if (elements[e].type === 'radio') {
        if (!elements[e].checked) continue;
        if (!formData[elements[e].name])
          formData[elements[e].name] = elements[e].value;
      } else if (elements[e].type === 'date') {
        formData[elements[e].name] = elements[e].value
          .split('-')
          .reverse()
          .join('-');
      } else if (elements[e].type === 'email' && !formData.email) {
        formData.email = elements[e].value;
      } else if (elements[e].type === 'tel' && !formData.phone) {
        formData.phone = elements[e].value;
      } else if (elements[e].type === 'submit') {
        elements[e].disabled = true;
        elements[e].classList.add('gsb-btn-loading');
        setTimeout(() => {
          elements[e].classList.remove('gsb-btn-loading');
          this.props.setIsFormSubmit(true);
        }, 2000);
      } else formData[elements[e].name] = elements[e].value;
    }
    this.props.onFormSubmit({ ...formData, ...this.state.fileS3Url });
  }

  errorMessage(field) {
    let message = '';
    // eslint-disable-next-line react/destructuring-assignment, react/prop-types
    if (this.props.language === 'fi') {
      if (['dropdwon'].includes(field.type))
        message = 'Valitse vähintään yksi valinta';
      else if (['radio'].includes(field.type))
        message = 'Valitse vähintään yksi vaihtoehto';
      else if (['checkbox'].includes(field.type))
        message = 'Tämä valinta on pakollinen';
      else if (['file'].includes(field.type)) message = 'Valitse tiedosto';
      else message = 'Tämä kenttä on pakollinen';
    }
    return message;
  }

  getFormattedRGBA(color, value){
    const data = `${color}`;
    const formatedData = data.split(',');
    const opacity =`${(value/100)})`
    formatedData.splice(formatedData.length-1, 1, opacity)
    return formatedData.toString()
}

  render() {
    const { currentElement, appearance } = this.props;
    const { formButtonType, barFormSubmitBtnUrl, barFormSubmitBtnEmail, barFormSubmitBtnPhone, barFormSubmitBtnText, barFormSubmitBtnTextUrl, barFormSubmitBtnTextPhone, barFormSubmitBtnTextEmail, barFormButtonPosition } = appearance;
    let selectedBtnType = (formButtonType === 'Website' ? `${barFormSubmitBtnUrl}` : 
      formButtonType === 'Email' ? `mailto:${barFormSubmitBtnEmail}` :
      formButtonType === 'Phone Number' ? `tel:${barFormSubmitBtnPhone}`: '');
    let selectedBtnValue = (formButtonType === 'Website' ? barFormSubmitBtnTextUrl : 
      formButtonType === 'Email' ? barFormSubmitBtnTextEmail :
      formButtonType === 'Phone Number' ? barFormSubmitBtnTextPhone : 'Submit');
    let gwTrackEventKey = formButtonType === 'Website' ? 'website' : 
      formButtonType === 'Email' ? 'email' :
      formButtonType === 'Phone Number' ? 'phoneNumber' : '';
    let gwTrackEventData = formButtonType === 'Website' ? barFormSubmitBtnUrl : 
      formButtonType === 'Email' ? barFormSubmitBtnEmail :
      formButtonType === 'Phone Number' ? barFormSubmitBtnPhone : '';
            
    return (
      <div style={{ width: '100%' }}>
        <Form
          id={`smartBar-form-${this.props.moduleId}`}
          className={`simple-form white underline-style`}
          fontSizeMultiplier={this.props.fontSizeMultiplier}
          onSubmit={event => {
            event.preventDefault();
            this.onFormSubmit(event);
          }}
        >
          {currentElement?.fields?.length
            ? currentElement.fields.map((field, index) => (
                <div key={`field${index}`} style={{ marginBottom: '12px' }}>
                  {field.showlabel && (
                    <LabelWrapper htmlFor={field.name}>
                      {field.label}
                      {field.showlabel && field.require && (
                        <span style={{ color: 'red' }}>*</span>
                      )}
                    </LabelWrapper>
                  )}
                  {field.showlabel && <br />}
                  {!['checkbox', 'dropdwon', 'textarea', 'radio'].includes(
                    field.type
                  ) && (
                    <input
                      id={field.name}
                      aria-label={field.name}
                      type={field.type}
                      name={field.name}
                      aria-required={field.require}
                      required={field.require}
                      onChange={e => {
                        field.type === 'file' && this.uploadFiles(e);
                      }}
                      placeholder={field.placeholder}
                      onInvalid={e =>
                        e.target.setCustomValidity(this.errorMessage(field))
                      }
                      onInput={e => e.target.setCustomValidity('')}
                      className='gsb-input-placeholder'
                      style={{backgroundColor: this.getFormattedRGBA((typeof this.props?.appearance?.barFormInputBgColor === 'undefined' ? 'rgba(255, 255, 255, 1)' : this.props?.appearance?.barFormInputBgColor), this.props?.appearance?.barFieldBGPercentage),
                        color: field.type === 'file' ? this.props?.appearance?.barFormLabelColor: (typeof this.props?.appearance?.barFormInputTextColor === 'undefined' ? '#000' : this.props?.appearance?.barFormInputTextColor),
                        '--gsb-input-placeholder': (typeof this.props?.appearance?.barFormInputPlaceholderColor === 'undefined' ? '#999' : this.props?.appearance?.barFormInputPlaceholderColor)
                      }}
                      />
                  )}
                  {field.type === 'textarea' && (
                    <textarea
                      id={field.name}
                      aria-label={field.name}
                      name={field.name}
                      required={field.require}
                      aria-required={field.require}
                      placeholder={field.placeholder}
                      onInvalid={e =>
                        e.target.setCustomValidity(this.errorMessage(field))
                      }
                      onInput={e => e.target.setCustomValidity('')}
                      style={{backgroundColor: this.getFormattedRGBA((typeof this.props?.appearance?.barFormInputBgColor === 'undefined' ? 'rgba(255, 255, 255, 1)' : this.props?.appearance?.barFormInputBgColor), this.props?.appearance?.barFieldBGPercentage),
                        color: (typeof this.props?.appearance?.barFormInputTextColor === 'undefined' ? '#000' : this.props?.appearance?.barFormInputTextColor),
                        '--gsb-input-placeholder': (typeof this.props?.appearance?.barFormInputPlaceholderColor === 'undefined' ? '#999' : this.props?.appearance?.barFormInputPlaceholderColor)
                      }}
                      />
                  )}
                  {['dropdwon'].includes(field.type) && (
                    <select
                      name={field.name}
                      id={field.name}
                      aria-label={field.name}
                      aria-required={field.require}
                      required={field.require}
                      onInvalid={e =>
                        e.target.setCustomValidity(this.errorMessage(field))
                      }
                      onInput={e => e.target.setCustomValidity('')}
                      style={{backgroundColor: this.getFormattedRGBA((typeof this.props?.appearance?.barFormInputBgColor === 'undefined' ? 'rgba(255, 255, 255, 1)' : this.props?.appearance?.barFormInputBgColor), this.props?.appearance?.barFieldBGPercentage),
                        color: (typeof this.props?.appearance?.barFormInputTextColor === 'undefined' ? '#000' : this.props?.appearance?.barFormInputTextColor),
                        '--gsb-input-placeholder': (typeof this.props?.appearance?.barFormInputPlaceholderColor === 'undefined' ? '#999' : this.props?.appearance?.barFormInputPlaceholderColor)
                      }}
                      >
                      <option value=""></option>
                      {field.options?.split('\n').map((option, i) => (
                        <option key={`op${i}`} value={option.trim()}>
                          {option.trim()}
                        </option>
                      ))}
                    </select>
                  )}
                  {['checkbox', 'radio'].includes(field.type) &&
                    field.options?.split('\n').map((option, i) => (
                      <div className='gsb-checkBox' key={`op${i}`}>
                        <input
                          className='w-5'
                          id={field.name + i}
                          type={field.type}
                          name={field.name}
                          aria-required={field.require}
                          aria-label={field.name}
                          style={{ marginLeft: '10px' }}
                          required={field.require}
                          onChange={this.validateCheckboxInput}
                          value={option.trim()}
                          onInvalid={e =>
                            e.target.setCustomValidity(this.errorMessage(field))
                          }
                          onInput={e => e.target.setCustomValidity('')}
                        />
                        <LabelWrapper className='w-95' htmlFor={field.name + i} dangerouslySetInnerHTML={{
														__html: option.trim()
													}}>
                          {/* {option.trim()} */}
                        </LabelWrapper>
                        <br />
                      </div>
                    ))}
                </div>
              ))
            : ''}
          <div style={{display: 'flex', alignItems:'center', justifyContent: (typeof barFormButtonPosition === 'undefined') ? 'left' : barFormButtonPosition}}>
            {(typeof formButtonType === 'undefined' || formButtonType === 'Form Submit') ? 
            (<FormButton
                type="submit"
                name="submit"
                role="button"
                aria-label={
                  this.props?.appearance?.barFormSubmitBtnText || 'Submit'
                }
                fontSizeMultiplier={this.props.fontSizeMultiplier}
                barFormAutoWidth={this.props?.appearance?.barFormAutoWidth}
                barFormAutoWidthRange={this.props?.appearance?.barFormAutoWidthRange}
                disabled={this.props.isFormSubmit || this.state.isFileUpload || this.props.isSession}
              >
                {this.props?.appearance?.barFormSubmitBtnText || 'Submit'}
              </FormButton>
            ) : (
              <FormButtonType
                href={selectedBtnType}
                target='_blank'
                onClick={()=>{
                  this.props.onFormBtnSubmit({[gwTrackEventKey] : gwTrackEventData})
                  this.props.setIsFormSubmit(true)
                }}
                barFormAutoWidth={this.props?.appearance?.barFormAutoWidth}
                barFormAutoWidthRange={this.props?.appearance?.barFormAutoWidthRange}
                style={{pointerEvents: this.props.isSession ? 'none' : (formButtonType === 'Website' && !barFormSubmitBtnUrl) ? 'none' : '' }}
              >
                {selectedBtnValue || 'Submit'}
              </FormButtonType>
            )}
          </div>
        </Form>
      </div>
    );
  }
}

export default FormComponent;
