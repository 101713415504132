import styled from "styled-components";
import defaultTheme from "../theme";

const Content = styled.div`
  text-align: center;
  margin: 0;
  font-size: ${({ slideData }) => `${slideData?.fontSize}px`};
  color: ${({ slideData }) => slideData?.fontColor || "#000"};
  font-weight: ${({ slideData }) => slideData?.para2FontWeight};
  word-break: break-word;
  height: ${({ slideData }) => slideData?.height ? `${slideData?.height}px` : 'auto'};
  position: absolute;
  width: 100%;
  top: ${({ slideData }) => `calc(20px + ${slideData?.contentTop}%)`};
`;

Content.defaultProps = {
  theme: defaultTheme
};

export default Content;
