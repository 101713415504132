import styled from "styled-components";
import defaultTheme from "../theme";

const Form = styled.form`
  padding:  ${({ theme }) => `${theme.barFormPadding} !important`};
  background: transparent !important;
  font-size: ${({ fontSizeMultiplier }) => `${fontSizeMultiplier * 14 || 14}px`};
  line-height: ${({ fontSizeMultiplier }) => `${fontSizeMultiplier * 20 || 20}px`};
`;

Form.defaultProps = {
	theme: defaultTheme,
};

export default Form;
